<template>
  <div>
    <!-- Pinned Itineraries -->
    <h2 class="text-xl font-semibold flex flex-row items-center mb-2">
      <HeartIcon class="text-red-800 mr-1" />Pinned Itineraries
    </h2>
    <div v-if="pinnedItineraries.length" class="flex flex-row items-start -m-1 overflow-x-auto">
      <div v-for="pinnedItinerary in pinnedItineraries" :key="pinnedItinerary._id" class="px-1">
        <PlannerItineraryTile :itinerary="pinnedItinerary.itinerary" />
      </div>
    </div>

    <!-- No Pinned Itineraries -->
    <div
      class="py-6 bg-gray-300 dark:bg-gray-700 rounded-lg flex flex-row items-center justify-center"
    >
      You haven't <strong class="px-1">pinned</strong> any itineraries.
    </div>

    <!-- Recently Viewed Itineraries -->
    <h2 class="text-xl font-semibold flex flex-row items-center mt-6 mb-2">
      <ClockIcon class="text-blue-700 mr-1" />Recently Viewed Itineraries
    </h2>
    <div v-if="itineraryViews.length" class="flex flex-row items-start -mx-1 overflow-x-auto">
      <div v-for="itineraryView in uniqueViews" :key="itineraryView._id" class="px-1">
        <PlannerItineraryTile :itinerary="itineraryView.itinerary" @click="handleItineraryClick" />
      </div>
    </div>

    <!-- No Viewed Itineraries -->
    <div
      v-else
      class="py-6 bg-gray-300 dark:bg-gray-700 rounded-lg flex flex-row items-center justify-center"
    >
      You haven't <strong class="px-1">viewed</strong> any itineraries.
    </div>

    <div class="mb-6"></div>
  </div>
</template>

<script>
import { computed, inject } from '@vue/composition-api'
import { findPinnedItinerariesForUser } from '@/use/pinned-itineraries.js'
import { findItineraryViewsForUser } from '@/use/itinerary-views.js'
import _uniqBy from 'lodash/uniqBy.js'
import { usePlanner } from '@/use/planner.js'

import { HeartIcon, ClockIcon } from 'vue-feather-icons'
import PlannerItineraryTile from '../PlannerItineraryTile/PlannerItineraryTile.vue'

export default {
  name: 'PlannerItineraries',
  components: {
    HeartIcon,
    ClockIcon,
    PlannerItineraryTile
  },
  setup(props, context) {
    const user = computed(() => {
      return context.root.$store.state.auth.user
    })
    const { pinnedItineraries } = findPinnedItinerariesForUser({ user })

    // Itinerary Views
    const { itineraryViews } = findItineraryViewsForUser({ user })
    const uniqueViews = computed(() => {
      return _uniqBy(itineraryViews.value, 'itinerary._id')
    })

    const { openPlannerItinerary } = usePlanner()

    function handleItineraryClick({ event, itinerary }) {
      openPlannerItinerary({ itineraryId: itinerary._id })
    }

    return {
      user,
      pinnedItineraries,
      itineraryViews,
      uniqueViews,
      handleItineraryClick
    }
  }
}
</script>

<style lang="postcss"></style>
