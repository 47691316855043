import { watch } from '@vue/composition-api'
import { $auth0 } from '../use/auth0'
import feathersClient from '../feathers-client'
import { models } from 'feathers-vuex'
import LogRocket from 'logrocket'
import router from '../router/index'
import { setVal } from '../use/utils.js'

// called when the store is initialized
export default function authPlugin(store) {
  store.registerModule('auth', {
    namespaced: true,
    state: {
      isLoading: true,
      isAuthenticated: false,
      accessToken: null,
      payload: null,
      user: null,
      auth0User: null,
      currentOrgId: null,
      error: null,
      shouldRecordLoginEvent: false
    },
    getters: {
      currentOrg: state => models.api.Org.getFromStore(state.currentOrgId)
    },
    mutations: {
      setApiAuthData(state, response) {
        if (response.user) {
          response.user = new models.api.User(response.user)
        }
        Object.assign(state, response)
      },
      setAuth0User(state, auth0User) {
        Object.assign(state, { auth0User })
      },
      setAuthenticated(state) {
        state.isAuthenticated = true
        state.isLoading = false
      },
      setLoaded(state) {
        state.isLoading = false
      },
      /**
       * Call this mutation with the orgId to change orgs in the UI.
       * This is called from store.auth.js immediately after authenticating the FeathersJS API.
       */
      setCurrentOrgId(state, orgId) {
        state.currentOrgId = orgId
      },
      setError(state) {
        state.error = true
      },
      ...setVal('setShouldRecordLoginEvent')
    },
    actions: {
      async authenticate({ commit, dispatch }, authData) {
        try {
          const response = await feathersClient.authenticate(authData)

          commit('setApiAuthData', response)
          if (response.user) {
            dispatch('setInitialOrg', response.user)
            //
          }
        } catch (error) {
          console.log('error during Feathers API Authentication', error)
          store.commit('auth/setError')
        }
      },
      /**
       * Sets the initial org from either the current-org-id key in localStorage OR
       * the orgId from the orgUser record where isDefaultOrg === true. For this to work,
       * `user.orgUsers` must exist, and each orgUser record must have `orgUser.org` on it.
       */
      setInitialOrg({ commit }, user) {
        const storedOrgId = window.localStorage.getItem('current-org-id')
        const orgUser =
          user.orgUsers.find(ou => ou.orgId === storedOrgId) ||
          user.orgUsers.find(ou => ou.isDefaultOrg)

        window.localStorage.setItem('current-org-id', orgUser.orgId)
        commit('setCurrentOrgId', orgUser.orgId)
      }
    }
  })

  const search = window.location.search
  const cameFromRedirect = search.includes('code=') && search.includes('state=')
  watch(
    () => $auth0.state.error,
    error => {
      if (error.message === 'Invalid state') {
        router.replace({ name: 'Discover', params: { env: 'rovit' } })
        window.location.reload()
      }
    }
  )

  watch(
    () => $auth0.state.isLoading,
    async isLoading => {
      if (!isLoading) {
        if ($auth0.state.user) {
          const accessToken = await feathersClient.authentication.getAccessToken()
          await store.dispatch('auth/authenticate', {
            strategy: 'auth0',
            accessToken
          })
          store.commit('auth/setAuth0User', $auth0.state.user)
          store.commit('auth/setAuthenticated')
          store.commit('auth/setLoaded')

          /**
           * If any anonymous tries to save any item to planner that will be stored in localstorage
           * Once he logs in that item will be saved to his planner
           * Clearing local storage data once data is saved
           */
          const pendingPlannerItemData = window.localStorage.getItem('pendingPlannerItemData')
          if (pendingPlannerItemData) {
            const data = JSON.parse(pendingPlannerItemData)
            const { PlannerSavedItem } = models.api
            await new PlannerSavedItem(data).save()
            window.localStorage.removeItem('pendingPlannerItemData')
          }

          // Record login event after env loads
          store.commit('auth/setShouldRecordLoginEvent', true)
          console.log('logged in')
          // Setup LogRocket
          const { sub, name, email } = $auth0.state.user
          LogRocket.identify(sub, { name, email })
        } else {
          await store.dispatch('auth/authenticate', { strategy: 'anonymous' })
          store.commit('auth/setLoaded')
        }
      }
    }
  )
}
