import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
import { iff, discard } from 'feathers-hooks-common'
import feathersVuex from 'feathers-vuex'
import paramsForServer from './utils/params-for-server'
import { storage } from './use/auth0.js'

const socket = io(process.env.VUE_APP_ROVIT_API_URL, {
  transports: ['websocket'],
})

let hasConnectedOnce = false
const feathersClient = feathers()
  .configure(
    socketio(socket, {
      timeout: 30000,
    })
  )
  .configure(auth({ jwtStrategy: 'auth0', storage }))

// A promise that returns true if the socket is connected
feathersClient.connected = new Promise((resolve) => {
  socket.on('connect', () => {
    resolve(true)
  })
})
// A hook that allows requests to continue if the socket is connected.
function waitForSocketConnect() {
  return async function waitForSocketConnectHook(context) {
    await context.app.connected
    return context
  }
}

feathersClient.hooks({
  before: {
    all: [
      waitForSocketConnect(),
      // authenticateFirst(),
      paramsForServer('$populateParams'),
      iff(
        (context) => ['create', 'update', 'patch'].includes(context.method),
        discard('__id', '__isTemp')
      ),
    ],
  },
})

socket.on('connect', async () => {
  if (hasConnectedOnce) {
    hasConnectedOnce = false
    if (feathersClient.authentication.authenticated) {
      try {
        await feathersClient.reAuthenticate()
      } catch (error) {
        console.error({ error })
      }
    }
  }
})

socket.on('disconnect', () => {
  hasConnectedOnce = true
})

export default feathersClient

// Setting up feathers-vuex
const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } = feathersVuex(
  feathersClient,
  {
    serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
    idField: '_id', // Must match the id field in your database table/collection
    whitelist: ['$regex', '$options'],
  }
)

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex }
