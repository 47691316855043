import { models, useFind, useGet } from 'feathers-vuex'
import { computed, watch } from '@vue/composition-api'
import { unwrapRefs } from './utils.js'

export function findPinnedItinerariesForUser(options) {
  const { PinnedItinerary } = models.api
  const params = computed(() => {
    const { user } = unwrapRefs(options)
    if (!user) {
      return null
    }
    const query = {
      userId: user._id,
      $sort: { sortOrder: 1 },
      $limit: 100
    }
    return { query, $populateParams: { name: 'withItinerary' } }
  })
  const queryWhen = computed(() => {
    return true
  })
  const { items: pinnedItineraries, haveLoaded, haveBeenRequested } = useFind({
    model: PinnedItinerary,
    params,
    queryWhen
  })
  return { pinnedItineraries, haveLoaded }
}

export async function createPinnedItinerary(options) {
  const { PinnedItinerary } = models.api
  const { user, itinerary } = unwrapRefs(options)
  const data = {
    userId: user._id || null,
    itineraryId: itinerary._id
  }
  const pinnedItinerary = await new PinnedItinerary(data).save()
  return pinnedItinerary
}
