import { models, useFind, useGet } from 'feathers-vuex'
import { computed, watch } from '@vue/composition-api'
import { unwrapRefs } from './utils.js'

export function findItineraryViewsForUser(options) {
  const { ItineraryView } = models.api
  const params = computed(() => {
    const { user } = unwrapRefs(options)
    if (!user) {
      return null
    }
    const query = {
      userId: user._id,
      $sort: { createdAt: -1 },
      $limit: 100
    }
    return { query, $populateParams: { name: 'withItinerary' } }
  })
  const queryWhen = computed(() => {
    return true
  })
  const { items: itineraryViews, haveLoaded, haveBeenRequested } = useFind({
    model: ItineraryView,
    params,
    queryWhen
  })
  return { itineraryViews, haveLoaded }
}

export async function createItineraryView(data) {
  const { ItineraryView } = models.api
  const itineraryView = await new ItineraryView(data).save()
  return itineraryView
}
