<template>
  <div class="flex flex-col">
    <div
      class="bg-orange-300 rounded flex flex-row items-center container-drag-handle"
    >
      <button
        v-if="!container.name"
        class="text-black block p-2 truncate flex-grow text-left"
        @click="() => edit && openPopper()"
      >
        {{ container.name || 'Click to name this section' }}
      </button>

      <div
        v-else
        class="text-black flex-grow p-2"
        :class="edit ? 'cursor-move' : null"
      >
        {{ container.name }}
      </div>

      <button
        ref="sectionSettingsPopupButton"
        class="text-black inline-block max-w-48 truncate p-1 h-full"
        :class="[edit ? null : 'invisible']"
        @click="() => edit && openPopper()"
      >
        <XIcon v-if="popper.isVisible" />
        <ChevronDownIcon v-else />
      </button>
    </div>

    <!-- Popper Menu for Section Header -->
    <div
      v-show="popper.isVisible"
      ref="sectionSettingsPopup"
      v-click-outside="closePopperIfReady"
      class="bg-gray-100 border border-gray-400 dark:bg-gray-800 dark:border-gray-600 rounded w-64"
    >
      <!-- Name -->
      <div
        class="bg-gray-100 dark:bg-gray-800 transition-colors duration-200 hover:bg-gray-200 dark:hover:bg-gray-900 p-2 rounded"
      >
        <label class="block">
          <span class="">Section Name</span>
          <input
            :value="container.name"
            class="form-input block w-full mt-1"
            type="text"
            @blur="updateName"
            @keyup.enter="
              e => {
                updateName(e)
                closePopperIfReady()
              }
            "
          />
        </label>
      </div>

      <hr class="border-t border-gray-200 dark:border-gray-700" />

      <button
        type="button"
        class="bg-gray-100 dark:bg-gray-800 text-red-600 transition-colors duration-200 dark:hover:bg-gray-900 hover:bg-red-200 hover:text-red-900 dark:hover:text-red-500 py-3 px-2 rounded w-full text-left"
        @click="$emit('remove-container', { container })"
      >
        Remove Section
      </button>
    </div>

    <draggable
      v-model="containerItems"
      class="list-group flex-grow min-h-48 bg-gray-100 dark:bg-gray-800 mt-2"
      group="itinerary"
      :disabled="!edit"
    >
      <PlannerItineraryItem
        v-for="(item, index) in container.items"
        :key="item.id"
        :edit="edit"
        :item="item"
        class="mb-2"
        @click="
          event => handleItineraryItemClick({ event, item, index, container })
        "
      >
        <template #menu>
          <ItineraryItemMenu
            v-if="edit"
            :item="item"
            @update:item="data => updateItem({ index, data })"
            @remove="() => removeItem({ item, container })"
          />
        </template>
      </PlannerItineraryItem>
    </draggable>
  </div>
</template>

<script>
import { computed, watch, ref } from '@vue/composition-api'
import { usePopper } from '@rovit/popper'
import { prepareContainerItem, prepareTravelItem } from '@/use/itineraries.js'

import draggable from 'vuedraggable'
import { XIcon, ChevronDownIcon } from 'vue-feather-icons'
import PlannerItineraryItem from '../PlannerItineraryItem/PlannerItineraryItem.vue'
import ItineraryItemMenu from '../ItineraryItemMenu/ItineraryItemMenu.vue'

export default {
  name: 'PlannerFolderItinerarySection',
  components: {
    draggable,
    XIcon,
    ChevronDownIcon,
    PlannerItineraryItem,
    ItineraryItemMenu
  },
  props: {
    container: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const containerItems = computed({
      get: () => {
        return props.container.items
      },
      set: val => {
        const normalizedItems = val.map(item => {
          // If an item has a `modelName` property on its constructor then it's a PlannerSavedItem.
          // Convert it to the itinerary item format.
          if (item.constructor.modelName === 'PlannerSavedItem') {
            return prepareContainerItem({ item })
          } else if (item.travelType) {
            return prepareTravelItem({ item })
          }
          return item
        })
        context.emit('update-items', normalizedItems)
      }
    })
    // Section Settings Popper
    const sectionSettingsPopupButton = ref(null)
    const sectionSettingsPopup = ref(null)
    const popper = usePopper(sectionSettingsPopupButton, sectionSettingsPopup, {
      placement: 'bottom-end'
    })
    const isPopperReady = ref(false)
    function openPopper() {
      if (popper.isVisible) {
        closePopperIfReady()
      } else {
        popper.open()
        setTimeout(() => {
          isPopperReady.value = true
        }, 500)
      }
      // Workaround for the popper position
      window.scrollBy(0, 1)
      window.scrollBy(0, -1)
    }
    function closePopperIfReady() {
      if (isPopperReady.value) {
        popper.close()
        isPopperReady.value = false
      }
    }

    function updateContainer(data) {
      const newContainer = Object.assign({}, props.container, data)
      context.emit('update:container', newContainer)
    }
    function updateItem({ data, index }) {
      const { items } = props.container.items.slice()
      items[index] = data
      updateContainer({ items })
    }
    function removeItem({ item, container }) {
      const items = container.items.filter(i => i !== item)
      updateContainer({ items })
    }
    function updateName(e) {
      updateContainer({ name: e.target.value })
    }

    function handleItineraryItemClick({ event, item, index, container }) {
      context.emit('item-click', { event, item, index, container })
    }

    return {
      //section settings popup
      sectionSettingsPopupButton,
      sectionSettingsPopup,
      popper,
      openPopper,
      closePopperIfReady,

      handleItineraryItemClick,
      containerItems,
      updateName,
      updateItem,
      removeItem
    }
  }
}
</script>
