<template>
  <div class="itinerary-social-share">
    <div
      class="text-lg rounded bg-gray-100 dark:bg-gray-900 p-2 h-full flex flex-col text-center items-center justify-center leading-tight"
    >
      <p
        v-if="!itinerary.isPublic"
        class="h-24 flex items-center justify-center"
        :class="[itinerary.isPublic ? 'opacity-100' : 'opacity-50 cursor-not-allowed']"
      >
        Enable public visibility to share on Social Media.
      </p>
      <SocialShare v-else :title="shareTitle" :description="shareDescription" :url="shareUrl" />
    </div>
  </div>
</template>

<script>
import { SocialShare } from '@rovit/social-share'
import { computed } from '@vue/composition-api'

export default {
  components: {
    SocialShare
  },
  props: {
    itinerary: {
      validator: val => typeof val === 'object',
      required: true
    }
  },
  setup(props) {
    const shareTitle = computed(() => {
      return `View "${props.itinerary.title}" itinerary on Rovit`
    })
    const shareDescription = computed(() => {
      return `Check out these trip plans on Rovit: ${props.itinerary.title}`
    })
    const shareUrl = computed(() => {
      return (
        window.location.origin +
        window.location.pathname +
        '?menu=planner&itineraryId=' +
        props.itinerary._id
      )
    })
    return { shareTitle, shareDescription, shareUrl }
  }
}
</script>

<style lang="postcss" scoped></style>
