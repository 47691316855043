<template>
  <div id="app">
    <div
      v-if="$auth0.state.error"
      class="flex items-center justify-center w-full h-full bg-gray-800"
    >
      <div class="text-center text-white">
        <img class="m-auto" src="https://cdn.rovit.com/rovit-frog-small.png" alt="" />
        <div class="mt-3 text-lg">Oh Snap! The server is not responding right now :(</div>
        <div class="text-lg">Please try again later.</div>
      </div>
    </div>

    <div v-else-if="isLoading" class="loading-screen">
      <LeapFrog />
    </div>

    <router-view v-else />
  </div>
</template>

<script>
import { ref, watch, computed, provide, inject, onMounted, onUnmounted } from '@vue/composition-api'
import { $auth0 } from '@/use/auth0.js'
import { findConfig } from '@/use/config'
import { usePlanner } from '@/use/planner.js'

import { LeapFrog } from '@rovit/loading'

export default {
  name: 'App',
  components: {
    LeapFrog
  },
  setup(props, context) {
    provide('$store', context.root.$store)
    provide('$auth0', $auth0)
    provide(
      '$route',
      computed(() => context.root.$route)
    )
    provide(
      '$router',
      computed(() => context.root.$router)
    )
    usePlanner()

    const store = inject('$store')

    const isAuthLoading = computed(() => context.root.$store.state.auth.isLoading)
    const configData = findConfig({ isAuthLoading })

    const isLoading = computed(() => {
      return (
        ($auth0.state.isLoading && configData.haveLoaded) ||
        !context.root.$store.state.environments.hasInitialEnvLoaded
      )
    })

    /**
      function to reload the current window.location
      when rovit app is being used as an iframe
      in another website.
    */
    function handleReload(event) {
      if (event.data === 'rovit-iframe-reload') {
        window.location.reload()
      }
    }

    onMounted(() => {
      window.addEventListener('message', handleReload, false)
    })
    onUnmounted(() => {
      window.removeEventListener('message', handleReload)
    })

    return { isLoading }
  }
}
</script>

<style lang="postcss">
@import '~@rovit/styles/tailwind.postcss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body,
#app {
  @apply h-full;
  font-family: 'Dosis', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}
body {
  cursor: auto !important;
}
.loading-screen {
  @apply h-full flex flex-row items-center justify-center;
}
@screen dark {
  .loading-screen {
    @apply bg-gray-800;
  }
}
</style>
