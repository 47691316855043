<template>
  <div>
    <div class="mt-3 flex justify-center">
      <img src="./../../assets/planner-placeholder.svg" style="width: 200px;" />
    </div>
    <div class="mt-3 flex justify-center">
      <span class="font-bold text-lg text-black dark:text-white">
        {{ label }}
      </span>
    </div>
    <div class="flex justify-center my-3">
      <button class="bg-green-800 rounded-lg flex px-2 py-2 items-center mr-2">
        <PlayIcon />
        <span class="ml-2">Getting Started</span>
      </button>
      <button
        class="bg-blue-800 rounded-lg flex px-2 py-2 items-center ml-2"
        @click="goToDiscover"
      >
        <GlobeIcon />
        <span class="ml-2">Discover</span>
      </button>
    </div>
  </div>
</template>

<script>
import { PlayIcon, GlobeIcon, PlusIcon } from 'vue-feather-icons'

export default {
  name: 'PlannerEmpty',
  components: {
    PlayIcon,
    GlobeIcon
  },
  props: {
    label: {
      type: String,
      default: 'Your planner is empty'
    }
  },
  setup(props, context) {
    function goToDiscover() {
      context.root.$router.replace({
        name: 'Discover'
      })
    }
    return {
      goToDiscover
    }
  }
}
</script>
