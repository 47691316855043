import { models } from 'feathers-vuex'

export default ($store) => {
  const { FrontPageItem, Scene } = models.api

  $store.registerModule('discover', {
    namespaced: true,
    state: () => ({
      queriedByCategoryId: {},
    }),
    mutations: {
      flagAsQueriedByCategory(state, categoryId) {
        state.queriedByCategoryId[categoryId] = true
      },
    },
    actions: {
      findItemsInCategory({ state, getters, rootGetters, commit }) {
        const categoryId = rootGetters['categories/currentCategoryId']
        const query = getters.itemsInCategoryQuery
        if (!categoryId || !query) {
          return
        }

        if (!state.queriedByCategoryId[categoryId]) {
          commit('flagAsQueriedByCategory', categoryId)
          query && Scene.find({ query, $populateParams: { name: 'withService' } })
        }
      },
    },
    getters: {
      itemsInCategoryQuery(state, getters, rootState, rootGetters) {
        const env = rootGetters['environments/currentEnv']
        const categoryId = rootGetters['categories/currentCategoryId']
        if (!env || !categoryId) {
          return null
        }
        return {
          'envsMeta.envId': env._id,
          'categoriesMeta.categoryId': categoryId,
          $limit: 250,
        }
      },
      itemsInCategory(state, getters, rootState, rootGetters) {
        const env = rootGetters['environments/currentEnv']
        if (env && env._id) {
          const res = Scene.findInStore({ query: getters.itemsInCategoryQuery })
          return res.data
        } else {
          return []
        }
      },
      frontPageItems(state, getters, rootState, rootGetters) {
        const env = rootGetters['environments/currentEnv']
        if (env && env._id) {
          const query = { envId: env._id, $sort: { sortOrder: 1 } }
          const res = FrontPageItem.findInStore({ query })
          return res.data
        } else {
          return []
        }
      },
    },
  })

  $store.watch(
    () => $store.getters['discover/itemsInCategoryQuery'],
    (query) => $store.dispatch('discover/findItemsInCategory')
  )
}
