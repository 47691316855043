<template>
  <div
    class="flex flex-row items-center justify-center h-full focus:outline-none"
  >
    <PanZoom
      class="block w-full h-full bg-gray-500 relative overflow-hidden flex flex-row items-center justify-center focus:outline-none"
    >
      <div class="h-screen focus:outline-none">
        <img ref="image" :src="src" alt="" class="max-h-full" />
      </div>
    </PanZoom>
  </div>
</template>

<script>
export default {
  name: 'ImagePanzoom',
  props: {
    src: {
      type: String,
      required: true
    },
    hotspots: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>

<style lang="postcss">
.vue-pan-zoom-scene:focus {
  @apply outline-none;
}
</style>
