<template>
  <div :class="[edit ? 'cursor-grab' : 'cursor-pointer']" @click="handleClick">
    <div
      class="rounded overflow-hidden flex flex-row items-center min-h-10"
      :class="[bgColor, item.type === 'travel' ? 'text-white' : 'text-black']"
    >
      <div class="mr-1 h-10 w-12 flex flex-row items-center justify-center">
        <component :is="item.iconName" v-if="item.type === 'travel'" />
        <ImageIcon v-else-if="item.type === 'scene'" class="text-gray-500" />
      </div>

      <div class="w-full truncate">
        <div class="font-bold truncate">
          {{ item.name }}
        </div>
        <div class="text-base">
          {{ item.date }}
        </div>
      </div>

      <!-- Menu Slot -->
      <slot name="menu"></slot>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { usePopper } from '@rovit/popper'

import { ImageIcon } from 'vue-feather-icons'
import PlaneIcon from '../PlaneIcon.vue'
import CarIcon from '../CarIcon.vue'

export default {
  name: 'PlannerItineraryItem',
  components: {
    ImageIcon,
    PlaneIcon,
    CarIcon
  },
  props: {
    item: {
      validator: val => typeof val === 'object',
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const bgColor = computed(() => {
      const colorsByType = {
        scene: 'bg-gray-300'
      }
      return props.item.bgColor || colorsByType[props.item.type]
    })

    function handleClick(e) {
      if (!props.edit) {
        context.emit('click', e)
      }
    }

    return {
      bgColor,
      handleClick
    }
  }
}
</script>

<style lang="postcss">
.sortable-chosen {
  cursor: grabbing;
}
</style>
