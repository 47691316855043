import { models, useFind, useGet } from 'feathers-vuex'
import { ref, computed, toRefs, watch } from '@vue/composition-api'
import { unwrapRef, unwrapRefs } from './utils'

/**
 * Create an Environment
 *
 * Creating an environment requires setting the org permission inside the
 * environment.orgs[] permissions array.  The server will automatically set
 * the user permissions to include the current user.
 */
export async function createEnv({ org }) {
  const { Environment } = models.api

  const env = await new Environment({
    name: 'New Environment',
    orgs: [
      {
        orgId: org._id,
        orgName: org.name,
        accessType: 'owner'
      }
    ],
    slug: 'new-environment'
  }).save()
  return { env }
}

export function getEnv({ envId }) {
  const { Environment } = models.api
  const params = computed(() => {
    return {
      $populateParams: { name: 'app' }
    }
  })
  const queryWhen = computed(() => {
    return !Environment.getFromStore(envId)
  })
  const { item: env } = useGet({
    model: Environment,
    id: envId,
    params,
    queryWhen,
    lazy: true
  })

  return { env }
}

export function findEnvs({ org, filterByOrg, search }) {
  const { Environment } = models.api

  const envsParams = computed(() => {
    const query = {
      $limit: 288,
      $sort: { name: 1 }
    }
    if (filterByOrg.value) {
      query['orgs.orgId'] = org._id
    }
    if (search.value) {
      Object.assign(query, {
        name: { $regex: search.value, $options: 'igm' }
      })
    }
    return { query, debounce: 300 }
  })
  const envsQueryWhen = computed(() => {
    // const queryWhen = Environment.findInStore({ query: {} }).data.length < 5
    // return queryWhen
    return true
  })
  const { items: environments } = useFind({
    model: Environment,
    params: envsParams,
    queryWhen: envsQueryWhen
  })
  return { environments }
}

export const rovit = ref(null)

export async function fetchRovitEnv() {
  const { Environment } = models.api

  if (rovit.value) {
    return { rovit: rovit.value }
  } else {
    const params = {
      query: { slug: 'rovit' },
      $populateParams: { name: 'app' }
    }
    const response = await Environment.find(params)
    rovit.value = response.data[0]
    return { rovit: rovit.value }
  }
}

const queriedBySlug = {}
export function findEnvBySlug(options) {
  const { queryWhen } = unwrapRefs(options)
  const { Environment } = models.api

  const envsParams = computed(() => {
    const { slug, populateName } = unwrapRefs(options)
    return {
      query: { slug },
      $populateParams: { name: populateName || 'app' }
    }
  })
  const envsQueryWhen = computed(() => {
    const { slug } = unwrapRefs(options)
    return !queriedBySlug[slug]
  })
  const data = useFind({
    model: Environment,
    params: envsParams,
    queryWhen: queryWhen || envsQueryWhen
  })
  data.env = computed(() => (data.items.value.length ? data.items.value[0] : null))
  watch(
    () => data.haveBeenRequested.value,
    val => {
      val => {
        const { slug } = unwrapRefs(options)
        if (val) {
          queriedBySlug[slug.value || slug] = true
        }
      }
    }
  )

  return data
}

export async function loadEnv({ envId }) {
  const { Environment } = models.api
  const envInStore = Environment.getFromStore(envId)
  const env = !envInStore && (await Environment.get(envId))
  return { env: envInStore || env }
}

export function getEnvBySlugFromStore({ slug }) {
  const { Environment } = models.api
  const env = computed(() => {
    const _slug = unwrapRef(slug)
    const query = { slug: _slug }
    const result = Environment.findInStore({ query })
    return result.data[0] || null
  })
  return { env }
}

export function getRovitEnvFromStore() {
  const { Environment } = models.api
  const params = computed(() => {
    return { query: { slug: 'rovit' } }
  })
  const { items: envs } = useFind({ model: Environment, params, local: true })
  const rovit = computed(() => {
    return envs[0] || null
  })
  return { rovit }
}
