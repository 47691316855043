<template>
  <svg
    width="26"
    height="16"
    viewBox="0 0 26 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.4253 5.39227H20.6849L16.4418 1.14917C15.823 0.441989 15.0275 0 14.1435 0H6.54128C3.62415 0 -0.000160679 3.18232 -0.000160679 6.27624V7.42541H0.883817C2.03299 7.42541 2.03299 9.37017 0.883817 9.37017H-0.000160679V12.2873C-0.000160679 12.7293 0.35343 13.0829 0.795419 13.0829H2.38658C2.38658 10.8729 4.06614 9.10497 6.27608 9.10497C8.48603 9.10497 10.1656 10.8729 10.1656 13.0829H17.0606C17.0606 10.8729 18.7402 9.10497 20.9501 9.10497C23.1601 9.10497 24.928 10.8729 24.8396 13.0829C25.37 13.0829 25.9004 12.9945 25.9004 12.2873V8.57459H24.6628C23.602 8.57459 23.602 6.89503 24.6628 6.89503H25.7236C25.37 6.01105 24.3976 5.39227 23.4253 5.39227ZM20.9501 9.90055C19.2706 9.90055 17.9446 11.3149 17.9446 12.9061C17.9446 14.5856 19.2706 16 20.9501 16C22.6297 16 23.9556 14.5856 23.9556 12.9061C23.9556 11.3149 22.6297 9.90055 20.9501 9.90055ZM6.27608 9.90055C4.59652 9.90055 3.27056 11.3149 3.27056 12.9061C3.27056 14.5856 4.59652 16 6.27608 16C7.95564 16 9.28161 14.5856 9.28161 12.9061C9.28161 11.3149 7.95564 9.90055 6.27608 9.90055ZM14.1435 1.59116C13.2595 1.32597 11.3148 1.06077 11.3148 2.47514V4.86188C11.3148 5.30387 11.5799 5.56906 11.9335 5.65746H16.7954C17.591 5.56906 17.4142 4.59669 17.0606 3.9779C16.3534 3.00552 15.2927 1.94475 14.1435 1.59116ZM9.6352 2.74033L9.5468 4.77348C9.5468 5.21547 9.28161 5.48066 8.83962 5.48066H2.82857C1.7678 5.48066 2.03299 4.41989 2.56338 3.8895C3.62415 2.82873 5.7457 1.32597 7.60205 1.32597C8.66282 1.32597 9.7236 1.67956 9.6352 2.74033Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'CarIcon'
}
</script>

<style lang="postcss"></style>
