import { setVal } from '../use/utils.js'

export default $store => {
  $store.registerModule('planner', {
    namespaced: true,
    state: () => ({
      isOpen: false
    }),
    mutations: {
      ...setVal('setIsOpen')
    },

    actions: {
      openPlanner({ state, commit }) {
        commit('setIsOpen', true)
      }
    },
    getters: {
      isOpen: state => state.isOpen
    }
  })
}
