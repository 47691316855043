import { get } from 'lodash'
import { setVal } from '../use/utils.js'

export default $store => {
  $store.registerModule('featureLayout', {
    namespaced: true,
    state: () => ({
      currentLayout: 'fullscreen',
      userOverrodeLayout: false,
      fullItem: 'map', // Left side in split screen
      miniItem: 'pano', // Right side in split screen
      featureObjectType: null,
      featureItem: null,
      isFeatureItemLarge: false,
      layoutPreference: null
    }),
    mutations: {
      ...setVal('setCurrentLayout'),
      ...setVal('setFullItem'),
      ...setVal('setMiniItem'),
      setLayoutFullscreen(state, { item, userOverrodeLayout = false }) {
        const featureType = get(state.featureItem, 'featureType')
        const type = featureType === 'asset' ? 'image' : featureType

        Object.assign(state, {
          fullItem: item,
          miniItem: item === 'map' ? type : 'map',
          currentLayout: 'fullscreen',
          userOverrodeLayout: state.userOverrodeLayout || userOverrodeLayout
        })
      },
      /**
       * Set the layout to split mode.
       * `item` is the clicked item
       */
      setLayoutSplit(state, { item, userOverrodeLayout = false }) {
        const featureType = get(state.featureItem, 'featureType')
        const fullItem = featureType === 'asset' ? 'image' : featureType

        Object.assign(state, {
          fullItem,
          miniItem: 'map',
          currentLayout: 'split',
          userOverrodeLayout: state.userOverrodeLayout || userOverrodeLayout
        })
      },
      setFeatureItem(state, { item, large = true }) {
        if (item) {
          const itemType = item.constructor.modelName
          if (!item.featureType) {
            return
          } else if (item.featureType === 'pano') {
            if (!item.panoUrl) {
              console.error(`${itemType} is missing a panoUrl`)
              return
            }
          } else if (item.featureType === 'image') {
            if (!item.assetUrl) {
              console.error(`${itemType} is missing an assetUrl`)
              return
            }
          }
          state.featureItem = item
          state.featureObjectType = item.constructor.name
          state.isFeatureItemLarge = large
        } else {
          state.featureItem = null
          state.featureObjectType = null
          state.isFeatureItemLarge = false
        }
      },
      setFeatureSize(state, val) {
        if (!['mini', 'large'].includes(val)) {
          throw new Error('setFeatureSize value must be "mini" or "large"')
        }
        if (val === 'mini') {
          state.isFeatureItemLarge = false
        } else {
          state.isFeatureItemLarge = true
        }
      },
      setLayoutPreference(state, { item }) {
        Object.assign(state, {
          layoutPreference: item
        })
      }
    },
    actions: {
      changeLayout({ state, commit }, { item, userOverrodeLayout }) {
        if (state.currentLayout === 'split') {
          commit('setLayoutFullscreen', { item, userOverrodeLayout })
          commit('setLayoutPreference', { item })
        } else {
          commit('setLayoutSplit', { item, userOverrodeLayout })
          commit('setLayoutPreference', { item: 'split' })
        }
      }
    },
    getters: {
      featureType(state) {
        const { featureItem } = state
        return (featureItem && featureItem.featureType) || null
      },
      shouldShowMiniFeature(state, getters) {
        const { featureItem } = state
        const attrForTypes = {
          asset: 'assetUrl',
          pano: 'panoUrl'
        }
        if (!featureItem) {
          console.warn('no featureItem')
          return false
        }
        return !!featureItem[attrForTypes[getters.featureType]]
      }
    }
  })

  /**
   * When leaving the Infobox page, clear the featureItem and set the map to fullscreen
   */
  $store.watch(
    () => $store.state.route,
    route => {
      if (route.name !== 'Infobox') {
        $store.commit('featureLayout/setLayoutFullscreen', { item: 'map' })
        $store.commit('featureLayout/setFeatureItem', { item: null })
      }
    }
  )
  /**
   * When the featureItem changes, update the fullItem or miniItem
   */
  $store.watch(
    () => $store.state.featureLayout.featureItem,
    featureItem => {
      if (featureItem) {
        const { fullItem, miniItem } = $store.state.featureLayout
        const featureType = get(featureItem, 'featureType')
        const item = featureType === 'asset' ? 'image' : featureType

        if (item) {
          if (!$store.state.featureLayout.layoutPreference) {
            $store.commit('featureLayout/setLayoutSplit', { item })
          } else {
            var layoutPreference = $store.state.featureLayout.layoutPreference
            if (layoutPreference === 'split') {
              $store.commit('featureLayout/setLayoutSplit', {
                item: layoutPreference,
                userOverrodeLayout: true
              })
            } else {
              $store.commit('featureLayout/setLayoutFullscreen', {
                item: layoutPreference,
                userOverrodeLayout: true
              })
            }
          }
        }
      }
    }
  )
}
