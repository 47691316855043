// src/store/store.js
import Vue from 'vue'
import Vuex from 'vuex'
import { FeathersVuex } from '../feathers-client'
import auth from './store.auth'

Vue.use(Vuex)
Vue.use(FeathersVuex)

import mapMain from './map-main.js'
import mapZoom from './map-zoom.js'
import mapFloating from './map-floating.js'
import mapClusterIndex from './map-cluster-index.js'
import discover from './discover.js'
import featureLayout from './feature-layout.js'
import miniInfobox from './mini-infobox.js'
import planner from './planner.js'
import device from './device.js'
import analytics from './analytics.js'

const requireModule = require.context('./services', false, /.js$/)
const servicePlugins = requireModule.keys().map(modulePath => requireModule(modulePath).default)

export default new Vuex.Store({
  state: {
    uploadcarePublicKey: 'f6ddda56c94aa9fd782f',
    mapboxToken: process.env.VUE_APP_MAPBOX_PUBLIC_TOKEN,
    stripeKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
  },
  mutations: {},
  actions: {},
  modules: {
    mapFloating,
    miniInfobox
  },
  plugins: [
    device,
    auth,
    ...servicePlugins,
    featureLayout,
    mapMain,
    mapZoom,
    mapClusterIndex,
    discover,
    planner,
    analytics
  ]
})
