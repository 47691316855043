<template>
  <div>
    <h2 class="text-2xl font-medium mb-2">Itinerary Sharing</h2>

    <div class="flex flex-col">
      <!-- isPublic -->
      <label
        v-if="isOwner"
        class="block px-3 py-1.5 bg-gray-200 dark:bg-gray-700 rounded-lg flex flex-row flex-grow items-center hover:bg-gray-300 dark:hover:bg-gray-800 transition-colors duration-200 cursor-pointer border border-gray-300 dark:border-gray-900 select-none"
      >
        <p class="flex-grow">Allow others with the link to view.</p>
        <input
          v-model="_itinerary.isPublic"
          type="checkbox"
          class="form-checkbox w-6 h-6"
          @change="() => save_itinerary('isPublic')"
        />
      </label>

      <!-- isSearchable -->
      <label
        v-if="isOwner"
        class="block px-3 py-1.5 bg-gray-200 dark:bg-gray-700 mt-1 rounded-lg flex flex-row items-center hover:bg-gray-300 dark:hover:bg-gray-800 transition-colors duration-200 cursor-pointer border border-gray-300 dark:border-gray-900 select-none"
        :class="[itinerary.isPublic ? 'opacity-100' : 'opacity-50 cursor-not-allowed']"
      >
        <p class="flex-grow">Allow others to find in search results</p>
        <input
          v-model="_itinerary.isSearchable"
          type="checkbox"
          class="form-checkbox w-6 h-6"
          :disabled="!itinerary.isPublic"
          @change="() => save_itinerary('isSearchable')"
        />
      </label>

      <button
        type="button"
        class="flex flex-row items-center rounded-lg py-1.5 bg-gray-200 hover:bg-gray-300 dark:hover:bg-gray-800 dark:bg-gray-700 border border-gray-300 dark:border-gray-900 justify-center items-center"
        :class="[
          itinerary.isPublic ? 'opacity-100' : 'opacity-50 cursor-not-allowed',
          isOwner ? 'mt-1' : null
        ]"
        :disabled="!itinerary.isPublic"
        @click="handleCopyToClipboard"
      >
        <div class="mr-2">Copy Link</div>
        <CheckIcon v-if="isCopySuccess" class="text-green-500" />
        <ClipboardIcon v-else />
      </button>

      <ItinerarySocialShare v-if="itinerary.isPublic" :itinerary="itinerary" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { handleClones } from '@rovit/utils/handle-clones.js'
import { useClipboard } from 'vue-use-web'

import { ClipboardIcon, CheckIcon } from 'vue-feather-icons'
import ItinerarySocialShare from '../ItinerarySocialShare/ItinerarySocialShare.vue'

export default {
  name: 'ItineraryPublicSettings',
  components: {
    ClipboardIcon,
    CheckIcon,
    ItinerarySocialShare
  },
  props: {
    itinerary: {
      validator: val => typeof val === 'object',
      required: true
    },
    isOwner: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _folder } = clones
    const { save_folder } = saveHandlers

    const { text, write } = useClipboard()
    const isCopySuccess = ref(false)
    const shareUrl = computed(() => {
      return (
        window.location.origin +
        window.location.pathname +
        '?menu=planner&itineraryId=' +
        props.itinerary._id
      )
    })
    function handleCopyToClipboard() {
      isCopySuccess.value = true
      const url = shareUrl.value
      write(url)
      setTimeout(() => {
        isCopySuccess.value = false
      }, 2000)
    }

    return { ...clones, ...saveHandlers, handleCopyToClipboard, isCopySuccess }
  }
}
</script>

<style lang="postcss"></style>
