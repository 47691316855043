/**
 * Floating map state (shared between all infobox-level maps)
 */
export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {},
  getters: {},
}
