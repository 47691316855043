<template>
  <button
    type="button"
    class="bg-blue-800 px-3 py-2 flex items-center flex-row rounded-full text-white focus:outline-none focus:shadow-outline mr-2"
    @click="handleClick"
  >
    <PlannerIcon />
    <span class="ml-2">Planner</span>
  </button>
</template>

<script>
import { inject } from '@vue/composition-api'
import { usePlanner } from '@/use/planner.js'

import PlannerIcon from '../PlannerIcon.vue'

export default {
  name: 'PlannerButton',
  components: {
    PlannerIcon
  },
  setup(props, context) {
    const $route = inject('$route')
    const { openPlanner, closePlanner } = usePlanner()

    function handleClick() {
      $route.value.query.menu ? closePlanner() : openPlanner()
    }

    return { handleClick }
  }
}
</script>

<style lang="postcss"></style>
