<template>
  <div class="flex flex-row text-white">
    <button
      type="button"
      class="flex flex-row items-center rounded-l-full px-3 py-1 bg-purple-700 border-r border-purple-500 focus:outline-none focus:shadow-outline"
      style="height: 36px"
    >
      <HeartIcon class="mr-1.5" />
      Save
    </button>
    <button
      type="button"
      class="flex flex-row items-center rounded-r-full px-2 bg-purple-700 mr-1.5 focus:outline-none focus:shadow-outline"
      style="height: 36px"
    >
      <CopyIcon class="mr-1.5" size="1x" />
    </button>
  </div>
</template>

<script>
import { HeartIcon, CopyIcon } from 'vue-feather-icons'

export default {
  name: 'PlannerSaveButton',
  components: {
    HeartIcon,
    CopyIcon
  }
}
</script>

<style lang="postcss"></style>
