<template>
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0525 23L18.6013 21.7496L17.2807 8.78173L21.8095 4.57259C22.7452 3.73818 23.56 1.74358 22.4998 0.500712C21.2564 -0.560255 19.2616 0.254547 18.4274 1.19054L14.2185 5.71931L1.25043 4.39872L0 5.94752L10.5941 9.68111L6.66063 13.8394L2.34518 13.8361L1.18358 15.041L6.47684 16.5229L7.95903 21.8164L9.16387 20.6551L9.16056 16.3394L13.3189 12.4062L17.0525 23Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlaneIcon'
}
</script>

<style lang="postcss"></style>
