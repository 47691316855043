const availableOptions = {
  /**
   * Size: https://images.weserv.nl/docs/size.html
   */

  width: {
    queryString: 'w',
    type: 'number' // # of pixels
  },

  height: {
    queryString: 'h',
    type: 'number' // # of pixels
  },

  // Device Pixel Ratio
  dpr: {
    queryString: 'dpr',
    type: 'number',
    default: 1
  },

  // Fit: https://images.weserv.nl/docs/fit.html
  fit: {
    queryString: 'fit',
    enum: ['inside', 'outside', 'cover', 'fill', 'contain'],
    default: 'inside' // preserve aspect ratio by default
  },

  // When true, do not enlarge the image when resizing.
  doNotEnlarge: {
    queryString: 'we',
    type: 'boolean',
    default: false
  },

  // Crop: https://images.weserv.nl/docs/crop.html
  cropAlignment: {
    queryString: 'a',
    enum: [
      'center',
      'top',
      'right',
      'bottom',
      'left',
      'top-left',
      'bottom-left',
      'bottom-right',
      'top-right',
      // Smart crop options, which only work when fit === 'cover'
      'entropy', // focus on the region with the highest Shannon entropy.
      'attention' // focus on the region with the highest luminance frequency, color saturation, and presence of skin tones.
    ],
    default: 'center'
  },

  cropFocalPoint: {
    queryString: 'focal',
    example: 'focal-x%-y%'
  },

  // Crop rectangle
  crop: {
    queryString: 'cx=,cy=,cw=,ch='
  },

  precrop: {
    queryString: 'precrop',
    type: 'boolean',
    default: false
  },

  // More options not yet implemented at https://images.weserv.nl/docs/crop.html
  // trim boring pixels from all edges that match the top-left pixel color.
  trim: {
    queryString: 'trim',
    type: 'boolean',
    default: false
  },

  // Mask: https://images.weserv.nl/docs/mask.html
  maskType: {
    queryString: 'mask',
    enum: [
      'circle',
      'ellipse',
      'triange',
      'triangle-180',
      'pentagon',
      'pentagon-180',
      'hexagon',
      'square', // Square tilted 45 degrees
      'star',
      'heart'
    ],
    default: 'circle'
  },

  maskTrim: {
    queryString: 'mtrim',
    type: 'boolean',
    default: false // Removes remaining whitespace from the mask. similar to trim
  },

  maskBg: {
    queryString: 'mbg',
    default: '' // Set the bg color of the mask
  },

  // Orientation: https://images.weserv.nl/docs/orientation.html

  // vertical flip the image
  flipVertical: {
    queryString: 'flip',
    type: 'boolean',
    default: false
  },

  // horizontal flip the image
  flipHorizontal: {
    queryString: 'flop',
    type: 'boolean',
    default: false
  },

  // rotation in degrees
  rotate: {
    queryString: 'ro',
    type: 'number',
    default: 0
  },

  // background color after rotating the image.
  rotationBg: {
    queryString: 'rbg',
    type: 'color',
    default: ''
  },

  // Adjustment: https://images.weserv.nl/docs/adjustment.html#background
  bg: {
    queryString: 'bg',
    type: 'color',
    default: ''
  },

  blur: {
    queryString: 'blur',
    type: 'number',
    default: 0 // 0 to 100
  },

  contrast: {
    queryString: 'con',
    type: 'number',
    default: 0 // -100 to 100
  },

  filter: {
    queryString: 'filt',
    enum: ['greyscale, sepia', 'duotone', 'negate'],
    default: ''
  },

  duotoneStart: {
    queryString: 'start',
    type: 'color',
    default: 'C83658'
  },
  duotoneStop: {
    queryString: 'stop',
    type: 'color',
    default: 'D8E74F'
  },

  gamma: {
    queryString: 'gam',
    type: 'number',
    default: 2.2 // 1 to 3
  },

  // Needs more work
  hsb: {
    queryString: 'mod',
    type: 'string',
    example: '1,1,1',
    default: ''
  },

  brightness: {
    queryString: 'mod',
    type: 'number',
    default: 1 // 0 to 2
  },

  saturation: {
    queryString: 'sat',
    type: 'number',
    default: 1 // 0 to 2
  },

  hue: {
    queryString: 'hue',
    type: 'number',
    default: 0 // 0 to 360 degrees
  },

  sharpen: {
    queryString: 'sharp',
    type: 'number',
    default: 0
  },

  tint: {
    queryString: 'tint',
    type: 'color', // color name, RGB, or ARGB (3 or 6 digits)
    default: ''
  },

  // Format: https://images.weserv.nl/docs/format.html
  pngAdaptiveFilter: {
    queryString: 'af',
    type: 'boolean',
    default: false
  },

  pngCompressionLevel: {
    queryString: '',
    type: 'number',
    default: 6
  },

  // Cache control
  maxAge: {
    queryString: 'maxage',
    type: 'string',
    default: '1y'
  },

  // default image url
  fallbackImageUrl: {
    queryString: 'default',
    type: 'url',
    default: ''
  },

  // override the filename
  filename: {
    queryString: 'filename',
    default: ''
  },

  output: {
    queryString: 'output',
    enum: ['jpg', 'png', 'gif', 'tiff', 'webp', 'json']
  },

  // interlace or progressive loading
  progressive: {
    queryString: 'il',
    type: 'boolean',
    default: false
  },

  // for webp or gifs
  pages: {
    queryString: 'n',
    type: 'number',
    default: -1
  },

  // for pdf, tiff, and ico files, which can have multiple pages.
  page: {
    queryString: 'page',
    type: 'number',
    default: 0
  },

  quality: {
    queryString: 'q',
    type: 'number',
    default: 85 // 0 to 100
  }
}

function defaultTransform(url, qs, val, defaultVal) {
  return `${url}&${qs}=${val || defaultVal}`
}

export function useCdnUrl(globalOptions = {}) {
  function makeCdnUrl(url, options = {}) {
    let baseUrl = `//cdn.uploaddog.com/?url=${url}`
    const finalOptions = Object.assign({}, globalOptions, options)
    const cdnUrl = Object.keys(finalOptions).reduce((newUrl, key) => {
      const value = finalOptions[key]
      const definition = availableOptions[key]
      const transform = definition.fn || defaultTransform
      newUrl = transform(
        newUrl,
        definition.queryString,
        value,
        definition.default
      )
      return newUrl
    }, baseUrl)
    return cdnUrl
  }
  return makeCdnUrl
}
