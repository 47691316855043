import { ref, computed, reactive, watch } from '@vue/composition-api'
import { areCoordsValidFor } from '@rovit/map'
import fastCopy from 'fast-copy'
import { get } from 'lodash'

export function getCoordsFromMapboxEvent(mapboxEvent) {
  const { lng, lat } = mapboxEvent.lngLat || mapboxEvent.target.getLngLat()
  const coordinates = [lng, lat]
  return coordinates
}

export function makeClusteredLayerSet({ sourceName = 'features', features }) {
  features = features.value ? features.value : features

  const layerSet = {
    sourceName,
    source: makeSource({ features }),
    unclustered: makeUnclusteredLayer({ sourceName }),
    clustered: makeClusteredLayer({ sourceName }),
    counts: makeClusterCountsLayer({ sourceName }),
  }
  return layerSet
}

export function makeSource({ features }) {
  return computed(() => {
    return {
      data: {
        type: 'FeatureCollection',
        features: makeFeatures({ features }),
      },
      // promoteId: '_id',
      cluster: true,
      clusterMaxZoom: 17, // Max zoom to cluster points on
      clusterRadius: 25, // Radius of each cluster when clustering points (defaults to 50)
    }
  })
}

export function makeFeatures({ features, current = ref({ _id: -1 }) }) {
  let unwrappedFeatures = features.value ? features.value : features
  return (
    unwrappedFeatures
      // remove invalid coordinates and current record
      .filter((item) => {
        return areCoordsValidFor(item) && item._id !== get(current.value, '_id')
      })
      .map((item, index) => {
        const _item = fastCopy(item)
        Object.assign(_item, {
          id: item._properties.mapboxId,
          properties: item._properties,
          geometry: item.location,
        })
        return _item
      })
  )
}

export function makeUnclusteredLayer({ sourceName = 'features' }) {
  return {
    id: `${sourceName}-unclustered`,
    type: 'circle',
    source: sourceName,
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-opacity': [
        'case',
        // if (feature-state.active)
        ['boolean', ['feature-state', 'active'], false],
        1, // ... make the color green
        // else if (feature-state.hover)
        ['boolean', ['feature-state', 'hover'], false],
        1,
        // else if isFromEnv
        ['boolean', ['==', ['get', 'source'], 'env']],
        0.5,
        // finally
        1,
      ],
      'circle-radius': [
        'case',
        // if (feature-state.active)
        ['boolean', ['feature-state', 'active'], false],
        12, // ... set radius to 12
        8, //  ... else set radius to 8
      ],
      'circle-color': [
        'case',
        ['boolean', ['==', ['get', 'source'], 'current']],
        ['get', 'circleColor'],
        // if (feature-state.active)
        ['boolean', ['==', ['feature-state', 'active'], true]],
        '#0FFF18', // ... make the color green
        // else if (feature-state.hover)
        ['boolean', ['==', ['feature-state', 'hover'], true]],
        '#0FFF18', // ... make the color green
        '#DD6B20', //  ... else make the color orange
      ],
      'circle-stroke-width': [
        'case',
        ['boolean', ['==', ['get', 'source'], 'current']],
        1.5,
        // if (feature-state.active)
        ['boolean', ['==', ['feature-state', 'active'], true]],
        2, // ... set stroke width to 2
        0, //  ... else stroke width is 0
      ],
      'circle-stroke-color': [
        'case',
        ['boolean', ['==', ['get', 'source'], 'current']],
        '#FF00D6',
        '#FFFFFF', //  ... else stroke width is 0
      ],
    },
  }
}

export function makeClusteredLayer({ sourceName = 'features' }) {
  const brightGreen = '#0FFF18'
  const green400 = '#68D391'
  const green600 = '#38A169'
  const purple800 = '#553C9A'

  return {
    id: `${sourceName}-clustered`,
    type: 'circle',
    source: sourceName,
    filter: ['has', 'point_count'],
    paint: {
      // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
      // with three steps to implement three types of circles:
      //   * Blue, 20px circles when point count is less than 100
      //   * Yellow, 30px circles when point count is between 100 and 750
      //   * Pink, 40px circles when point count is greater than or equal to 750
      'circle-color': [
        'step',
        ['get', 'point_count'],
        ['case', ['boolean', ['==', ['feature-state', 'active'], true]], brightGreen, green400],
        100, // if point_count is over 100
        ['case', ['boolean', ['==', ['feature-state', 'active'], true]], brightGreen, green600],
        750, // if point_count is over 750
        ['case', ['boolean', ['==', ['feature-state', 'active'], true]], brightGreen, purple800],
      ],
      'circle-radius': [
        'step',
        ['get', 'point_count'],
        ['case', ['boolean', ['==', ['feature-state', 'active'], true]], 13, 9], //   default circle radius is 9
        100, // if (point_count >= 100)
        12, //  ... set radius to 12
        750, // if (point_count >= 750)
        15, //   ... set radius to 15
      ],
      'circle-stroke-width': ['case', ['boolean', ['feature-state', 'active'], false], 2, 0],
      'circle-stroke-color': '#FFFFFF',
    },
  }
}

export function makeClusterCountsLayer({ sourceName = 'features' }) {
  return {
    id: `${sourceName}-counts`,
    type: 'symbol',
    source: sourceName,
    filter: ['has', 'point_count'],
    paint: {
      'text-color': ['step', ['get', 'point_count'], '#000000', 100, '#FFFFFF', 750, '#FFFFFF'],
    },
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
    },
  }
}
