import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

class StripeAttachPaymentMethod extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'StripeAttachPaymentMethod'
  static instanceDefaults() {
    return {}
  }
  static setPaymentMethod(arg) {
    paymentMethod = arg
  }
}

const attachServicePath = `stripe-payment-method`
const attachServicePlugin = makeServicePlugin({
  Model: StripeAttachPaymentMethod,
  service: feathersClient.service(attachServicePath),
  attachServicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(attachServicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default attachServicePlugin
