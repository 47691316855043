<template>
  <div class="planner-toolbar">
    <!-- Left -->
    <div>
      <ToolbarIconButton icon="MenuIcon" />
    </div>

    <!-- Middle -->
    <div
      class="flex flex-row items-center rounded-full px-3 py-1 border border-gray-400 font-bold text-lg"
    >
      Trip Planner
    </div>

    <!-- Right -->
    <div>
      <ToolbarIconButton icon="XIcon" @click="closePlanner" />
    </div>
  </div>
</template>

<script>
import { inject } from '@vue/composition-api'
import { usePlanner } from '@/use/planner.js'
import ToolbarIconButton from '../ToolbarIconButton.vue'

export default {
  name: 'PlannerToolbar',
  components: {
    ToolbarIconButton
  },
  setup(prop, context) {
    const { closePlanner } = usePlanner()

    return { closePlanner }
  }
}
</script>

<style lang="postcss" scoped>
.planner-toolbar {
  @apply flex flex-row items-center justify-between p-1.5;
}
</style>
