<template>
  <div>
    <button
      ref="theButton"
      type="button"
      class="flex items-end justify-end rounded-lg"
      :class="[isDraggedOver ? 'darken' : null]"
      @click="handleFolderClick"
      @dragenter.prevent="() => {}"
      @dragover="handleDragover"
      @dragleave="handleDragleave"
      @drop="handleDrop"
    >
      <FolderTile :name="folder.name" />
    </button>

    <div
      v-show="popper.isVisible"
      ref="thePopup"
      class="bg-gray-300 dark:bg-gray-700 border border-gray-400 dark:border-gray-600 rounded w-64 px-2.5 pt-1 pb-2 absolute z-10"
    >
      <FolderTileEditor :folder="folder" />
    </div>
  </div>
</template>
<script>
import FolderTile from '../FolderTile/FolderTile'
import FolderTileEditor from './../FolderTileEditor/FolderTileEditor'
import { usePopper } from '@rovit/popper'
import { ref, onUnmounted, watch, inject } from '@vue/composition-api'
import { tile150 } from '@rovit/image-transforms'
import { moveItemToFolder } from '@/use/planner-saved-items.js'

export default {
  name: 'PlannerFolderView',
  components: {
    FolderTile,
    FolderTileEditor
  },
  props: {
    folder: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const theButton = ref(null)
    const thePopup = ref(null)
    const popper = usePopper(theButton, thePopup, { offset: '10,10' })

    function folderTileimg() {
      return tile150(
        'https://f002.backblazeb2.com/file/rovit-images/2020-07-03_14-59-01_5eff47b51f251e00177d5d05_5eff47b6bf649848d2000007',
        { fit: 150 }
      )
    }
    function handleFolderClick() {
      if (props.isSelected) {
        popper.toggle()
      }
      context.emit('select')
    }
    // Hide the popper when this is no longer the selected folder.
    watch(
      () => props.isSelected,
      val => {
        if (!val) {
          popper.close()
        }
      }
    )

    const currentDraggable = inject('currentDraggable')
    const isDraggedOver = ref(false)
    function handleDragover(e) {
      e.preventDefault()
      if (currentDraggable.value.type === 'planner_item_drag') {
        isDraggedOver.value = true
      }
    }
    function handleDragleave() {
      isDraggedOver.value = false
    }
    function handleDrop() {
      isDraggedOver.value = false
      moveItemToFolder({
        item: currentDraggable.value.plannerItem,
        folder: props.folder
      })
    }

    return {
      theButton,
      thePopup,
      popper,
      folderTileimg,
      handleFolderClick,

      // Handle drop
      isDraggedOver,
      handleDragover,
      handleDragleave,
      handleDrop
    }
  }
}
</script>

<style lang="postcss" scoped>
.folder {
  width: 58px;
  height: 66px;
  background-color: #38b2ac;
}
.darken {
  filter: brightness(50%);
}
</style>
