import { computed } from 'vue-demi'

export const isIframe = computed(() => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
})

export function postMessageLogin() {
  window.parent.postMessage('rovit-login', '*')
}
export function postMessageLoggedIn() {
  window.parent.postMessage('rovit-logged-in', '*')
}
