import './plugins/composition-api'
import Vue from 'vue'
import './assets/animate.min.css'
import LogRocket from 'logrocket'
import { Notifier } from '@airbrake/browser'
import router from './router'
import { sync } from 'vuex-router-sync'
import { setupAuth0, Auth0Plugin } from './use/auth0.js'
import App from './App.vue'
import store from './store/store'

sync(store, router)

// Setup Auth0
const audience = process.env.VUE_APP_ROVIT_API_URL
const domain = process.env.VUE_APP_ROVIT_AUTH_DOMAIN
const client_id = process.env.VUE_APP_ROVIT_AUTH_CLIENT_ID

function handleReturn(appState) {
  const loginRedirect = window.localStorage.getItem('login-redirect')
  if (loginRedirect) {
    window.localStorage.removeItem('login-redirect')
    router.replace({ path: loginRedirect })
  }
}
setupAuth0({ audience, domain, client_id, handleReturn })
Vue.use(Auth0Plugin)

Vue.config.ignoredElements = ['a-scene', 'a-camera', 'a-sky', 'a-entity', 'a-sprite']

// Import all plugins
const requireModule = require.context('./plugins', false, /.js$/)
requireModule.keys().map(modulePath => {
  if (!modulePath.includes('composition')) {
    requireModule(modulePath).default
  }
})

Vue.config.devtools = true
Vue.config.productionTip = false

//init logrocket & airbrake
if (process.env.NODE_ENV !== 'development') {
  LogRocket.init(process.env.VUE_APP_LOGROCKET_KEY)
  const _ = new Notifier({
    projectId: process.env.VUE_APP_AIRBRAKE_ID,
    projectKey: process.env.VUE_APP_AIRBRAKE_KEY,
    environment: process.env.NODE_ENV
  })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
