import { models } from 'feathers-vuex'
import { keyBy, concat, get } from 'lodash'
import { setVal } from '../use/utils.js'

// Store a reference to the main mapbox instance. Set by setMap mutation.
const vueMapbox = {}

/**
 * Main map state
 */
export default $store => {
  const { MapPoint } = models.api

  $store.registerModule('mapMain', {
    namespaced: true,
    state: () => ({
      isMapReady: false,

      // Special Feature Types
      markedFeature: null,
      activeFeature: null,
      hoveredFeature: null,

      mapClickData: null,
      featureClickData: null
    }),
    mutations: {
      setMap(state, data) {
        Object.assign(vueMapbox, data)
        state.isMapReady = true
        window.map = vueMapbox.map
        // Force the map to resize
        setTimeout(function () {
          vueMapbox.map.resize()
          vueMapbox.map.scrollZoom.enable()
        }, 500)

        const { map } = vueMapbox

        map.addSource('mapbox-dem', {
          type: 'raster-dem',
          url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
          tileSize: 512,
          maxzoom: 14
        })
        // add the DEM source as a terrain layer with exaggerated height
        map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 })
      },
      stop() {
        vueMapbox.map && vueMapbox.map.stop()
      },
      redraw() {
        vueMapbox.map && vueMapbox.map.resize()
      },
      setScrollZoom(state, val) {
        const method = val ? 'enable' : 'disable'
        vueMapbox.map && vueMapbox.map.scrollZoom[method]()
      },
      ...setVal('setFeatureClickData'),
      ...setVal('setMarkedFeature'),
      ...setVal('setActiveFeature'),
      setActiveFeature(state, feature) {
        if (state.activeFeature) {
          vueMapbox.map.setFeatureState(state.activeFeature, { active: false })
        }
        if (feature) {
          vueMapbox.map.setFeatureState(feature, { active: true })
        }
        state.activeFeature = feature
      },
      setHoveredFeature(state, feature) {
        if (state.hoveredFeature) {
          vueMapbox.map.setFeatureState(state.hoveredFeature, { hover: false })
        }
        if (feature) {
          vueMapbox.map.setFeatureState(feature, { hover: true })
        }
        state.hoveredFeature = feature
      },
      // removeHover(state, feature) {
      //   if (feature) {
      //     vueMapbox.map.setFeatureState(feature, { hover: false })
      //   }
      // },
      ...setVal('setHasZoomedInitially'),
      ...setVal('setIsZooming'),
      ...setVal('setZoom'),
      ...setVal('setCenter'),
      // ...setVal('setTargetItemId'),
      /**
       * Pulls some data from the mapboxEvent (which is safe to wrap in a reactive object)
       * and puts it in the reactive mapState object. Putting the entire object in the reactive
       * breaks the map's styles upon zooming.
       */
      handleMapClick(state, vueMapboxData) {
        const { lngLat, point, type, _defaultPrevented } = vueMapboxData.mapboxEvent
        state.mapClickData = { lngLat, point, type, _defaultPrevented }
        // mapClickData.mapClick = vueMapboxData.mapboxEvent // { component, map, mapboxEvent }
      }
    },
    actions: {
      setActiveMapFeature({ state, commit }, feature) {
        // isActiveFeature is a utility function that's currently only used here.
        function isActiveFeature(feature) {
          if (feature == null || state.activeFeature == null) {
            return false
          }
          return feature.id === state.activeFeature.id
        }
        if (feature == null || isActiveFeature(feature)) {
          return false
        }
        // remove highlight from previously highlighted item
        if (state.activeFeature) {
          vueMapbox.map.setFeatureState(state.activeFeature, { active: false })
        }
        try {
          vueMapbox.map.setFeatureState(feature, { active: true }) // set clicked feature to active
          // store reference so we can deactivate it, later, if another feature is clicked.
          commit('setActiveFeature', feature)
        } catch (error) {
          commit('setActiveFeature', null)
        }
      },
      handleItemHover({ commit, dispatch, getters }, { item }) {
        if (!vueMapbox.map) {
          return
        }
        const itemId = get(item, '_properties.mapboxId')
        const features = vueMapbox.map.queryRenderedFeatures()
        const feature = features.find(feat => feat.id === itemId)
        if (feature) {
          commit('setHoveredFeature', feature)
        } else {
          dispatch('setClusterAsActive', { features, predicate: feat => feat.id === itemId })
        }
      },
      setClusterAsActive({ state, commit }, { features, predicate }) {
        const clusters = features.filter(feat => feat.properties.cluster)
        const clusterSource = vueMapbox.map.getSource('features')
        clusters.forEach(cluster => {
          const {
            properties: { cluster_id: id, point_count: count }
          } = cluster
          clusterSource.getClusterLeaves(id, count, 0, function (error, features) {
            if (!features) {
              return
            }
            if (!!features.find(predicate)) {
              commit('setActiveFeature', cluster)
            }
          })
        })
      }
    },
    getters: {
      location: (state, getters, rootState, rootGetters) => {
        const { route } = rootState
        const currentEnv = rootGetters['enviroments/currentEnv']
        const defaultLocation = {
          bbox: null,
          center: state.center,
          zoom: state.zoom
        }
        if (!currentEnv) {
          return null
        }
        if (route.name === 'Discover') {
          return {
            bbox: currentEnv.bbox,
            zoom: currentEnv.zoom || defaultLocation.zoom,
            center: currentEnv.center || defaultLocation.center
          }
        } else if (route.name === 'Infobox') {
          return {
            bbox: null,
            zoom: 12,
            center: get(state.markedFeature, 'location.coordinates') || null
          }
        }
        return defaultLocation
      },

      mapPoints(state, getters, rootState, rootGetters) {
        const env = rootGetters['environments/currentEnv']
        if (env && env._id) {
          const query = { envId: env._id }
          const res = MapPoint.findInStore({ query })
          return res.data
        } else {
          return []
        }
      },

      envFeatures(state, getters, rootState, rootGetters) {
        return rootGetters['mapMain/mapPoints']
      },
      currentFeatures(state, getters, rootState, rootGetters) {
        const currentCategoryId = rootGetters['categories/currentCategoryId']
        const frontPageItems = rootGetters['discover/frontPageItems']
        const itemsInCategory = rootGetters['discover/itemsInCategory']

        return currentCategoryId ? itemsInCategory : frontPageItems
      },

      /**
       * All environment-level features combined with the "current" feature set.
       */
      combinedFeatures(state, getters, rootState, rootGetters) {
        const { envFeatures, currentFeatures } = getters
        const currentById = keyBy(currentFeatures, '_id')
        const currentCategoryId = rootGetters['categories/currentCategoryId']

        const fromEnv = envFeatures
          .map(item => {
            item._properties = {
              _id: item._id || item.id,
              mapboxId: parseInt((item._id || item.id).slice(14), 16),
              source: 'env'
            }
            return item
          })
          .filter(i => !currentById[i._id])
        const fromCurrent = currentFeatures.map(item => {
          item._properties = {
            _id: item._id || item.id,
            mapboxId: parseInt((item._id || item.id).slice(14), 16),
            source: 'current',
            circleColor: '#BE2DC1'
          }
          if (get(state.hoveredFeature, 'id') === item._properties.mapboxId) {
            item._properties.circleColor = '#EF58F9'
          }
          return item
        })

        // When a category is selected, only show markers from the current category.
        // Tack on one item from the environment so that the map-zoom's $store.watch
        // can work properly.
        if (currentCategoryId) {
          return fromCurrent.concat([fromEnv[0]])
        }
        return concat(fromEnv, fromCurrent)
      }
    }
  })
}
