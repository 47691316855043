import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class MapPoint extends BaseModel {
  static modelName = 'MapPoint'
  static instanceDefaults() {
    return {}
  }
}

const servicePath = 'map-points'
const servicePlugin = makeServicePlugin({
  Model: MapPoint,
  service: feathersClient.service(servicePath),
  servicePath,
})

export default servicePlugin
