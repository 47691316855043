<template>
  <div draggable @dragstart="e => $emit('dragstart', e)">
    <SceneTile
      v-if="item.service"
      :src="thumbnail(item.service.primaryAssetUrl).url"
      :title="item.name"
      :subtitle="item.service.subtitle"
      :rating="false"
      @click="handlePlannerItemClick"
    >
      <template #action>
        <button
          ref="moveItemPopupButton"
          type="button"
          class="flex flex-row items-end w-6 h-6 focus:outline-none focus:shadow-outline rounded"
          @click.stop="popper.toggle"
        >
          <ChevronUpIcon />
        </button>
      </template>
    </SceneTile>

    <div
      v-show="popper.isVisible"
      ref="moveItemPopup"
      v-click-outside="popper.close"
      class="bg-gray-200 dark:bg-gray-700 border border-gray-500 dark:border-gray-600 rounded w-64 absolute z-10"
    >
      <div
        class="px-2.5 py-2 rounded-t border-b border-gray-400 dark:border-gray-600 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-800 transition-colors duration-200"
      >
        <PlannerItemMove :item="item" />
      </div>

      <div class="">
        <button
          type="button"
          class="text-red-600 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-800 p-2.5 pb-3 rounded-none block w-full text-left transition-colors duration-200 hover:text-red-900 dark:hover:text-red-400 hover:bg-red-200 rounded"
          @click="item.remove()"
        >
          Remove from Planner
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { thumbnail } from '@rovit/image-transforms'
import { usePopper } from '@rovit/popper'

import { SceneTile } from '@rovit/scene-tiles'
import { ChevronUpIcon } from 'vue-feather-icons'
import PlannerItemMove from '../PlannerItemMove/PlannerItemMove.vue'

export default {
  name: 'PlannerTile',
  components: {
    SceneTile,
    ChevronUpIcon,
    PlannerItemMove
  },
  props: {
    item: {
      validator: val => typeof val === 'object',
      required: true
    }
  },
  setup(props, context) {
    /**
     * move planner-item popper
     */
    const moveItemPopupButton = ref(null)
    const moveItemPopup = ref(null)
    const popper = usePopper(moveItemPopupButton, moveItemPopup, {
      offset: '10,10',
      placement: 'top'
    })

    function handlePlannerItemClick() {
      console.log('implement planner item click handler')
    }

    return {
      moveItemPopupButton,
      moveItemPopup,
      popper,
      thumbnail,
      handlePlannerItemClick
    }
  }
}
</script>

<style lang="postcss"></style>
