import feathersClient, {
  makeServicePlugin,
  BaseModel,
  models
} from '../../feathers-client'
import { makeModel } from '@rovit/models/PlannerSavedItem'
import { omit } from 'lodash'

const servicePath = 'planner-saved-items'
const servicePlugin = makeServicePlugin({
  Model: makeModel({ BaseModel, models }),
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [
      function omitPopulatedService(context) {
        context.data = omit(context.data, 'service')
        return context
      }
    ],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
