<template>
  <Dock class="shadow-lg">
    <div
      class="flex flex-col items-center justify-center mt-2 text-center"
      style="min-height: 200px"
    >
      The {{ slug }} environment could not be found.
    </div>
  </Dock>
</template>

<script>
import { Dock } from '@rovit/dock'

export default {
  name: 'EnvNotFound',
  metaInfo: {
    title: 'No Environment'
  },
  components: {
    Dock
  },
  setup(props, context) {
    const slug = context.root.$route.params.env
    return { slug }
  }
}
</script>
