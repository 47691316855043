<template>
  <div
    class="overflow-y-scroll transition-all duration-300 ease-in-out planner-container"
    :style="style"
  >
    <div class="planner-wrapper p-8">
      <!-- Tabs -->
      <div class="w-full flex flex-row space-x-2 hidden">
        <div
          class="p-1 flex items-center space-x-1 bg-gray-200 text-black dark:text-white dark:bg-gray-800"
        >
          <div><ListIcon class="text-blue-700" /></div>
          <div>Inbox History</div>
        </div>
        <div
          class="p-1 flex items-center space-x-1 bg-gray-200 text-black dark:text-white dark:bg-gray-800"
        >
          <div><CompassIcon class="text-red-900" /></div>
          <div>Environments Visited</div>
        </div>
        <div
          class="p-1 flex items-center space-x-1 bg-gray-200 text-black dark:text-white dark:bg-gray-800"
        >
          <div><BookmarkIcon class="text-green-700" /></div>
          <div>Saved Searches</div>
        </div>
        <div
          class="p-1 flex items-center space-x-1 bg-gray-200 text-black dark:text-white dark:bg-gray-800"
        >
          <div><BookIcon class="text-green-700" /></div>
          <div>Planner</div>
        </div>
      </div>

      <!-- Content -->
      <div class="content-height mt-2 flex flex-row space-x-2">
        <div
          class="hidden w-64 rounded-xl border-2 border-gray-300 bg-gray-200 text-black dark:text-white dark:bg-gray-800 py-2 px-4 text-left"
        >
          <div class="text-xl font-bold">Environments Visited</div>
          <div class="mt-2 space-y-2">
            <div>Kanab</div>
            <div>Rovit</div>
            <div>St George Chamber</div>
          </div>
        </div>
        <div class="w-full h-full">
          <div v-if="isMenuPlanner" class="h-full">
            <Planner v-if="hasOpenedOnce && shouldShowInbox" />
            <PlannerFolder v-else-if="$route.query.folderId" />
            <PlannerItinerary v-else-if="$route.query.itineraryId" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref, watch, watchEffect } from '@vue/composition-api'
import { usePlanner } from '@/use/planner.js'

import { ListIcon, CompassIcon, BookmarkIcon, BookIcon } from 'vue-feather-icons'
import Planner from './Planner.vue'
import PlannerItinerary from '../PlannerItinerary/PlannerItinerary.vue'
import PlannerFolder from '../PlannerFolder/PlannerFolder.vue'

const hasOpenedOnce = ref(false)

export default {
  name: 'PlannerContainer',
  components: {
    ListIcon,
    CompassIcon,
    BookmarkIcon,
    BookIcon,
    Planner,
    PlannerItinerary,
    PlannerFolder
  },
  setup(props, context) {
    const $route = inject('$route')
    const $store = inject('$store')
    const $router = inject('$router')

    const isPlannerOpen = computed(() => $store.getters['planner/isOpen'])
    const isMenuPlanner = computed(() => $route.value.query.menu === 'planner')
    const { openPlanner } = usePlanner()

    // Only load the planner if it has been opened at least once.
    watch(
      () => $route.value.query.menu,
      planner => {
        if (planner && !hasOpenedOnce.value) {
          hasOpenedOnce.value = true
        }
      },
      { immediate: true }
    )

    watch(
      () => isPlannerOpen.value,
      isOpen => {
        if (isOpen) {
          openPlanner()
        }
      },
      { immediate: true }
    )

    const shouldBeOpen = computed(() => {
      return $route.value.query.menu
    })

    watchEffect(() => {
      if (shouldBeOpen.value) {
        document.querySelector('body').style.overflow = 'hidden'
      } else {
        document.querySelector('body').style.overflow = 'auto'
      }
    })

    const shouldShowInbox = computed(() => {
      return !$route.value.query.itineraryId && !$route.value.query.folderId
    })
    const style = computed(() => {
      const top = shouldBeOpen.value ? '60px' : 'calc(100%)'
      return { top }
    })

    return { style, hasOpenedOnce, shouldShowInbox, isMenuPlanner }
  }
}
</script>

<style lang="postcss" scoped>
.planner-container {
  @apply fixed z-40 w-full bottom-0 overflow-hidden max-h-full;
}
.planner-wrapper {
  @apply bg-white h-full rounded-t-lg shadow-2xl border border-gray-200;
}
@screen dark {
  .planner-wrapper {
    @apply bg-gray-900 text-white;
  }
}

@screen sm {
  .planner-wrapper {
    @apply mx-6;
  }
}
@screen md {
  .planner-wrapper {
    @apply mx-8;
  }
}
@screen lg {
  .planner-wrapper {
    @apply mx-12;
  }
}
@screen xl {
  .planner-wrapper {
    @apply mx-auto;
    max-width: 1200px;
  }
}

.content-height {
  height: calc(100% - 32px);
}
</style>
