<template>
  <div class="relative">
    <svg width="70" height="80" viewBox="0 0 58 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 0C1.79086 0 0 1.79086 0 4V7.90932V61.015V64.0001C0 65.1046 0.895432 66.0001 2 66.0001H56C57.1046 66.0001 58 65.1046 58 64.0001V7.90932C58 6.80475 57.1046 5.90932 56 5.90932H22V4C22 1.79086 20.2091 0 18 0H4Z"
        fill="#38B2AC"
      />
      <path
        v-if="src"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 0C1.79086 0 0 1.79086 0 4V7.90932V61.015V64.0001C0 65.1046 0.895432 66.0001 2 66.0001H56C57.1046 66.0001 58 65.1046 58 64.0001V7.90932C58 6.80475 57.1046 5.90932 56 5.90932H22V4C22 1.79086 20.2091 0 18 0H4Z"
        :fill="`url(${hashPatternId})`"
      />
      <defs>
        <pattern :id="`pattern-${id}`" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use
            :xlink:href="hashImageId"
            transform="translate(-0.450677) scale(0.0144042 0.0126582)"
          />
        </pattern>
        <image
          :id="imageId"
          width="132"
          height="79"
          :href="src"
          preserveAspectRatio="xMinYMin slice"
        />
      </defs>
    </svg>
    <div
      class="absolute bottom-0 w-full left-0 text-sm text-center mb-1 text-white leading-tight mb-1.5"
      :class="[src && 'with-text-shadow']"
    >
      {{ name }}
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { randomString } from '@/use/random-string.js'

export default {
  name: 'FolderTile',
  props: {
    src: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'New Folder'
    }
  },
  setup(props, context) {
    const id = randomString(10)
    const imageId = computed(() => `image-${id}`)
    const hashImageId = computed(() => `#image-${id}`)
    const patternId = computed(() => `pattern-${id}`)
    const hashPatternId = computed(() => `#pattern-${id}`)

    return { id, imageId, hashImageId, patternId, hashPatternId }
  }
}
</script>

<style lang="postcss" scoped>
.with-text-shadow {
  /* offset-x | offset-y | blur-radius | color */
  text-shadow: 1px 1px 3px white;
}
</style>
