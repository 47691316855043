<template>
  <div class="feature-layout dark:bg-black">
    <slot name="modals" />

    <Navbar :env="env" />

    <!-- Feature Wrapper -->
    <div class="relative feature" :class="[fullscreenButtonSlideUpClass]">
      <!-- Map -->
      <div ref="mapWrapperEl" class="map-wrapper" :class="[mapClass]">
        <MapMain
          v-if="env && center"
          class="main-map"
          :access-token="mapboxToken"
          :env="env"
          :features="features"
          :marked-feature="markedFeature"
          @click="handleMapClick"
        />
        <div class="feature-tools">
          <ToolbarIconButton
            class="bg-white dark:bg-gray-800"
            icon="Maximize2Icon"
            @click.native="() => handleFullscreenButtonClick('map')"
          />
        </div>
      </div>

      <!-- Pano -->
      <div
        v-show="featureType === 'pano'"
        ref="panoWrapperEl"
        class="pano-wrapper"
        :class="[panoClass]"
      >
        <Pannellum
          v-if="featureItem"
          :scene="featureScene"
          class="h-full pano"
          :mouse-zoom="false"
          :keyboard-control="false"
          :double-click-zoom="false"
          :auto-rotate-stop-delay="10000"
          @load="handlePannellumLoad"
          @hotspot-click="handlePanoHotspotClick"
          @click="handlePannellumClick"
        />
        <div class="feature-tools">
          <ToolbarIconButton
            class="bg-white dark:bg-gray-800"
            icon="Maximize2Icon"
            @click.native="() => handleFullscreenButtonClick('pano')"
          />
        </div>
      </div>

      <!-- Image -->
      <div
        v-show="featureType === 'asset'"
        ref="imageWrapperEl"
        :class="[imageClass]"
        class="image-wrapper"
      >
        <ImageHotspots
          v-if="featureType === 'asset'"
          :src="progressiveUrl"
          :hotspots="featureItem.hotspots"
          class="flex flex-row items-center justify-center h-full"
          @init="handleImageHotspotsInit"
          @hotspot-click="handleImageHotspotClick"
        />
        <div class="feature-tools">
          <ToolbarIconButton
            class="bg-white dark:bg-gray-800"
            icon="Maximize2Icon"
            @click.native="() => handleFullscreenButtonClick('image')"
          />
        </div>
      </div>
    </div>

    <slot name="content" @update-map-layer="updateMapLayer" />

    <div class="footer bg-green-500 text-white px-2 rounded-t-lg pt-1 pb-0.5">
      <a href="https://rovit.com" class="inline-flex flex-row items-center font-semibold">
        <img
          src="https://cdn.rovit.com/rovit-frog-small.png"
          alt="Rovit Frog Logo"
          class="h-4 mr-1"
        />
        Rovit.com
      </a>
    </div>
  </div>
</template>

<script>
import { computed, ref, toRef, watch, toRefs, inject, onMounted } from '@vue/composition-api'
import { featureLayout, setFeatureSize } from '@/use/feature-layout.js'
import {
  sameSizeProgressiveQ5,
  sameSizeProgressiveQ20,
  sameSizeProgressiveQ40,
  sameSizeProgressiveQ80,
  progressive256,
  progressive512,
  progressive2048,
  progressive4096,
  progressive8192
} from '@rovit/image-transforms'
// import {
//   progressive256,
//   progressive512,
//   progressive1024,
//   progressive2048,
//   progressive4096,
// } from '@rovit/image-transforms'

import { progressiveLoadImage } from '@rovit/use-progressive-loading'

import Navbar from '../components/Navbar/Navbar.vue'
import { Pannellum } from '@rovit/pannellum'
import ImageHotspots from '../components/ImageHotspots.vue'
import MapMain from '../components/MapMain'
import ToolbarIconButton from '../components/ToolbarIconButton.vue'

export default {
  name: 'FeatureLayout',
  components: {
    Navbar,
    Pannellum,
    ImageHotspots,
    MapMain,
    ToolbarIconButton
  },
  props: {
    env: {
      validator: val => typeof val === 'object',
      required: true
    },
    features: { type: Array, required: true }
  },
  setup(props, context) {
    const $store = inject('$store')

    const center = computed(() => {
      const getter = $store.getters['mapZoom/currentLocation']
      return getter.center
    })
    const mapboxToken = computed(() => {
      if(props.env && props.env.mapboxToken) {
        return props.env.mapboxToken
      }
      return $store.state.mapboxToken
    })

    // Feature Item
    const featureItem = computed(() => $store.state.featureLayout.featureItem)

    const panoUrl = computed(() => (featureItem.value ? featureItem.value.panoUrl : ''))
    const featureScene = computed(() => {
      if (featureItem.value) {
        const { hotspots } = featureItem.value
        /**
         * Adding 2 transfrom for hotspot image, Since size might
         * not be greater than 512 wide
         */
        const transforms = [progressive256, progressive512]
        const result = hotspots.map(hs => {
          const { url: iconUrl } = progressiveLoadImage({ url: { value: hs.iconUrl }, transforms })
          return Object.assign(hs, { iconUrl: iconUrl.value })
        })

        const { url } = progressiveLoadImage({
          url: panoUrl,
          transforms: [progressive256, progressive4096]
        })
        return Object.assign({}, featureItem.value, {
          panoUrl: `https:${url.value}`,
          hotspots: result
        })
      }

      return {}
    })

    const isFeatureItemLarge = computed(() => $store.state.featureLayout.isFeatureItemLarge)
    const featureType = computed(() => $store.getters['featureLayout/featureType'])
    const shouldShowMiniFeature = computed(
      () => $store.getters['featureLayout/shouldShowMiniFeature']
    )

    const fullscreenButtonSlideUpClass = ref('')
    watch(
      () => featureItem.value,
      featureItem => {
        if (featureItem) {
          setTimeout(() => {
            fullscreenButtonSlideUpClass.value = 'fullscreen-button-slide-up'
          }, 1000)
          setTimeout(() => {
            fullscreenButtonSlideUpClass.value = ''
          }, 3000)
        }
      }
    )

    const assetUrl = computed(() => (featureItem.value ? featureItem.value.assetUrl : ''))
    const progressiveUrl = computed(() => {
      const transforms = [
        sameSizeProgressiveQ5,
        sameSizeProgressiveQ20,
        sameSizeProgressiveQ40,
        sameSizeProgressiveQ80
      ]
      if (featureItem.value) {
        const { url } = progressiveLoadImage({
          url: assetUrl,
          transforms
        })
        return url.value
      }
      return ''
    })

    // Control the layout in the store.
    const currentLayout = computed(() => $store.state.featureLayout.currentLayout)
    const fullItem = computed(() => $store.state.featureLayout.fullItem)
    const miniItem = computed(() => $store.state.featureLayout.miniItem)

    // Pano Element
    const panoWrapperEl = ref(null)
    const panoClass = computed(() => {
      if (fullItem.value === 'pano') {
        return currentLayout.value === 'split' ? 'feature-left' : 'feature-full'
      } else if (miniItem.value === 'pano') {
        return currentLayout.value === 'split' ? 'feature-right' : 'feature-mini'
      } else {
        return 'hidden'
      }
    })

    // Image Element
    const imageWrapperEl = ref(null)
    const imageClass = computed(() => {
      if (fullItem.value === 'image') {
        return currentLayout.value === 'split' ? 'feature-left' : 'feature-full'
      } else if (miniItem.value === 'image') {
        return currentLayout.value === 'split' ? 'feature-left' : 'feature-mini'
      } else {
        return 'hidden'
      }
    })

    // Map Element
    const mapWrapperEl = ref(null)
    const mapClass = computed(() => {
      if (fullItem.value === 'map') {
        return currentLayout.value === 'split' ? 'feature-right' : 'feature-full'
      } else if (miniItem.value === 'map') {
        return currentLayout.value === 'split' ? 'feature-right' : 'feature-mini'
      } else {
        return 'hidden'
      }
    })

    const markedFeature = computed(() => $store.state.mapMain.markedFeature)

    // Handle Fullscreen Buttons
    function handleFullscreenButtonClick(item) {
      $store.dispatch('featureLayout/changeLayout', { item, userOverrodeLayout: true })
      resizeAll()
    }

    function resizeAll() {
      setTimeout(() => {
        panoViewer && panoViewer.resize()
        $store.commit('mapZoom/redraw')
        $store.dispatch('mapZoom/zoomToMarkedFeature', { mapMethod: 'jumpTo' })
      }, 50)
    }

    let panZoomInstance
    let resetPanZoomInstance
    let calcImageSize
    function handleImageHotspotsInit({ panZoom, resetPanZoom, calculateImageSize }) {
      panZoomInstance = panZoom
      resetPanZoomInstance = resetPanZoom
      calcImageSize = calculateImageSize
    }

    watch(
      () => isFeatureItemLarge.value,
      async isFeatureItemLarge => {}
    )

    async function miniToFull() {}
    async function fullToMini() {}

    async function featureMiniToFull({ toMiniEl }) {}
    function featureFullToMini() {}
    function resetFeature() {}

    async function mapMiniToFull() {}
    async function mapFullToMini() {}
    function redrawMap() {
      $store.commit('mapMain/redraw')
    }

    function handlePanoClick() {
      console.log('handlePanoClick')
    }

    // If the map is small, make it big onclick.
    function handleMapClick({ component, map, mapboxEvent }) {
      if (isFeatureItemLarge.value) {
        $store.commit('featureLayout/setFeatureSize', 'mini')
      }
    }
    function updateMapLayer(data) {
      console.log('updateMapMarkers', data)
    }

    // Pannellum Pano
    let panoViewer = null
    function handlePannellumLoad({ viewer }) {
      panoViewer = viewer
      panoViewer.startAutoRotate(-2, panoViewer.getPitch())
    }
    function handlePanoHotspotClick({ event, hotspot }) {
      $store.dispatch('miniInfobox/openWithLink', hotspot.link)
    }
    function handlePannellumClick() {
      panoViewer.stopAutoRotate()
    }

    function handleImageHotspotClick({ event, hotspot, index, offset }) {
      if (isFeatureItemLarge.value) {
        $store.dispatch('miniInfobox/openWithLink', hotspot.link)
      }
    }

    // CSS height fix for mobile safari. Prevents bottom of page from clipping.
    onMounted(() => {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
    return {
      featureLayout,
      featureItem,
      featureScene,
      featureType,
      progressiveUrl,
      center,

      // Hotspot Handlers
      handlePanoHotspotClick,
      handleImageHotspotClick,
      handlePannellumClick,

      // Pano Element
      panoWrapperEl,
      panoClass,

      imageWrapperEl,
      imageClass,

      mapWrapperEl,
      mapClass,
      handleMapClick,

      handleFullscreenButtonClick,
      fullscreenButtonSlideUpClass,

      // Pannellum
      handlePannellumLoad,

      // Mapbox
      mapboxToken,
      shouldShowMiniFeature,
      markedFeature,

      // Handlers
      handlePanoClick,
      updateMapLayer,
      handleImageHotspotsInit
    }
  }
}
</script>

<style lang="postcss" scoped>
.map-wrapper,
.image-wrapper,
.pano-wrapper {
  @apply relative overflow-hidden;
}

.feature-full {
  @apply h-screen w-full top-0 left-0 bottom-0 right-0;
}
.feature-mini {
  @apply absolute h-8 w-8 z-10;
  bottom: 60px;
  right: 20px;
}
@screen sm {
  .feature-mini {
    @apply h-8 w-8 rounded;
    bottom: 60px;
    right: 20px;
  }
}
@screen md {
  .feature-mini {
    @apply h-16 w-16 rounded;
    bottom: 60px;
    right: 20px;
  }
}
@screen lg {
  .feature-mini {
    @apply h-32 w-64 rounded;
    bottom: 60px;
    right: 20px;
  }
}
@screen xl {
  .feature-mini {
    @apply h-32 w-64 rounded;
    bottom: 60px;
    right: 20px;
  }
}

/* Screen less than sm wide will have top-bottom view */
.feature-left {
  @apply absolute left-0 top-0 right-0;
  bottom: 50%;
}
.feature-right {
  @apply absolute right-0 bottom-0 left-0;
  top: 50%;
}

@screen sm {
  .feature-left {
    @apply absolute left-0 top-0 bottom-0;
    right: 50%;
  }
  .feature-right {
    @apply absolute right-0 top-0 bottom-0;
    left: 50%;
  }
}

/* Feature Tools */
.feature-tools {
  @apply absolute transition-all duration-500;
  z-index: 100;
}

@screen sm {
  /* Animate the feature tools upwards when the dock bounces (sync movement with the dock) */
  .fullscreen-button-slide-up .feature-left .feature-tools,
  .fullscreen-button-slide-up .feature-right .feature-tools {
    bottom: 152px;
  }
}

.feature-left .feature-tools {
  bottom: 8px;
  right: 8px;
}

.feature-right .feature-tools {
  top: 8px;
  right: 8px;
}

@screen sm {
  .feature-right .feature-tools {
    top: auto;
    bottom: 52px;
    left: 8px;
  }
  .feature-left .feature-tools {
    bottom: 52px;
    right: 8px;
  }
}

.feature-large .feature-tools {
  @apply hidden;
}
.feature-mini .feature-tools {
  top: 2px;
  left: 2px;
}
</style>

<style lang="postcss">
.navbar {
  @apply flex;
  position: fixed;
  top: 10px;
  width: 100%;
  z-index: 150;
}
.feature {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.feature-mini .pnlm-controls-container {
  display: none;
}
.feature-mini .mapboxgl-control-container {
  /* display: none; */
}
.feature-left .pnlm-controls-container,
.feature-full .pnlm-controls-container {
  @apply rounded-lg;
  position: absolute;
  top: 60px;
  left: auto;
  right: 4px;
  z-index: 1;
  transform: scale(1.1);
}
.feature-left .pnlm-controls,
.feature-full .pnlm-controls {
  @apply shadow-xs border-gray-500;
  border-radius: 5px;
}

/* Shrink the mapbox logo for small maps */
.mapboxgl-ctrl-bottom-left {
  transform: scale(0.65) translate(-28px, 12px);
}
.feature-full .mapboxgl-ctrl-bottom-left {
  transform: scale(1);
}
/* Hide the "improve this map" comment for smaller maps */
.mapboxgl-ctrl-bottom-right {
  visibility: hidden;
}
.main-map .mapboxgl-ctrl-bottom-right {
  visibility: visible;
}
.feature-full .mapboxgl-ctrl-top-right,
.feature-right .mapboxgl-ctrl-top-right {
  top: 48px;
}
.feature-mini .mapboxgl-ctrl-top-right {
  top: -10px;
  right: -10px;
}
/* Move the mapbox logo above the dock for the main-map. */
.main-map .mapboxgl-ctrl-bottom-left {
  z-index: 0 !important;
  bottom: 192px !important;
}
.main-map .mapboxgl-ctrl-bottom-right {
  z-index: 0 !important;
  bottom: 192px !important;
}
</style>
