<template>
  <div>
    <!-- Planner Saved Items -->
    <draggable
      v-if="unusedItems.length"
      class="w-56 list-group min-h-32"
      :list="unusedItems"
      :group="{ name: 'itinerary', pull: 'clone' }"
      style="min-width: 248px"
    >
      <PlannerItineraryItem
        v-for="(item, index) in convertedUnusedItems"
        :key="item.id"
        :item="item"
        class="mb-2"
      >
        <template #menu>
          <PlannerSavedItemMenu
            :item="unusedItems[index]"
            @save="() => savePlannerSavedItem()"
            @remove="() => removePlannerSavedItem(unusedItems[index])"
          />
        </template>
      </PlannerItineraryItem>
    </draggable>

    <!-- No Planner Saved Items -->
    <div
      v-if="!plannerSavedItems.length"
      class="flex-grow h-32 bg-gray-300 dark:bg-gray-800 rounded p-3 flex flex-row items-center justify-center"
    >
      No Saved Items
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import _flatten from 'lodash/flatten.js'
import { prepareContainerItem, prepareTravelItem } from '@/use/itineraries.js'

import draggable from 'vuedraggable'
import PlannerSavedItemMenu from '../PlannerSavedItemMenu/PlannerSavedItemMenu.vue'
import PlannerItineraryItem from '../PlannerItineraryItem/PlannerItineraryItem.vue'

export default {
  name: 'PlannerSavedItems',
  components: {
    draggable,
    PlannerSavedItemMenu,
    PlannerItineraryItem
  },
  props: {
    plannerSavedItems: {
      type: Array,
      required: true
    },
    itinerary: {
      validator: val => typeof val === 'object',
      required: true
    }
  },
  setup(props, context) {
    const usedPlannerSavedItemIds = computed(() => {
      if (!props.itinerary) {
        return []
      }
      const usedSets = props.itinerary.containers.reduce((usedSets, current) => {
        const items = current.items.map(item => item.plannerSavedItemId).filter(i => i)
        usedSets.push(items)
        return usedSets
      }, [])
      return _flatten(usedSets)
    })
    const unusedItems = computed(() => {
      return props.plannerSavedItems.filter(psi => !usedPlannerSavedItemIds.value.includes(psi._id))
    })
    const convertedUnusedItems = computed(() => {
      return unusedItems.value.map(item => prepareContainerItem({ item }))
    })

    return { usedPlannerSavedItemIds, unusedItems, convertedUnusedItems }
  }
}
</script>

<style lang="postcss"></style>
