import Vue from 'vue'
import VueRouter from 'vue-router'

import AppView from '../views/AppView.vue'
import HotspotTest from '../components/HotspotTest.vue'

Vue.use(VueRouter)

const routes = [
  {
    name: 'App',
    path: '/',
    component: AppView,
    redirect: '/discover',
    children: [
      {
        name: 'Infobox',
        path: 'discover/:env/infobox/:sceneId',
        component: () =>
          import(
            /* webpackChunkName: "infobox" */ '../components/Infobox/Infobox.vue'
          )
      },
      {
        name: 'Map',
        path: 'map-test',
        component: () =>
          import(/* webpackChunkName: "map" */ '../components/MapTest.vue')
      },
      { path: '/discover', redirect: '/discover/rovit' },
      {
        name: 'Discover',
        path: 'discover/:env?',
        component: () =>
          import(
            /* webpackChunkName: "discover" */ '../components/Discover/Discover.vue'
          )
      },
      {
        name: 'Planner',
        path: 'planner',
        component: () =>
          import(
            /* webpackChunkName: "planner" */ '../components/Planner/Planner.vue'
          )
      },
      {
        name: 'PlannerFolder',
        path: 'planner/folder/:folderId',
        component: () =>
          import(
            /* webpackChunkName: "planner-folder" */ '../components/PlannerFolder/PlannerFolder.vue'
          ),
        children: [
          {
            name: 'PlannerFolderItinerary',
            path: 'itinerary',
            component: () =>
              import(
                /* webpackChunkName: "planner-folder" */ '../components/PlannerFolderItinerary/PlannerFolderItinerary.vue'
              )
          },
          {
            name: 'PlannerFolderShare',
            path: 'share',
            component: () =>
              import(
                /* webpackChunkName: "planner-folder" */ '../components/PlannerFolderShare/PlannerFolderShare.vue'
              )
          }
        ]
      },
      {
        name: 'PlannerItinerary',
        path: 'planner/itineraries/:itineraryId',
        component: () =>
          import(
            /* webpackChunkName: "planner" */ '../components/PlannerItinerary/PlannerItinerary.vue'
          )
      },
      {
        name: 'Community',
        path: 'community',
        component: () =>
          import(
            /* webpackChunkName: "community" */ '../components/Community/Community.vue'
          )
      },
      {
        name: 'Guidebooks',
        path: 'guidebooks',
        component: () =>
          import(
            /* webpackChunkName: "guidebooks" */ '../components/Guidebooks/Guidebooks.vue'
          )
      },
      {
        name: 'Rovit',
        path: 'rovit',
        component: () =>
          import(
            /* webpackChunkName: "rovit" */ '../components/Rovit/Rovit.vue'
          )
      }
    ]
  },
  { name: 'HotspotTest', path: '/hotspots', component: HotspotTest }
  // { path: '/planner', redirect: '/app/planner' },
  // { path: '/community', redirect: '/app/community' },
  // { path: '/guidebooks', redirect: '/app/guidebooks' },
  // { path: '/rovit', redirect: '/app/rovit' }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
