import { models, useFind } from 'feathers-vuex'
import { watch, computed } from '@vue/composition-api'

export function findConfig({ local = true }) {
  const params = computed(() => ({}))
  const configData = useFind({ model: models.api.Config, params, local })

  const defaultEnvConfigName = 'defaultEnvSlug'
  const defaultEnvSlug = 'rovit'
  const existingDefaultEnvConfig = computed(
    () =>
      configData.items.value.find(i => i.name === defaultEnvConfigName) || null
  )

  // watch(
  //   () => configData.haveLoaded.value,
  //   async isLoaded => {
  //     if (!existingDefaultEnvConfig.value) {
  //       await new models.api.Config({
  //         name: 'defaultEnvSlug',
  //         value: defaultEnvSlug
  //       }).save()
  //     }
  //   },
  //   { lazy: true }
  // )

  return configData
}
