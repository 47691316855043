var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative max-h-screen overflow-y-scroll bg-white border border-gray-400 rounded-lg shadow-2xl map-cluster-index dark:bg-gray-900 dark:text-gray-200 dark:border-gray-800"},[_c('div',{staticClass:"p-8 container-width"},[_c('div',{staticClass:"flex flex-row w-full pl-2"},[_c('div',{staticClass:"flex items-center w-1/2 px-1 text-black bg-gray-200 rounded"},[_c('SearchIcon',{}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"flex-grow h-full py-2 pl-2 bg-transparent outline-none focus:outline-none",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}})],1),_c('div',{staticClass:"ml-2"},[_c('button',{staticClass:"flex inline h-full p-1 text-white bg-green-500 rounded outline-none",on:{"click":_vm.handleZoomClick}},[_c('ZoomInIcon'),_vm._v(" Zoom Map ")],1)])]),_c('div',{class:{ '-ml-2': !_vm.shouldShowRovit }},[_c('DiscoverCategories',{attrs:{"primary-category":_vm.primaryCategory,"categories":_vm.usedCategories,"category-id-stack":_vm.categoryIdStack,"env":_vm.currentEnv,"is-clustered":true,"should-show-rovit":_vm.shouldShowRovit},on:{"update:categoryIdStack":function($event){_vm.categoryIdStack=$event},"update:category-id-stack":function($event){_vm.categoryIdStack=$event}}})],1),_c('div',{staticClass:"flex flex-wrap pl-2.5"},_vm._l((_vm.scenes),function(scene){return _c('DiscoverInfobox',{key:scene._id,staticClass:"mr-1.5 mt-2",attrs:{"scene":scene,"env":_vm.currentEnv,"link":_vm.makeLink({ scene: scene, service: scene.service, env: _vm.currentEnv })},on:{"add-to-planner":function () { return _vm.handleAddToPlanner({ scene: scene }); },"open-link":function (ref) {
          var link = ref.link;

          return _vm.handleOpenLink({ link: link, item: scene });
}}})}),1),(_vm.scenes.length)?_c('div',{staticClass:"flex justify-center mt-3"},[_c('FeathersVuexPagination',{attrs:{"latest-query":_vm.latestQuery},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var currentPage = ref.currentPage;
          var pageCount = ref.pageCount;
          var toStart = ref.toStart;
          var toEnd = ref.toEnd;
          var toPage = ref.toPage;
          var next = ref.next;
          var prev = ref.prev;
          var canNext = ref.canNext;
          var canPrev = ref.canPrev;
return [_c('SidebarPagination',{attrs:{"current-page":currentPage,"page-count":pageCount,"can-prev":canPrev,"can-next":canNext},on:{"to-start":toStart,"to-end":toEnd,"to-page":toPage,"next":next,"prev":prev}})]}}],null,false,2817206174),model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1):_vm._e()]),_c('div',{staticClass:"absolute top-0 right-0 px-1 py-2"},[_c('ToolbarIconButton',{attrs:{"icon":"XIcon"},on:{"click":_vm.handleClose}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }