<template>
  <div>
    <!-- Travel Tiles -->
    <draggable
      class="w-56 mt-2 flex flex-row items-center"
      :list="travelTiles"
      :group="{ name: 'itinerary', pull: 'clone', put: false }"
      style="min-width: 248px"
    >
      <div
        v-for="item in travelTiles"
        :key="item.id"
        class="h-10 w-10 rounded text-white flex flex-row items-center justify-center mr-1 mb-2"
        :class="item.bgColor"
      >
        <component :is="item.icon" />
      </div>
    </draggable>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'

import draggable from 'vuedraggable'
import CarIcon from '../CarIcon.vue'
import PlaneIcon from '../PlaneIcon.vue'

export default {
  name: 'PlannerTravelTiles',
  components: {
    draggable
  },
  setup(props, context) {
    // Travel Tiles
    const travelTiles = computed(() => {
      return [
        { travelType: 'Flight', icon: PlaneIcon, bgColor: 'bg-blue-600' },
        { travelType: 'Driving Time', icon: CarIcon, bgColor: 'bg-purple-600' }
      ]
    })

    return { travelTiles }
  }
}
</script>

<style lang="postcss"></style>
