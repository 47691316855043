<template>
  <div class="planner-folder relative h-full">
    <PlannerFolderToolbar class="absolute w-full z-20" @share-click="openShareTools" />

    <!-- Folder Header -->
    <div class="pt-12 px-4 pb-4 h-full overflow-y-auto">
      <div class="flex items-center">
        <!-- Folder Image -->
        <button ref="folderImage" type="button" @click="openPopper">
          <div class="h-16 w-16 bg-gray-500 rounded"></div>
        </button>

        <div class="flex flex-col ml-2">
          <div class="flex flex-row items-center">
            <!-- Folder Name -->
            <button type="button" class="text-2xl" @click="openPopper">
              {{ plannerFolder ? plannerFolder.name : 'Click to set folder name' }}
            </button>
            <button type="button" @click="openPopper">
              <Edit2Icon
                size="24"
                class="text-gray-600 dark:text-gray-400 hover:text-gray-800 transition-colors duration-200 p-1"
              />
            </button>
          </div>
        </div>
      </div>

      <!-- Popper Menu -->
      <div
        v-show="popper.isVisible"
        ref="folderEditPopup"
        v-click-outside="closePopperIfReady"
        class="bg-gray-100 border border-gray-400 dark:bg-gray-800 dark:border-gray-600 rounded p-2 w-64"
      >
        <div v-if="plannerFolder">
          <!-- Name -->
          <label class="block">
            <div class="flex flex-row items-center">
              <!-- Folder Name -->
              <span class="flex-grow">Folder Name</span>

              <!-- Close Popper Button -->
              <button type="button" class="-mr-1" @click="closePopperIfReady">
                <XIcon />
              </button>
            </div>

            <!-- Folder Name -->
            <input
              v-model="plannerFolder.name"
              class="form-input block w-full"
              type="text"
              @blur="() => plannerFolder.save()"
            />
          </label>

          <button
            type="button"
            class="text-red-600 px-3 py-2 mt-1 hover:bg-red-300 rounded hover:text-red-900 transition-colors duration-200 w-full dark:hover:bg-gray-800 text-left"
            @click="handleRemoveFolder"
          >
            Delete Folder
          </button>
        </div>
      </div>

      <!-- Itinerary -->
      <div class="mt-3 flex flex-row">
        <div>
          <div class="bg-gray-400 text-black p-2 rounded">Unscheduled</div>
          <PlannerTravelTiles />
          <PlannerSavedItems :planner-saved-items="plannerSavedItems" :itinerary="itinerary" />
        </div>
        <PlannerFolderItinerary
          v-if="itinerary && hasItineraryLoaded"
          :itinerary="itinerary"
          :planner-saved-items="plannerSavedItems"
        />
        <div v-else-if="hasItineraryLoaded" class="bg-gray-200 dark:bg-gray-800 rounded p-6 h-48">
          Loading Itinerary
        </div>
      </div>

      <XModal :is-visible="isShareModalVisible" @close="closeShareTools">
        <PlannerFolderShare
          v-if="isShareModalVisible && itinerary"
          class="mt-3 md:flex md:flex-row shadow-xl md:w-4/5"
          :itinerary="itinerary"
        />
      </XModal>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, inject } from '@vue/composition-api'
import { getPlannerFolder } from '@/use/planner-folders.js'
import { findItineraryForFolder, createItinerary } from '@/use/itineraries'
import { findPlannerSavedItems } from '@/use/planner-saved-items.js'
import { usePlanner } from '@/use/planner.js'
import { usePopper } from '@rovit/popper'

import { XIcon, Edit2Icon } from 'vue-feather-icons'
import PlannerFolderToolbar from '../PlannerFolderToolbar/PlannerFolderToolbar.vue'
import XModal from '@ionomy/x-modal'

import PlannerFolderItinerary from './../PlannerFolderItinerary/PlannerFolderItinerary'
import PlannerTravelTiles from '../PlannerTravelTiles/PlannerTravelTiles.vue'
import PlannerSavedItems from '../PlannerSavedItems/PlannerSavedItems.vue'
import PlannerFolderShare from './../PlannerFolderShare/PlannerFolderShare'

export default {
  name: 'PlannerFolder',
  metaInfo: {
    title: 'Planner Folder'
  },
  components: {
    XIcon,
    Edit2Icon,
    XModal,
    PlannerFolderToolbar,
    PlannerFolderItinerary,
    PlannerTravelTiles,
    PlannerSavedItems,
    PlannerFolderShare
  },
  props: {},
  setup(props, context) {
    const $route = inject('$route')
    const { openShareTools, closeShareTools, backToPlanner } = usePlanner()

    // Planner Folder
    const plannerFolderId = computed(() => $route.value.query.folderId)
    const { plannerFolder } = getPlannerFolder(plannerFolderId)

    // Edit Folder Popper
    const folderImage = ref(null)
    const folderEditPopup = ref(null)
    const popper = usePopper(folderImage, folderEditPopup, {
      placement: 'top-start'
    })
    const isPopperReady = ref(false)
    function openPopper() {
      if (popper.isVisible) {
        closePopperIfReady()
      } else {
        popper.open()
        setTimeout(() => {
          isPopperReady.value = true
        }, 500)
      }
      // Workaround for the popper position
      window.scrollBy(0, 1)
      window.scrollBy(0, -1)
    }
    function closePopperIfReady() {
      if (isPopperReady.value) {
        popper.close()
        isPopperReady.value = false
      }
    }

    // Saved Items
    const { plannerSavedItems } = findPlannerSavedItems({ plannerFolderId })

    // Itinerary
    const { itinerary, hasItineraryLoaded } = findItineraryForFolder({
      plannerFolderId,
      local: true
    })

    // Create initial Itinerary or open an existing itinerary
    watch(
      () => plannerFolder.value,
      () => {
        if (plannerFolder.value && !plannerFolder.value.itinerary) {
          createItinerary({
            title: plannerFolder.value.name,
            plannerFolderId
          })
        }
      }
    )

    // Share
    const isShareModalVisible = computed(() => {
      return !!$route.value.query.share
    })

    function handleRemoveFolder() {
      plannerFolder.value && plannerFolder.value.remove()
      backToPlanner()
    }

    return {
      itinerary,
      hasItineraryLoaded,
      // Edit Folder Popper
      folderImage,
      folderEditPopup,
      popper,
      openPopper,
      closePopperIfReady,
      // Saved items
      plannerSavedItems,
      plannerFolder,
      handleRemoveFolder,
      // Share
      isShareModalVisible,
      openShareTools,
      closeShareTools
    }
  }
}
</script>

<style lang="postcss">
.social-share-item {
  @apply w-32 justify-center items-center inline-flex mb-1 rounded shadow-sm;
}
</style>
