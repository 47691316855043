<template>
  <MglMap
    id="mapbox-main-map"
    :access-token="accessToken"
    :map-style="mapStyle"
    :zoom.sync="zoom"
    :center.sync="center"
    class="h-full w-full"
    :preserve-drawing-buffer="true"
    @load="handleMapLoad"
    @click="handleMapClick"
  >
    <MglGeolocateControl position="top-right" />
    <MglNavigationControl position="top-right" />

    <MglMarker
      v-if="markedFeatureCoordinates && markedFeatureCoordinates.length"
      :coordinates="markedFeatureCoordinates"
      color="#C53030"
    />

    <!-- Unclustured Features -->
    <MglGeojsonLayer
      :source-id="featureSet.sourceName"
      :source="featureSet.source.value"
      :layer-id="featureSet.unclustered.id"
      :layer="featureSet.unclustered"
      :clear-source="false"
      @click="handleUnclusteredLayerClick"
      @mouseenter="handleLayerMouseEnter"
      @mouseleave="handleLayerMouseLeave"
    />

    <!-- Clusters -->
    <MglGeojsonLayer
      :source-id="featureSet.sourceName"
      :source="featureSet.source.value"
      :layer-id="featureSet.clustered.id"
      :layer="featureSet.clustered"
      :clear-source="false"
      @click="handleClusterLayerClick"
    />

    <!-- Cluster Count -->
    <MglGeojsonLayer
      :source-id="featureSet.sourceName"
      :source="featureSet.source.value"
      :layer-id="featureSet.counts.id"
      :layer="featureSet.counts"
      :clear-source="false"
    />
  </MglMap>
</template>

<script>
import { ref, computed, watch, inject } from '@vue/composition-api'
import { makeClusteredLayerSet, getCoordsFromMapboxEvent } from './map-utils.js'
import { get } from 'lodash'
import { models } from 'feathers-vuex'

import Mapbox from 'mapbox-gl'
import {
  MglMap,
  MglMarker,
  MglGeojsonLayer,
  MglNavigationControl,
  MglGeolocateControl
} from 'vue-mapbox'

export default {
  name: 'MapMain',
  components: {
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    MglNavigationControl,
    MglGeolocateControl
  },
  props: {
    accessToken: { type: String, required: true },
    env: {
      validator: val => typeof val === 'object',
      required: true
    },
    features: { type: Array, required: true },
    markedFeature: { validator: val => typeof val === 'object', default: null }
  },
  setup(props, context) {
    const $store = inject('$store')

    const markedFeatureCoordinates = computed(() => {
      return get(props.markedFeature, 'location.coordinates') || null
    })

    const mapStyle = 'mapbox://styles/rovit/ckksomc2a02gi17qhmgyeavs8' // Default to Rovit Streets Map Style
    const location = computed(() => $store.getters['mapZoom/currentLocation'])
    const isMapReady = computed(() => $store.state.mapMain.isMapReady)
    const zoom = computed({
      get: () => {
        const getter = $store.getters['mapZoom/currentLocation']
        return getter.zoom
      },
      set: val => $store.commit('mapZoom/setZoom', val)
    })
    const center = computed({
      get: () => {
        const getter = $store.getters['mapZoom/currentLocation']
        return getter.center
      },
      set: val => $store.commit('mapZoom/setCenter', val)
    })

    const featureSet = computed(() => {
      return makeClusteredLayerSet({
        sourceName: 'features',
        features: props.features
      })
    })
    function handleMapLoad(data) {
      $store.commit('mapZoom/setMap', data)
      $store.commit('mapMain/setMap', data)
    }

    /**
     * Clicks are handed out to App.vue (the original map location) and are then passed
     * through the map-state's `handleMapClicked` function.
     */
    function handleMapClick(data) {
      context.emit('click', data)
    }

    async function handleUnclusteredLayerClick({ mapboxEvent, layerId, map, component }) {
      const coordinates = getCoordsFromMapboxEvent(mapboxEvent)
      const feature = mapboxEvent.features[0]
      const item = props.features.find(i => feature.properties._id === i._id)

      // Highlights the clicked map feature
      $store.dispatch('mapMain/setActiveMapFeature', feature)

      if (item.link) {
        $store.dispatch('miniInfobox/openWithLink', item.link)
      } else {
        $store.dispatch('miniInfobox/openWithOptions', { sceneId: item._id })
      }
    }

    function getClusterChildren(clusterId) {
      return new Promise((resolve, reject) => {
        map.getSource('features').getClusterChildren(clusterId, (error, features) => {
          if (error) {
            reject(error)
          }
          resolve(features)
        })
      })
    }

    // Cluster Handling
    async function handleClusterLayerClick({ mapboxEvent, layerId, map, component }) {
      var features = map.queryRenderedFeatures(mapboxEvent.point, {
        layers: ['features-clustered']
      })
      const cluster = features[0]
      const { cluster_id, point_count } = cluster.properties
      map.getSource('features').getClusterLeaves(cluster_id, point_count, 0, (error, children) => {
        $store.dispatch('mapClusterIndex/open', { cluster, children })
      })
    }

    /**
     * layer mouseenter, enable hover style
     */
    function handleLayerMouseEnter({ mapboxEvent, layerId, map, component }) {
      map.getCanvas().style.cursor = 'pointer'
      const feature = get(mapboxEvent, 'features.0')
      $store.commit('mapMain/setHoveredFeature', feature)
    }
    /**
     * layer mouseleave, remove hover style
     */
    function handleLayerMouseLeave({ mapboxEvent, layerId, map, component }) {
      map.getCanvas().style.cursor = ''
      $store.commit('mapMain/setHoveredFeature', null)
    }

    return {
      markedFeatureCoordinates,
      mapStyle,
      zoom,
      center,
      featureSet,
      location,
      handleMapLoad,
      handleMapClick,
      handleUnclusteredLayerClick,
      handleClusterLayerClick,
      handleLayerMouseEnter,
      handleLayerMouseLeave
    }
  }
}
</script>

<style lang="postcss">
.mapbox-map {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
