<template>
  <div class="flex overflow-x-auto overflow-y-hidden">
    <div
      v-for="container in itinerary.containers"
      :key="container.id"
      class="w-56 mr-2 list-group-item"
      style="min-width: 248px"
    >
      <PlannerFolderItinerarySection :container="container" @item-click="handleItemClick" />
    </div>
  </div>
</template>

<script>
import { inject } from '@vue/composition-api'

import PlannerFolderItinerarySection from '../PlannerFolderItinerarySection/PlannerFolderItinerarySection.vue'

export default {
  name: 'Itinerary',
  components: {
    PlannerFolderItinerarySection
  },
  props: {
    itinerary: {
      validator: val => typeof val === 'object',
      required: true
    }
  },
  setup(props, context) {
    const $store = inject('$store')
    function handleItemClick(data) {
      const { event, item, index, container } = data
      if (item.link) {
        $store.dispatch('miniInfobox/openWithLink', item.link)
      }
    }

    return { handleItemClick }
  }
}
</script>

<style lang="postcss"></style>
