import feathersClient, {
  makeServicePlugin,
  models,
  BaseModel
} from '../../feathers-client'
import { makeModel } from '@rovit/models/Config'

const servicePath = 'config'
const servicePlugin = makeServicePlugin({
  Model: makeModel({ BaseModel, models }),
  service: feathersClient.service(servicePath),
  servicePath,
  extend: ({ store, defaults }) => {
    return {
      state: {
        isAttempted: false,
        isLoaded: false
      },
      getters: {
        config(state, getters) {
          return getters.find({ query: {} }).data.reduce((acc, current) => {
            acc[current.name] = current
            return acc
          }, {})
        }
      },
      mutations: {
        setAttempted(state) {
          state.isAttempted = true
        },
        setLoaded(state) {
          state.isLoaded = true
        }
      }
    }
  }
})

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})
export default servicePlugin
