<template>
  <div class="h-full overflow-hidden">
    <!-- <ImagePanzoom
      v-if="category"
      :src="category.assetUrl"
      :hotspots="category.hotspots"
    /> -->
    <ImageHotspots
      v-if="category"
      :src="category.assetUrl"
      class="flex flex-row items-center justify-center h-full"
      :hotspots="category.hotspots"
      @hotspot-click="handleHotspotClick"
    />

    <div v-else>Loading Category</div>
  </div>
</template>

<script>
import { fetchRovitEnv } from '@/use/environments.js'
import { models } from 'feathers-vuex'

import ImageHotspots from './ImageHotspots.vue'
import ImagePanzoom from './ImagePanzoom.vue'
import { ref } from '@vue/composition-api'

/* eslint-disable vue/no-unused-components */
export default {
  name: 'HotspotTest',
  components: {
    ImageHotspots,
    ImagePanzoom
  },
  setup(props, context) {
    const { rovit } = fetchRovitEnv()
    const category = ref(null)

    models.api.Category.get('5f29fb838f19bc3658954725').then(result => {
      category.value = result
    })

    function handleHotspotClick(data) {
      console.log('handleHotspotClick in HotspotTest', data)
    }

    return { rovit, category, handleHotspotClick }
  }
}
</script>

<style lang="postcss">
.image-hotspots-zoom-container {
  @apply max-h-full;
}
</style>
