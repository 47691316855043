import { useFind, useGet, models } from 'feathers-vuex'
import { ref, computed, watch } from '@vue/composition-api'
import { unwrapRef, unwrapRefs, watchRouteParam } from './utils'
import { getEnvBySlugFromStore } from './environments.js'

/**
 * Create an infobox for the provided service.
 */
export async function createInfobox({ service, org }) {
  const { Scene } = models.api
  const _service = unwrapRef(service)
  const _org = unwrapRef(org)

  const infobox = await new Scene({
    name: 'New Infobox',
    infoboxName: 'New Infobox',
    isInfobox: true,
    serviceId: _service._id,
    featureType: '',
    parentSceneId: null,
    sceneModulesMeta: [],
    orgs: [
      {
        orgId: _org._id,
        orgName: _org.name,
        accessType: 'owner',
        nameOfOwner: _org.nameOfOwner
      }
    ]
  }).save()
  return { infobox }
}

/**
 * Create an scene for the provided infobox.
 */
export async function createSceneForInfobox({ infobox }) {
  const { Scene } = models.api
  const _infobox = unwrapRef(infobox)

  const scene = await new Scene({
    name: 'New Scene',
    isInfobox: false,
    serviceId: _infobox.serviceId,
    featureType: '',
    parentSceneId: _infobox._id,
    sceneModulesMeta: [],
    orgs: _infobox.orgs
  }).save()
  return { scene }
}

/**
 * Get a scene by id
 */
export function getScene({ id, local = false }) {
  const { Scene } = models.api
  const params = computed(() => {
    return {
      $populateParams: { name: 'app' }
    }
  })
  const queryWhen = computed(() => {
    return true
    // return !id || !Scene.getFromStore(id)
  })
  const { item: scene, hasLoaded: hasSceneLoaded } = useGet({
    model: Scene,
    id,
    params,
    queryWhen,
    local
  })

  return { scene, hasSceneLoaded }
}

/**
 * Find infoboxes for provided service
 */
export function findInfoboxesForService({ service }) {
  const { Scene } = models.api

  const params = computed(() => {
    const _service = unwrapRef(service)

    if (!_service) {
      return null
    }
    return {
      query: {
        serviceId: _service._id,
        isInfobox: true
      },
      debounce: 50,
      $populateParams: {
        name: 'infoboxBuilder'
      }
    }
  })
  const queryWhen = computed(() => {
    return true
  })
  const { items: infoboxes } = useFind({ model: Scene, params, queryWhen })
  return { infoboxes }
}

const queriedByCategoryId = {}
export function findScenesInCategory(options) {
  const { Scene } = models.api

  const params = computed(() => {
    const { env, category } = unwrapRefs(options)
    if (!env || !category) {
      return null
    }
    const query = {
      'envsMeta.envId': env._id,
      'categoriesMeta.categoryId': category._id,
      $limit: 250
    }
    return { query, $populateParams: { name: 'withService' } }
  })
  const queryWhen = computed(() => {
    const { category } = unwrapRefs(options)
    return !queriedByCategoryId[category._id]
  })
  const { items: scenes, haveBeenRequested } = useFind({
    model: Scene,
    params,
    queryWhen
  })
  watch(
    () => haveBeenRequested.value,
    val => {
      const { category } = unwrapRefs(options)
      if (val && category) {
        queriedByCategoryId[category._id] = new Date()
      }
    },
    { immediate: true }
  )
  return { scenes }
}

/**
 * Find child scenes that belong to the provided infobox.
 */
export function findScenesForInfobox({ infobox, local }) {
  const { Scene } = models.api

  const params = computed(() => {
    const _infobox = unwrapRef(infobox)

    if (!_infobox) {
      return null
    }
    return {
      query: {
        parentSceneId: _infobox._id
      },
      debounce: 50,
      $populateParams: {
        name: 'infoboxBuilder'
      }
    }
  })
  const { items: scenes } = useFind({ model: Scene, params, local })
  return { scenes }
}

/**
 * Infobox Selection (admin area)
 */
export function infoboxSelection(props, context) {
  const selectedInfobox = ref(null)
  function selectInfobox(scene) {
    if (selectedInfobox.value === scene) {
      selectedInfobox.value = null
    } else {
      selectedInfobox.value = scene
    }
  }
  function openInfobox(scene) {
    context.root.$router.push(
      {
        name: 'InfoboxEditor',
        params: {
          serviceId: scene.serviceId,
          infoboxId: scene.parentSceneId || scene._id
        }
      },
      () => {}
    )
  }
  return {
    selectedInfobox,
    selectInfobox,
    openInfobox
  }
}

/**
 * Scene Selection (what is different from above? wtf was I thinking)
 */
export function sceneSelection(props, context) {
  const selectedScene = ref(null)
  function selectScene(scene) {
    if (selectedScene.value === scene) {
      selectedScene.value = null
    } else {
      selectedScene.value = scene
    }
  }
  function openScene(scene) {
    context.root.$router.push(
      {
        name: 'InfoboxEditor',
        params: {
          serviceId: scene.serviceId,
          infoboxId: scene.parentSceneId || scene._id
        }
      },
      () => {}
    )
  }
  return {
    selectedScene,
    selectScene,
    openScene
  }
}

/**
 * (Discover) Open the route to a scene
 */
export async function openLink({ context, link }) {
  const { Environment } = models.api
  const sceneId = link.sceneId ? link.sceneId : link.infoboxId
  let env = unwrapRef(Environment.getFromStore(link.envId))

  if (!env) {
    let slug = context.root.$route.params.env
    let val = getEnvBySlugFromStore({ slug })
    if (val.env) {
      env = unwrapRef(val.env)
    }
  }

  if (link.envId && !env) {
    console.error(
      'no environment record was found. This probably should have been loaded by the MiniInfobox'
    )
  }

  context.root.$router.push({
    name: 'Infobox',
    params: { env: (env && env.slug) || 'rovit', sceneId }
  })
}

/**
 * Watches the route for a `params.serviceId` and stores it in localStorage.
 */
const storageKey = 'current-scene-id'
export function routeSceneId(context) {
  const { sceneId } = watchRouteParam(context, {
    name: 'sceneId',
    storageKey
  })
  return { sceneId }
}
/**
 * Useful to restore context when switching back to discover from one of the other
 * tabs in the primary navigation.
 */
export function getLatestCurrentSceneId() {
  return window.localStorage.getItem(storageKey)
}

/**
 * Find scenes for env
 */
const queriedByEnvId = {}
export function findScenesForEnv(options) {
  const { Scene } = models.api

  const params = computed(() => {
    const {
      env,
      sceneIds,
      search,
      category,
      minSearchLength = 2,
      paginate = false,
      populate = 'withService'
    } = unwrapRefs(options)
    if (!env) {
      return null
    }
    const query = {
      'envsMeta.envId': env._id,
      $limit: 250
    }
    if (sceneIds && sceneIds.length) {
      query._id = { $in: sceneIds }
    }
    if (search && search.length >= minSearchLength) {
      Object.assign(query, {
        name: { $regex: search, $options: 'igm' }
      })
    }
    if (category) {
      Object.assign(query, { 'categoriesMeta.categoryId': category._id })
    }

    return { query, paginate, $populateParams: { name: populate } }
  })
  const queryWhen =
    options.queryWhen ||
    computed(() => {
      const { env } = unwrapRefs(options)
      return !queriedByEnvId[env._id]
    })
  const { items: scenes, haveBeenRequested, isPending } = useFind({
    model: Scene,
    params,
    queryWhen
  })
  watch(
    () => haveBeenRequested.value,
    val => {
      const { env } = unwrapRefs(options)
      if (val && env) {
        queriedByEnvId[env._id] = new Date()
      }
    },
    { immediate: true }
  )
  return { scenes, isPending }
}

/**
 * Find paginated scenes for env
 */
export function findPaginatedScenesForEnv(options) {
  const { Scene } = models.api

  const params = computed(() => {
    const {
      env,
      sceneIds,
      search,
      category,
      pagination,
      minSearchLength = 2,
      populate = 'withService'
    } = unwrapRefs(options)

    if (!env) {
      return null
    }
    const query = {
      'envsMeta.envId': env._id,
      $limit: 250
    }
    if (sceneIds && sceneIds.length) {
      query._id = { $in: sceneIds }
    }
    if (search && search.length >= minSearchLength) {
      Object.assign(query, {
        name: { $regex: search, $options: 'igm' }
      })
    }
    if (category) {
      Object.assign(query, { 'categoriesMeta.categoryId': category._id })
    }
    if (pagination) {
      Object.assign(query, pagination)
    }

    return { query, paginate: !!pagination, $populateParams: { name: populate }, debounce: 50 }
  })
  const queryWhen =
    options.queryWhen ||
    computed(() => {
      const { env } = unwrapRefs(options)
      return !queriedByEnvId[env._id]
    })
  const { items: scenes, isPending, latestQuery, paginationData } = useFind({
    model: Scene,
    params,
    queryWhen
  })
  return { scenes, isPending, latestQuery, paginationData }
}
