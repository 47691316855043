<template>
  <FeatureLayout :env="env" :features="combinedFeatures">
    <template #modals>
      <div>
        <!-- MiniInfobox Modal -->
        <XModal v-model="isMiniInfoboxOpen" no-mask :style="{ zIndex: 9999 }">
          <template #default="{ close }">
            <MiniInfobox
              v-if="isMiniInfoboxOpen"
              :link-to="targetLink"
              @open-link="handleOpenLink"
              @close="close"
            />
          </template>
        </XModal>

        <!-- MapClusterIndex Modal -->
        <XModal v-model="isMapClusterIndexOpen" no-mask>
          <template #default="{ close }">
            <MapClusterIndex v-if="isMapClusterIndexOpen" @close="close" />
          </template>
        </XModal>
      </div>
    </template>

    <!-- Content -->
    <template #content>
      <div v-if="env" class="sm:px-2 md:px-6">
        <router-view :env="env" :scene="scene" :features="combinedFeatures"></router-view>
      </div>
      <EnvNotFound v-else-if="!doesEnvExist" />
      <LoadingDock v-else />
      <PlannerContainer />
    </template>
  </FeatureLayout>
</template>

<script>
import { models } from 'feathers-vuex'
import { computed, ref, watch, toRefs, inject, provide } from '@vue/composition-api'
import { routeSceneId, getScene, openLink } from '@/use/scenes.js'
import { useHistory, history, currentRoute, mostRecentDiscoverRoute } from '@/use/history.js'
import { syncToStore } from '@/use/utils.js'

import FeatureLayout from '../layouts/FeatureLayout'
import LoadingDock from '../components/LoadingDock/LoadingDock'
import EnvNotFound from '../components/EnvNotFound/EnvNotFound'
import MiniInfobox from '../components/MiniInfobox/MiniInfobox.vue'
import MapClusterIndex from '../components/MapClusterIndex/MapClusterIndex.vue'
import XModal from '@ionomy/x-modal'
import PlannerContainer from '../components/Planner/PlannerContainer.vue'

export default {
  name: 'AppView',
  metaInfo: {
    titleTemplate: '%s | Rovit'
  },
  components: {
    FeatureLayout,
    LoadingDock,
    EnvNotFound,
    XModal,
    MiniInfobox,
    MapClusterIndex,
    PlannerContainer
  },
  setup(props, context) {
    const $store = inject('$store')
    const $route = inject('$route')

    const env = computed(() => $store.getters['environments/currentEnv'])
    const doesEnvExist = computed(() => $store.state.environments.doesEnvExist)
    const isEnvLoading = computed(() => $store.state.environments.isEnvLoading)

    const isMiniInfoboxOpen = computed({
      get: () => $store.state.miniInfobox.isOpen,
      set: val => $store.commit('miniInfobox/setIsOpen', false)
    })
    const targetLink = computed(() => $store.state.miniInfobox.targetLink)

    const isMapClusterIndexOpen = computed({
      get: () => {
        return $store.state.mapClusterIndex.isOpen
      },
      set: val => $store.commit('mapClusterIndex/close')
    })

    const { sceneId } = routeSceneId(context)
    const { scene } = getScene({ id: sceneId })

    const currentCategory = computed(() => $store.getters['categories/currentCategory'])

    const combinedFeatures = computed(() => {
      return $store.getters['mapMain/combinedFeatures']
    })

    // Active Map Feature
    const activeFeature = computed({
      get: () => $store.state.mapMain.activeFeature,
      set: val => $store.dispatch('mapMain/setActiveMapFeature', val)
    })

    const featureItem = computed(() => $store.state.featureLayout.featureItem)
    watch(
      () => [scene.value, currentCategory.value],
      ([scene, category], oldVals) => {
        // If the scene and category are cleared, clear any existing featureItem.
        if (!scene && !category && featureItem.value) {
          $store.commit('featureLayout/setFeatureItem', { item: null })
        } else if (!scene && !category) {
          return
        } else {
          let areSameFeature = false
          if (oldVals && oldVals.length) {
            let [oldScene, oldCategory] = oldVals
            if (scene) {
              areSameFeature = scene === oldScene
            } else if (category) {
              areSameFeature = category === oldCategory
            }
          }
          if (!areSameFeature) {
            $store.commit('featureLayout/setFeatureItem', { item: scene || category })
          }
        }
      }
      // { immediate: true }
    )

    // When route path is '/discover/:env'
    function openEnvironmentHome() {
      const isFirstTimeOpeningEnv = true
      if (isFirstTimeOpeningEnv) {
        // 1. Setup the env's featureType, with same options as a scene.
        // 2. Setup the map at the environment's location, even if it's not the current featureType.
      }
    }

    useHistory(context, { env, scene })

    // Feature
    const currentFeatureType = ref('map')

    function handleOpenLink({ event, link }) {
      // Route to the current scene
      openLink({ context, link })
      $store.commit('miniInfobox/setIsOpen', false)
      $store.commit('mapClusterIndex/close')
    }

    function handleMapClick(data) {
      handleMapClicked(data)
    }

    return {
      env,
      doesEnvExist,
      isEnvLoading,
      sceneId,
      scene,

      // History
      history,
      currentRoute,
      mostRecentDiscoverRoute,

      // Feature
      currentFeatureType,
      handleMapClick,
      combinedFeatures,

      // Mini Infobox
      isMiniInfoboxOpen,
      targetLink,
      handleOpenLink,

      // Cluster Index Modal
      isMapClusterIndexOpen
    }
  }
}
</script>

<style>
/* purgecss start ignore */

.dock {
  overflow: hidden;
}

.toasted-action-success {
  background: #009100 !important;
}
.toasted-action-error {
  background: #e53e3e !important;
}
/* Hide pannellum loading container */
.pnlm-load-box {
  display: none !important;
}
/* purgecss end ignore */
</style>
