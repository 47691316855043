import { setVal, unwrapRefs } from '../use/utils.js'
import { models } from 'feathers-vuex'

export default {
  namespaced: true,
  state: () => ({
    isOpen: false,
    targetLink: null,
  }),
  mutations: {
    ...setVal('setIsOpen'),
    ...setVal('setTargetLink'),
  },
  actions: {
    /**
     * A link consists of at least some of the the following attributes:
     *  - envId,
     *  - envName,
     *  - shouldSwitchEnvs,
     *  - infoboxId,
     *  - infoboxName,
     *  - sceneId [required]
     *  - sceneName,
     *  - isInfobox,
     *  - serviceId,
     *  - serviceName
     */
    openWithLink({ commit }, targetLink) {
      commit('setTargetLink', targetLink)
      commit('setIsOpen', true)
    },
    async openWithOptions({ commit, rootGetters }, options) {
      const { scene, sceneId, env, infobox, service } = unwrapRefs(options)
      const targetEnv = env || rootGetters['environments/currentEnv']

      // Get target scene from provided scene, store, or API
      let targetScene = scene
      if (!targetScene) {
        try {
          targetScene = models.api.Scene.getFromStore(sceneId)
        } catch (error) {}
      }
      if (!targetScene) {
        targetScene = await models.api.Scene.get(sceneId)
      }

      const link = {
        envId: targetEnv._id,
        envName: targetEnv.name,
        serviceId: service ? service._id : null,
        serviceName: service ? service.name : '',
        infoboxId: null,
        infoboxName: null,
        isInfobox: targetScene.isInfobox,
        shouldSwitchEnvs: true,
        sceneId: targetScene._id,
        sceneName: targetScene.name,
      }
      commit('setTargetLink', link)
      commit('setIsOpen', true)
    },
  },
  getters: {},
}
