import { isRef, computed, watch } from '@vue/composition-api'
import store from '../store/store.js'

export function unwrapRef(obj) {
  return isRef(obj) ? obj.value : obj
}

export function unwrapRefs(obj) {
  return Object.keys(obj).reduce((props, key) => {
    props[key] = unwrapRef(obj[key])
    return props
  }, {})
}

export function checkModel(Model) {
  if (!Model) {
    throw new Error('the Model class must be provided in the options.')
  }
}

export function watchRouteParam(context, { name, storageKey }) {
  const param = computed(() => context.root.$route.params[name])

  watch(
    () => param.value,
    (val) => window.localStorage.setItem(storageKey, val)
  )
  return { [name]: param }
}

/**
 * Quickly create a mutation for setting an individual state value.
 * @param mutationName the mutation name
 * ...setVal('setCurrentEnv')
 */
export function setVal(mutationName) {
  const name = mutationName.replace('set', '')
  const first = name[0]
  const attr = `${first.toLowerCase()}${name.slice(1)}`
  return {
    [mutationName](state, payload) {
      state[attr] = payload != null && payload.value ? payload.value : payload
    },
  }
}

/**
 * Synchronize a value with store state by calling the mutationName with the watcher payload.
 * syncToStore(() => env.value, 'environments/setCurrentEnv')
 * @param options
 *  @attr watch {Function} the expression to watch.
 *  @attr value {Computed} value to use to override the watched value. (optional)
 *  @attr commit {String} the full pathname of the mutation.
 */
export function syncToStore(options) {
  watch(options.watch, (val) => {
    const { value, commit } = options

    let valToUse
    // If value is provided, it should be used, otherwise use the watcher val
    if (value != null) {
      valToUse = value.value || value
    } else {
      valToUse = val
    }
    store.commit(commit, valToUse)
  })
}
