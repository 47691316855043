import { models, useFind, useGet } from 'feathers-vuex'
import { ref, computed } from '@vue/composition-api'
import { randomString } from '@/use/random-string.js'

export function findItineraryForFolder({ plannerFolderId, local = false }) {
  const { Itinerary } = models.api
  const params = computed(() => {
    const query = {
      plannerFolderId: plannerFolderId.value,
      $limit: 1
    }
    return { query, $populateParams: { name: '' } }
  })
  const queryWhen = computed(() => {
    return local === false
  })
  const { items: itineraries, haveLoaded } = useFind({
    model: Itinerary,
    params,
    queryWhen,
    local
  })
  const itinerary = computed(() => {
    return itineraries.value[0] || null
  })
  return { itinerary, hasItineraryLoaded: haveLoaded }
}

export async function createItinerary({ plannerFolderId }) {
  const { Itinerary } = models.api

  const itinerary = await new Itinerary({
    plannerFolderId: plannerFolderId.value
  }).save()
  return { itinerary }
}

export async function addContainer({ itinerary }) {
  const clone = itinerary.clone()
  clone.containers.push({
    id: randomString(8),
    name: '',
    items: []
  })
  return await clone.commit().save()
}

/**
 * Accepts an `item` which is a PlannerSavedItem instance and converts it to the proper format
 * for an itinerary container item.
 */
export function prepareContainerItem({ item }) {
  return {
    id: randomString(8),
    name: item.name,
    url: item.url || '',
    bgColor: '',
    plannerSavedItemId: item._id || null,
    type: 'scene',
    iconName: '',
    note: '',
    assetId: null,
    assetUrl: '',
    assetFit: 'cover',
    link: {
      sceneId: item.sceneId,
      sceneName: item.sceneName,
      isInfobox: true,
      shouldSwitchEnvs: true,
      serviceId: item.serviceId,
      envId: item.envId,
      envName: item.envName
    },
    isBooked: false,
    date: null
  }
}

export function prepareTravelItem({ item }) {
  return {
    id: randomString(8),
    name: item.travelType,
    url: item.url || '',
    bgColor: item.bgColor,
    plannerSavedItemId: null,
    type: 'travel',
    iconName: item.icon.name,
    note: '',
    assetId: null,
    assetUrl: '',
    assetFit: 'cover',
    link: null,
    isBooked: false,
    date: null
  }
}

export function removeItemFromContainer({ item, container, itinerary }) {
  container.items = container.items.filter(i => i !== item)
  itinerary.save()
}
export function removeContainerFromItinerary({ container, itinerary }) {
  itinerary.containers = itinerary.containers.filter(c => c !== container)
  itinerary.save()
}

export function getItinerary({ id, local = false }) {
  const { Itinerary } = models.api
  const params = computed(() => {
    return { $populateParams: { name: '' } }
  })
  const queryWhen = computed(() => {
    const existing = Itinerary.getFromStore(id)
    return !existing
  })
  const {
    item: itinerary,
    hasLoaded: hasItineraryLoaded,
    isItineraryLoading
  } = useGet({
    model: Itinerary,
    id,
    params,
    queryWhen,
    local
  })

  return { itinerary, hasItineraryLoaded, isItineraryLoading }
}
