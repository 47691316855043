import { computed } from '@vue/composition-api'
import { get } from 'lodash'
import { point, midpoint } from '@turf/turf'
import { setVal } from '../use/utils.js'
import { areCoordsValid } from '@rovit/map'
import fastCopy from 'fast-copy'

const wait = (time = 0) => new Promise(resolve => setTimeout(resolve, time))

// Store a reference to the main mapbox instance. Set by setMap mutation.
const vueMapbox = {}

const defaultCenter = [-112.7673352712477, 37.116325303409965]
const defaultZoom = 8.211764492345896

/**
 * The mapZoom module is responsible for the auto-zooming and panning of the map.
 */
export default $store => {
  const currentEnv = computed(() => $store.getters['environments/currentEnv'])

  $store.registerModule('mapZoom', {
    namespaced: true,
    state: () => ({
      hasZoomedInitially: false,
      cachedFeatureItem: null,
      envsBySlug: {
        rovit: {
          bbox: null,
          zoom: null,
          center: null,
          hasZoomedToBoundsOnce: false
        }
      }
    }),
    mutations: {
      ...setVal('setHasZoomedInitially'),
      ...setVal('setCachedFeatureItem'),
      redraw() {
        vueMapbox.map && vueMapbox.map.resize()
      },

      // Store a reference to the map
      setMap(state, data) {
        Object.assign(vueMapbox, data)
      },
      setBbox(state, env) {
        const calculatedCenter =
          env.bbox && env.bbox[0]
            ? midpoint(point(env.bbox[0]), point(env.bbox[1])).geometry.coordinates
            : defaultCenter.slice()

        state.envsBySlug[env.slug] = {
          bbox: env.bbox,
          zoom: defaultZoom,
          center: calculatedCenter,
          hasZoomedToBoundsOnce: false
        }
      },
      setCenter(state, center) {
        const slug = get(currentEnv.value, 'slug')
        if (slug) {
          state.envsBySlug[slug].center = center
        }
      },
      setZoom(state, zoom) {
        const slug = get(currentEnv.value, 'slug')
        if (slug) {
          state.envsBySlug[slug].zoom = zoom
        }
      }
    },
    actions: {
      async zoom({ state, commit, dispatch, getters, rootState }, { route, oldRoute }) {
        const { currentLocation: location } = getters
        const { featureItem } = rootState.featureLayout
        const { cachedFeatureItem } = state
        let time = 0

        if (
          // (route.name !== oldRoute.name && cachedFeatureItem) ||
          (featureItem && !cachedFeatureItem) ||
          (cachedFeatureItem && !featureItem)
        ) {
          time = 1200
        }

        if (!vueMapbox.map) {
          return
        }

        await wait(time)
        commit('redraw')

        if (route.name === 'Discover') {
          dispatch('fitBounds', location.bbox)
        } else if (route.name === 'Infobox') {
          dispatch('zoomToMarkedFeature')
        }
        if (!state.hasZoomedInitially) {
          commit('setHasZoomedInitially', true)
        }
        return
      },
      async zoomToMarkedFeature({ state }, payload = { mapMethod: '' }) {
        const method = payload.mapMethod || state.hasZoomedInitially ? 'easeTo' : 'jumpTo'
        const duration = method === 'jumpTo' ? 0 : state.hasZoomedInitially ? 3000 : 0

        vueMapbox.map[method]({
          center: get($store.state.mapMain.markedFeature, 'location.coordinates') || null,
          zoom: 15,
          duration
        })
      },
      async pan({ state, commit }, { center, forMiniInfobox = false }) {
        if (!vueMapbox.map || !areCoordsValid(center)) {
          return
        }
        const offset = forMiniInfobox ? [0, -160] : [0, -80]
        // Use a copy to prevent editing the original object.
        center = center.slice()

        vueMapbox.map.easeTo({ center, duration: 3000, offset })
      },
      async cleanUpCachedFeatureItem({ state, rootState, commit }) {
        const { cachedFeatureItem } = state
        const { featureItem } = rootState.featureLayout

        if (!featureItem && cachedFeatureItem) {
          commit('setCachedFeatureItem', null)
        }
      },
      async fitBounds({ state }, bbox) {
        vueMapbox.map.fitBounds(bbox, {
          duration: state.hasZoomedInitially ? 3000 : 3000
        })
      }
    },
    getters: {
      currentLocation: (state, getters, rootState, rootGetters) => {
        const env = rootGetters['environments/currentEnv']
        const defaultLocation = {
          bbox: null,
          center: null,
          zoom: null
        }
        if (env && env.slug && state.envsBySlug[env.slug]) {
          return state.envsBySlug[env.slug]
        } else {
          return defaultLocation
        }
      }
    }
  })

  // Update the bbox for the env when a new currentEnv is set.
  $store.watch(
    () => $store.getters['environments/currentEnv'],
    env => {
      if (env) {
        $store.commit('mapZoom/setBbox', env)
      }
    }
  )

  // When the featureItem changes, cache it.
  $store.watch(
    () => $store.state.featureLayout.featureItem,
    featureItem => {
      if (featureItem) {
        $store.commit('mapZoom/setCachedFeatureItem', featureItem)
      }
    }
  )

  /**
   * When the route changes, zoom the map.
   * Conditional logic is handled in the mapZoom/zoom action.
   */
  $store.watch(
    () => [
      $store.state.route,
      $store.state.mapMain.isMapReady,
      $store.getters['environments/currentEnv']
    ],
    ([route, isMapReady, currentEnv], oldVals) => {
      let oldRoute = get(oldVals, '0') || {}
      if (isMapReady) {
        setTimeout(async () => {
          await $store.dispatch('mapZoom/zoom', { route, oldRoute })
          await $store.dispatch('mapZoom/cleanUpCachedFeatureItem')

          // Force the map to redraw the circle markers
          const item = fastCopy($store.getters['mapMain/combinedFeatures'][0])
          const id = parseInt((item._id || item.id).slice(14), 16)
          const source = 'features'
          item._properties = {
            id,
            _id: item._id || item.id,
            mapboxId: id,
            source
          }
          $store.commit('mapMain/setHoveredFeature', item._properties)
        }, 0)
      }
    }
  )
}
