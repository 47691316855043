<template>
  <Dock class="shadow-lg">
    <div
      class="text-center mt-2 flex flex-col items-center justify-center"
      style="min-height: 200px;"
    >
      <LeapFrog class="mr-5" />
      Loading Current Environment
    </div>
  </Dock>
</template>

<script>
import { Dock } from '@rovit/dock'
import { LeapFrog } from '@rovit/loading'

export default {
  name: 'LoadingDock',
  components: {
    Dock,
    LeapFrog
  },
  setup(props, context) {
    return {}
  }
}
</script>
