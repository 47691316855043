<template>
  <div class="planner-itinerary relative h-full overflow-hidden">
    <div v-if="itinerary" class="h-full">
      <PlannerFolderToolbar class="absolute w-full z-20" @share-click="openShareTools" />
      <div class="pt-12 px-4 pb-4 h-full overflow-y-auto">
        <ItineraryPreview :itinerary="itinerary" />
      </div>
    </div>
    <div v-else>Loading</div>

    <XModal :is-visible="isShareModalVisible" @close="closeShareTools">
      <ItineraryPublicSettings
        v-if="isShareModalVisible && !!itinerary"
        :itinerary="itinerary"
        :is-owner="isItineraryOwner"
        class="mt-3 shadow-xl md:w-2/5 p-3 bg-white rounded-lg dark:bg-gray-800"
      />
    </XModal>
  </div>
</template>

<script>
import { ref, computed, watch, inject } from '@vue/composition-api'
import { useDock } from '@/use/dock.js'
import { getItinerary } from '@/use/itineraries'
import { usePlanner } from '@/use/planner.js'

import ItineraryPreview from './ItineraryPreview.vue'
import PlannerFolderToolbar from '../PlannerFolderToolbar/PlannerFolderToolbar.vue'
import XModal from '@ionomy/x-modal'
import ItineraryPublicSettings from '../PlannerFolderShare/ItineraryPublicSettings.vue'

export default {
  name: 'PlannerItinerary',
  metaInfo: {
    title: 'Itinerary',
  },
  components: {
    ItineraryPreview,
    PlannerFolderToolbar,
    XModal,
    ItineraryPublicSettings,
  },
  props: {},
  setup(props, context) {
    // Itinerary
    const id = computed(() => context.root.$route.query.itineraryId)
    const { itinerary, hasItineraryLoaded, isItineraryLoading } = getItinerary({
      id,
    })

    const $route = inject('$route')
    const { openShareTools, closeShareTools, backToPlanner } = usePlanner()

    // Determine if Owner
    const $store = inject('$store')
    const userId = computed(() => {
      return $store.state.auth.user._id
    })
    const $auth0 = inject('$auth0')
    const isItineraryOwner = computed(() => {
      return !!itinerary.value.users.filter(
        (u) => (u.accessType === 'owner' || u.accessType === 'manager') && u.userId === userId.value
      )
    })

    // Share
    const isShareModalVisible = computed(() => {
      return !!$route.value.query.share && !!itinerary.value
    })

    return {
      itinerary,
      hasItineraryLoaded,
      isItineraryLoading,
      // Share
      isShareModalVisible,
      openShareTools,
      closeShareTools,
      isItineraryOwner,
    }
  },
}
</script>
