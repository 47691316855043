<template>
  <button
    type="button"
    class="flex flex-row items-center rounded-full px-3 py-1 bg-green-500 mr-1.5 text-white focus:outline-none focus:shadow-outline"
    style="height: 36px"
  >
    <ShareIcon class="mr-0 lg:mr-1.5" />
    <p class="hidden lg:inline">Share</p>
  </button>
</template>

<script>
import { ShareIcon } from 'vue-feather-icons'

export default {
  name: 'PlannerShareButton',
  components: {
    ShareIcon
  }
}
</script>

<style lang="postcss"></style>
