<template>
  <div>
    <div class="flex items-center">
      <!-- Title -->
      <div v-if="itinerary.assetUrl">Title Box</div>
      <h1 v-else class="font-semibold text-3xl">
        {{ itinerary.title || 'Untitled Itinerary' }}
      </h1>
      <p>{{ itinerary.description }}</p>
    </div>

    <p v-if="itinerary.description">{{ itinerary.description }}</p>

    <div class="border border-gray-300 dark:border-gray-600 rounded p-2 mt-3">
      <Itinerary :itinerary="itinerary" />
    </div>
  </div>
</template>

<script>
import { computed, inject, watch } from '@vue/composition-api'
import { createItineraryView } from '@/use/itinerary-views.js'
import { createPinnedItinerary } from '@/use/pinned-itineraries.js'

import Itinerary from './Itinerary.vue'

export default {
  name: 'ItineraryPreview',
  components: {
    Itinerary
  },
  props: {
    itinerary: {
      validator: val => typeof val === 'object',
      required: true
    }
  },
  setup(props, context) {
    const user = computed(() => {
      return context.root.$store.state.auth.user
    })

    function handleSaveToPinnedClick() {
      const userId = user.value._id || null
      createPinnedItinerary({ userId, itinerary: props.itinerary })
    }

    watch(
      () => props.itinerary._id,
      id => {
        const userId = user.value._id || null
        createItineraryView({ userId, itineraryId: id })
      },
      { immediate: true }
    )

    return {
      user,
      handleSaveToPinnedClick
    }
  }
}
</script>

<style lang="postcss"></style>
