import feathersClient, { makeServicePlugin, BaseModel, models } from '../../feathers-client'
import { setVal } from '../../use/utils.js'
import { computed } from '@vue/composition-api'
import { get } from 'lodash'

function extend({ store, module }) {
  window.currentEnv = computed(() => store.getters['environments/currentEnv'])
  window.routedEnv = computed(() => store.getters['environments/routedEnv'])
  window.cachedEnvSlug = computed(() => store.state.environments.cachedEnvSlug)

  store.watch(
    () => [
      store.state.route,
      (store.state.environments && store.state.environments.cachedEnvSlug) || null,
      store.state.auth.isLoading
    ],
    ([route, cachedEnvSlug, isAuthLoading]) => {
      const envSlug = get(route, 'params.env')
      const currentEnv = store.getters['environments/currentEnv']
      if (!envSlug) {
        return
      }
      if (!isAuthLoading && (!currentEnv || envSlug !== cachedEnvSlug)) {
        store.dispatch('environments/findEnvBySlug')
      }
    }
  )

  /**
   * If there's no env for the routed slug, wait until the currentEnv to match, then set the cachedEnvSlug
   */
  store.watch(
    () => [
      store.state.route,
      (store.state.environments && store.state.environments.cachedEnvSlug) || null,
      store.getters['environments/routedEnv']
    ],
    ([route, cachedEnvSlug, routedEnv]) => {
      const routedSlug = get(route, 'params.env')
      // set the initial cachedEnvSlug to match the routedSlug
      if (routedSlug && !cachedEnvSlug) {
        store.commit('environments/setCachedEnvSlug', routedSlug)
      }
      if (routedEnv) {
        store.commit('environments/setCachedEnvSlug', routedSlug)
      }
    }
  )

  return {
    state: {
      hasInitialEnvLoaded: false,
      isEnvLoading: false,
      doesEnvExist: true,
      cachedEnvSlug: '',
      routedEnvSlugs: [],
      haveLoadedBySlug: {}
    },
    mutations: {
      ...setVal('setHasInitialEnvLoaded'),
      ...setVal('setIsEnvLoading'),
      ...setVal('setDoesEnvExist'),
      setCachedEnvSlug(state, slug) {
        state.cachedEnvSlug = slug
      },
      addRoutedEnvSlug(state, slug) {
        if (slug) {
          state.routedEnvSlugs.push(slug)
        }
      },
      setHaveLoadedBySlug(state, slug) {
        state.haveLoadedBySlug[slug] = true
      }
    },
    actions: {
      async findEnvBySlug({ state, rootState, commit }) {
        const slug = get(rootState, 'route.params.env')
        const shouldRecordLoginEvent = get(rootState, 'auth.shouldRecordLoginEvent')

        if (slug && !state.haveLoadedBySlug[slug]) {
          commit('setDoesEnvExist', true)
          commit('setHaveLoadedBySlug', slug)
          commit('setIsEnvLoading', true)
          const res = await models.api.Environment.find({
            query: {
              slug,
              $select: ['_id', 'bbox', 'isPublic', 'name', 'primaryAssetUrl', 'slug', 'mapboxToken']
            },
            $populateParams: { name: 'app' }
          })
          commit('setIsEnvLoading', false)
          if (!state.hasInitialEnvLoaded) {
            commit('setHasInitialEnvLoaded', true)
          }
          if (!res[0]) {
            commit('setDoesEnvExist', false)
          }
          if (res && res.data && res.data.length) {
            const referringEnv = res.data[0]
            store.dispatch('analytics/initiate', referringEnv._id)

            if (shouldRecordLoginEvent) {
              store.dispatch('analytics/logEvent', { type: 'login' })
              store.commit('auth/setShouldRecordLoginEvent', false)
            }

            store.dispatch('analytics/logEvent', {
              type: 'env-view',
              'env-view': {
                envId: referringEnv._id,
                envName: referringEnv.name,
                sourceEnvId: referringEnv._id,
                sourceEnvName: referringEnv.name
              }
            })
          }
        }
      }
    },
    getters: {
      currentEnv(state, getters, rootState) {
        const { cachedEnvSlug } = state
        if (!cachedEnvSlug) {
          return null
        }

        // Use the cached slug as a backup.
        const cachedResponse = models.api.Environment.findInStore({
          query: { slug: cachedEnvSlug }
        })

        const env = get(cachedResponse, 'data.0') || null

        return env
      },
      routedEnv(state, getters, rootState) {
        const routedSlug = get(rootState.route, 'params.env') || null
        if (routedSlug) {
          const routedResponse = models.api.Environment.findInStore({ query: { slug: routedSlug } })
          const routedEnv = get(routedResponse, 'data.0') || null
          return routedEnv
        } else {
          return null
        }
      }
    }
  }
}

class Environment extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Environment'
  // Define default properties here
  static instanceDefaults() {
    return {
      name: '',
      slug: '',
      notes: '',
      startingSceneId: null,
      mapboxToken: '',
      isPublic: true,
      users: [],
      orgs: [],
      createdBy: null
    }
  }
  static setupInstance(data, { models }) {
    const { Category, FrontPageItem, MapPoint } = models.api

    if (data.categories) {
      data.categories = data.categories.map(i => new Category(i))
    }
    if (data.frontPageItems) {
      data.frontPageItems = data.frontPageItems.map(i => new FrontPageItem(i))
    }
    if (data.mapPoints) {
      data.mapPoints.map(i => new MapPoint(i))
      delete data.mapPoints
    }
    if (data.createdAt) {
      data.createdAt = new Date(data.createdAt)
    }
    if (data.updatedAt) {
      data.updatedAt = new Date(data.updatedAt)
    }
    return data
  }
  get mapPoints() {
    const defaultQuery = { envId: this._id }
    return query => {
      return computed(() => {
        return models.api.MapPoint.findInStore({ query: query || defaultQuery }).data
      })
    }
  }
}
const servicePath = 'environments'
const servicePlugin = makeServicePlugin({
  Model: Environment,
  service: feathersClient.service(servicePath),
  servicePath,
  extend
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [
      // Update bbox to be an array if it's null
      context => {
        if (context.data.hasOwnProperty('bbox') && context.data.bbox == null) {
          context.data.bbox = []
        }
      }
    ],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
