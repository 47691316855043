import feathersClient, {
  models,
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'
import { iff, discard } from 'feathers-hooks-common'
import { makeModel } from '@rovit/models/Icon'

const servicePath = 'icons'
const servicePlugin = makeServicePlugin({
  Model: makeModel({ BaseModel, models }),
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [
      // iff(
      //   context => ['create', 'update', 'patch'].includes(context.method),
      //   discard('childCategories')
      // )
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
