<template>
  <div class="flex items-center">
    <XSelect
      v-model="moveToPlannerFolder"
      :items="foldersWithInbox"
      :label="i => i.name"
      placeholder="Move to Folder"
      clearable
      class="block flex-grow"
    />
    <button
      class="bg-green-600 text-white rounded p-2 ml-0.5"
      @click="() => handleMovePlannerItem(item)"
    >
      Move
    </button>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { findPlannerFolders } from '@/use/planner-folders.js'

import { XSelect } from '@rovit/x-select'

export default {
  name: 'PlannerItemMove',
  components: {
    XSelect
  },
  props: {
    item: {
      validator: val => typeof val === 'object',
      required: true
    }
  },
  setup(props, context) {
    const user = computed(() => {
      return context.root.$store.state.auth.user
    })

    // Folders
    const { plannerFolders } = findPlannerFolders({ user })
    const moveToPlannerFolder = ref(null)
    const foldersWithInbox = computed(() => {
      return [{ _id: 'inbox', name: 'Inbox' }].concat(plannerFolders.value)
    })
    const selectedFolder = ref(null)

    function handleMovePlannerItem(plannerItem) {
      let plannerFolderId
      let plannerFolderName
      if (moveToPlannerFolder.value.name === 'Inbox') {
        plannerFolderId = null
        plannerFolderName = ''
      } else {
        plannerFolderId = moveToPlannerFolder.value._id
        plannerFolderName = moveToPlannerFolder.value.name
      }
      const item = plannerItem.clone({ plannerFolderId, plannerFolderName })
      item.commit().save({ $populateParams: { name: 'withService' } })
    }

    return {
      plannerFolders,
      moveToPlannerFolder,
      foldersWithInbox,
      selectedFolder,
      handleMovePlannerItem
    }
  }
}
</script>

<style lang="postcss"></style>
