import feathersClient, { models, makeServicePlugin, BaseModel } from '../../feathers-client'
import { iff, discard } from 'feathers-hooks-common'
import { get } from 'lodash'
import router from '../../router/index.js'

const module = {
  state: {},
  mutations: {},
  actions: {
    // Write the provided categories to the route
    setCategoryIdStack({ rootState }, val) {
      const $route = rootState.route
      let query
      if (val && val.length) {
        query = Object.assign({}, $route.query, {
          categories: val.join(','),
        })
      } else {
        query = Object.assign({}, $route.query)
        delete query.categories
      }
      const newRoute = Object.assign({}, $route, { query })
      router.push(newRoute, () => {})
    },
  },
  getters: {
    categories(state, getters, rootState, rootGetters) {
      const currentEnv = rootGetters['environments/currentEnv']
      const { Category } = models.api

      if (currentEnv._id) {
        const query = { envId: currentEnv._id, $sort: { sortOrder: 1 }, $limit: 2000 }
        return Category.findInStore({ query }).data
      } else {
        return []
      }
    },
    primaryCategory(state, getters, rootState, rootGetters) {
      const currentEnv = rootGetters['environments/currentEnv']
      const { Category } = models.api

      if (!currentEnv) {
        return null
      }
      const results = Category.findInStore({
        query: {
          envId: currentEnv._id,
          sortOrder: 0,
          parentCategoryId: null,
        },
      }).data
      return currentEnv.slug === 'rovit' ? null : results[0]
    },
    categoryIdStack(state, getters, rootState) {
      const categoriesString = get(rootState.route, 'query.categories')
      const routed = categoriesString ? categoriesString.split(',') : []
      return routed
    },
    currentCategoryId(state, getters) {
      return getters.categoryIdStack[getters.categoryIdStack.length - 1] || null
    },
    currentCategory(state, getters) {
      const { Category } = models.api
      const { currentCategoryId } = getters
      if (currentCategoryId) {
        return Category.getFromStore(currentCategoryId)
      } else {
        return null
      }
    },
  },
}

class Category extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'Category'
  static instanceDefaults() {
    return {
      name: '',
      envId: null,
      sortOrder: 0,
      childCategoriesMeta: [],
      childCategories: [], // populated on server,
      iconAdaptiveFill: false,
      iconAdaptiveStroke: false,
      iconId: null,
      iconUrl: '',
    }
  }
  static setupInstance(data, { models }) {
    return data
  }
}
const servicePath = 'categories'
const servicePlugin = makeServicePlugin({
  Model: Category,
  service: feathersClient.service(servicePath),
  servicePath,
  extend: () => module,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [
      iff(
        (context) => ['create', 'update', 'patch'].includes(context.method),
        discard('childCategories')
      ),
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default servicePlugin
