<template>
  <div class="overflow-x-auto flex flex-row">
    <draggable
      v-if="itinerary"
      v-model="_itinerary.containers"
      handle=".container-drag-handle"
      class="flex overflow-x-auto overflow-y-hidden"
      @update="updateOrderOfContainers"
    >
      <div
        v-for="(container, index) in _itinerary.containers"
        :key="container.id"
        class="w-56 ml-2 list-group-item"
        style="min-width: 248px"
      >
        <PlannerFolderItinerarySection
          :container="container"
          :edit="true"
          @update-items="items => updateItems({ container, index, items })"
          @update:container="
            cntr => updateContainer({ container: cntr, index })
          "
          @remove-container="removeContainer"
        />
      </div>

      <button
        type="button"
        class="ml-2 border border-gray-500 rounded flex flex-col items-center justify-center px-2 text-gray-700 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
        @click="handleAddSectionClick"
      >
        <PlusIcon />
        Add Section
      </button>
    </draggable>
  </div>
</template>

<script>
import { handleClones } from '@rovit/utils/handle-clones.js'
import sample from 'lodash/sample'
import { computed, ref, watch } from '@vue/composition-api'
import {
  findItineraryForFolder,
  addContainer,
  removeContainerFromItinerary,
  prepareContainerItem
} from '@/use/itineraries'
import { findPlannerSavedItems } from '@/use/planner-saved-items'

import draggable from 'vuedraggable'
import PlannerFolderItinerarySection from './../PlannerFolderItinerarySection/PlannerFolderItinerarySection'
import { PlusIcon } from 'vue-feather-icons'

export default {
  name: 'PlannerFolderItinerary',
  components: {
    draggable,
    PlusIcon,
    PlannerFolderItinerarySection
  },
  props: {
    itinerary: {
      validator: val => typeof val === 'object',
      required: true
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _itinerary } = clones
    const { save_itinerary } = saveHandlers

    // PlannerSavedItems, formatted for use in the <PlannerItineraryItem/>
    let debounceTimeout
    function updateItems({ container, index, items }) {
      const clone = props.itinerary.clone()
      clone.containers[index] = Object.assign({}, container, { items })
      clone.commit()
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
      debounceTimeout = setTimeout(() => {
        clone.save()
      }, 500)
    }

    function handleAddSectionClick() {
      addContainer({ itinerary: props.itinerary })
    }
    function handleAddToContainer(e) {
      console.log(e)
      debugger
    }

    function updateOrderOfContainers(containers) {
      save_itinerary({ containers })
    }

    function updateContainer({ container, index }) {
      const containers = _itinerary.value.containers.slice()
      containers[index] = container
      save_itinerary({ containers })
    }
    function removeContainer({ container }) {
      removeContainerFromItinerary({ container, itinerary: props.itinerary })
    }

    function savePlannerSavedItem(item) {
      item.save()
    }
    function removePlannerSavedItem(item) {
      const plannerSavedItem = item.remove()
    }
    return {
      ...clones,
      ...saveHandlers,
      handleAddSectionClick,
      handleAddToContainer,
      updateItems,
      updateOrderOfContainers,
      updateContainer,
      removeContainer,
      savePlannerSavedItem,
      removePlannerSavedItem
    }
  }
}
</script>
