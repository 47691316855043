<template>
  <SceneTile
    :src="thumbnail(src, { fit: 'cover' })"
    :title="scene.name || (service && service.name) || ''"
    :rating="4.2"
    :rating-count="4125"
    distance="1.2m"
    :category="currentCategory.name"
    @click="() => handleSceneTileClick()"
    @mouseover="ev => $emit('mouseover', ev)"
  >
    <template #action>
      <button
        ref="addItemPopupButton"
        type="button"
        class="flex flex-row items-end w-6 h-6 focus:outline-none focus:shadow-outline rounded"
        @click.stop="popper.toggle"
      >
        <PlusSquareIcon class="text-green-600" />
      </button>
      <div
        v-show="popper.isVisible"
        ref="addItemPopup"
        v-click-outside="popper.close"
        class="shadow-lg z-30 bg-white dark:bg-gray-800 text-black dark:text-white rounded border border-gray-500"
        @click.stop="() => {}"
      >
        <!-- Close Popper Button -->
        <div class="flex flex-row items-center">
          <div class="flex-grow"></div>
          <button
            type="button"
            class="p-1 hover:bg-gray-400 dark:hover:bg-gray-900 transition-colors duration-200"
            @click="popper.close()"
          >
            <XIcon />
          </button>
        </div>

        <!-- "Add to Planner" Button -->
        <button
          type="button"
          class="whitespace-no-wrap py-2 px-4 hover:bg-gray-400 dark:hover:bg-gray-900 transition-colors duration-200 block w-full"
          @click.stop="handleAddToPlanner"
        >
          Add to Planner
        </button>
      </div>
    </template>
  </SceneTile>
</template>

<script>
import { models } from 'feathers-vuex'
import { usePopper } from '@rovit/popper'
import { computed, ref, watch, onMounted, onUnmounted, toRefs, inject } from '@vue/composition-api'
import { thumbnail } from '@/use/image-transforms.js'
import { get } from 'lodash'

import { SceneTile } from '@rovit/scene-tiles'
import { XIcon, PlusSquareIcon } from 'vue-feather-icons'

export default {
  name: 'DiscoverInfobox',
  components: {
    SceneTile,
    PlusSquareIcon,
    XIcon
  },
  props: {
    service: {
      validator: val => typeof val == 'object',
      default: null
    },
    scene: {
      type: Object,
      required: false,
      default: () => ({ _id: null, name: '' })
    },
    currentCategory: {
      type: Object,
      required: false,
      default: () => ({ name: '' })
    },
    link: {
      validator: val => typeof val === 'object',
      default: null
    }
  },
  setup(props, context) {
    const $store = inject('$store')

    //add to planner saved item popper
    const addItemPopupButton = ref(null)
    const addItemPopup = ref(null)
    const popper = usePopper(addItemPopupButton, addItemPopup, {
      offset: '10,10',
      placement: 'top'
    })

    const src = computed(() => {
      return (
        get(props.scene, 'panoUrl') ||
        get(props.scene, 'assetUrl') ||
        get(props.scene, 'service.primaryAssetUrl') ||
        get(props.service, 'primaryAssetUrl')
      )
    })

    const currentEnv = computed(() => $store.getters['environments/currentEnv'])
    const currentCategory = computed(() => $store.getters['categories/currentCategory'])
    const scene = computed(() => {
      if (props.scene && props.scene._id) {
        return props.scene
      }

      if (props.service) {
        return props.service
      }

      return null
    })

    /**
     * Creates a link object and passes it to `openMiniInfobox`
     */
    async function handleSceneTileClick() {
      context.emit('open-link', { link: props.link })
    }

    function handleAddToPlanner() {
      context.emit('add-to-planner')
      popper.close()
    }

    /**
     * Recording scene-impression events
     */
    watch(
      () => scene.value,
      scene => {
        if (scene) {
          const sceneId = props.scene
          const metaInfo = {
            sourceEnvId: currentEnv.value._id,
            sourceEnvName: currentEnv.value.name,
            sceneId: scene._id,
            sceneName: scene.name,
            categoryId: currentCategory.value && currentCategory.value._id,
            categoryName: currentCategory.value && currentCategory.value.name,
            placement: 'discover'
          }
          $store.dispatch('analytics/logEvent', {
            type: 'scene-impression',
            'scene-impression': metaInfo
          })
        }
      },
      {
        immediate: true
      }
    )

    return {
      addItemPopupButton,
      addItemPopup,
      popper,

      src,
      thumbnail,
      handleSceneTileClick,
      handleAddToPlanner
    }
  }
}
</script>
