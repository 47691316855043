<template>
  <PanZoom
    ref="el"
    :options="{ minZoom: 0.25, maxZoom: 5 }"
    class="image-hotspots-scroll-container"
    @init="onPanZoomInit"
    @scroll="handleContainerScroll"
  >
    <div class="image-hotspots-zoom-container max-h-full" :style="imageStyle">
      <img
        ref="imageWrapper"
        :src="imageSrc"
        alt=""
        class="image-hotspots-image min-w-full"
        dropzone
        @load="handleImageLoad"
        @dragenter.prevent="noop"
        @dragover.prevent="noop"
        @drop.prevent="handleDrop"
        @click="handleImageClick"
      />

      <!-- Target -->
      <button
        v-if="isImageLoaded && showTarget"
        type="button"
        class="absolute z-10 top-0 left-0"
        :style="targetStyle"
        draggable="true"
        @click="handleTargetClick"
        @dragstart="handleTargetDrag"
      >
        <CrosshairIcon></CrosshairIcon>
      </button>

      <!-- Hotspot -->
      <ImageHotspotIcon
        v-for="(hotspot, index) in filteredHotspots"
        :key="hotspot._id"
        :hotspot="hotspot"
        class="absolute"
        :with-border="currentHotspotIndex === index"
        @click="data => handleHotspotClick({ ...data, index })"
        @dragstart="
          ({ draggable, event }) =>
            handleHotspotDrag({ draggable, event, index })
        "
      ></ImageHotspotIcon>
    </div>
  </PanZoom>
</template>

<script>
import ImageHotspotIcon from './ImageHotspotIcon.vue'
import {
  computed,
  ref,
  onMounted,
  onUnmounted,
  watch,
  provide
} from '@vue/composition-api'

export default {
  name: 'ImageHotspots',
  components: {
    ImageHotspotIcon
  },
  mixins: [],
  props: {
    src: {
      type: String,
      required: true
    },
    hotspots: {
      type: Array,
      required: true,
      default: () => []
    },
    /**
     * The percentages of the target direction on the scene record.
     * When a pano is added to a scene, the default target direction gets
     * copied over to the scene, so this will only ever reference the
     * scene targetOffsets.
     */
    targetOffsets: {
      type: Object,
      required: false,
      default: () => ({
        left: 50,
        top: 50
      })
    },
    /**
     * Set to true to allow editing the target direction. Useful when editing a
     * pano's hotspots.
     */
    showTarget: {
      type: Boolean,
      default: false
    },
    /**
     * Allows controlling the display zoom from outside of the component.
     * Scale is a multiplier. This is for the zoom level.
     */
    zoom: {
      type: Number,
      default: 1
    },
    // eslint-disable-next-line vue/require-prop-types
    draggable: {
      default: null
    },
    /**
     * Downsamples the image to 3000x1500 using UploadCare's URL schema.
     */
    preview: {
      type: Boolean,
      default: false
    },
    /**
     * Enables progressive loading using UploadCare's URL schema.
     */
    progressive: {
      type: Boolean,
      default: false
    },
    /**
     * Used to highlight the current hotspot
     */
    currentHotspotIndex: {
      type: [Number, String],
      default: -1
    }
  },
  setup(props, context) {
    /* ------- PANO ------- */
    const imageWrapper = ref(null)
    const isImageLoaded = ref(false)
    /**
     * The pano reference size will be used to determine the ratio at which the
     * hotspot icons should be scaled.  The ratio calculation is performed by
     * comparing clientWidth to actualWidth of the image.
     */
    const panoReferenceWidth = ref(5000)
    const imageClientSize = ref({ width: 0, height: 0 })
    const imageClientToReferenceRatio = computed(() => {
      return imageClientSize.value.width / panoReferenceWidth.value
    })
    const imageSrc = computed(() => {
      return props.src
    })
    const imageStyle = computed(() => {
      return {
        transform: `scale(${props.zoom})`
      }
    })
    function handleImageLoad() {
      isImageLoaded.value = true
      calculateImageSize()
    }
    function handleImageClick(event) {
      context.emit('image-click', event)
    }
    function calculateImageSize() {
      imageClientSize.value = {
        width: imageWrapper.value.clientWidth,
        height: imageWrapper.value.clientHeight
      }
    }

    /* ------- Scrolling & Zooming ------- */
    const el = ref(null)
    const currentTransformOrigin = ref(null)
    /**
     * The transformOrigin is either the manually-specified "currentTransformOrigin"
     * or the center of the current scroll position.
     */
    const transformOrigin = computed(() => {
      return {
        left: imageClientSize.value.width / 2,
        top: imageClientSize.value.height / 2
      }
    })
    let isTicking = false
    let isZooming = false
    /**
     * Sets the point at which the zoom slider will target as the center of the zoom.
     */
    function setCurrentTransformOrigin(offsets) {
      currentTransformOrigin.value = offsets
    }
    /**
     * When the scroll container is scrolled, detect and update the transform origin
     * so that zooming happens at the center of the new coordinates.
     */
    function handleContainerScroll(e) {
      // Track the scroll position of the image
      if (!isZooming && !isTicking) {
        window.requestAnimationFrame(function () {
          setCurrentTransformOrigin({
            left: (e.target.scrollLeft + e.target.clientWidth / 2) / props.zoom,
            top: (e.target.scrollTop + e.target.clientHeight / 2) / props.zoom
          })
          // console.log(e)
          isTicking = false
        })

        isTicking = true
      }
      isZooming = false
    }
    /**
     * Calculate the new scroll position based on the current zoom level.
     * Set isZooming to prevent the handleContainerScroll from changing the
     * transformOrigin while we are manually scrolling.  It would unnecessarily
     * overwrite the value with the same current value.
     */
    watch(
      () => props.zoom,
      zoom => {
        if (el.value) {
          const origin = currentTransformOrigin.value || transformOrigin.value
          const zoomedOrigin = {
            left: origin.left * props.zoom,
            top: origin.top * props.zoom
          }
          const scrollLeft = zoomedOrigin.left - el.value.clientWidth / 2
          const scrollTop = zoomedOrigin.top - el.value.clientHeight / 2

          isZooming = true
          el.value.scrollLeft = scrollLeft
          el.value.scrollTop = scrollTop
        }
      },
      { immediate: true }
    )

    provide('imageWrapper', imageWrapper)
    provide('imageClientToReferenceRatio', imageClientToReferenceRatio)
    provide('imageClientSize', imageClientSize)
    provide('zoom', ref(props.zoom))

    /* ------- Hotspots ------- */
    const filteredHotspots = computed(() => {
      return isImageLoaded.value ? props.hotspots.filter(h => h) : []
    })
    function handleHotspotClick(data) {
      const { hotspot, offset, bottomCenterOffset, event, index } = data

      setCurrentTransformOrigin(offset)
      context.emit('hotspot-click', { hotspot, offset, event, index })
    }
    function handleHotspotDrag({ draggable, event, index }) {
      draggable.index = index
      context.emit('set-draggable', draggable)
    }

    /* ------- Target Direction ------- */
    const targetDirectionOffset = computed(() => {
      return {
        left:
          (props.targetOffsets.percentX / 100) * imageClientSize.value.width,
        top: (props.targetOffsets.percentY / 100) * imageClientSize.value.height
      }
    })
    const targetStyle = computed(() => {
      const origin = targetDirectionOffset.value
      return {
        left: `${(origin.left || 0) - 12}px`,
        top: `${(origin.top || 0) - 12}px`,
        fontSize: '30px',
        textShadow: `0px 0px 2px white`
      }
    })
    function handleTargetClick(event) {
      setCurrentTransformOrigin({
        left: event.target.parentElement.offsetLeft,
        top: event.target.parentElement.offsetTop
      })
    }
    function handleTargetDrag(event) {
      event.dataTransfer.setData('Text', 'set_target_direction')
      const draggable = {
        type: 'set_target_direction',
        offsetX: event.offsetX || 0,
        offsetY: event.offsetY || 0
      }
      context.emit('set-draggable', draggable)
    }

    // Allows smooth drag and drop
    function noop() {}

    /**
     * Handle the current draggable once it is dropped onto the image.
     */
    async function handleDrop(event) {
      const draggable = props.draggable
      if (!draggable) {
        return
      }
      if (
        !draggable.offsetX === undefined ||
        !draggable.offsetY === undefined
      ) {
        throw new Error(
          'offsetX and offsetY are required in the current draggable'
        )
      }
      // Get the number of display pixels where the drop occurred.
      let dropX = event.offsetX - draggable.offsetX / props.zoom
      let dropY = event.offsetY - draggable.offsetY / props.zoom

      if (draggable.type === 'update_hotspot') {
        const { hotspotSize } = draggable
        dropX += hotspotSize.width / 2
        dropY += hotspotSize.height
      }
      // Get the percentage-based coordinates of the drop.
      const percentX = (dropX / event.target.clientWidth) * 100
      const percentY = (dropY / event.target.clientHeight) * 100
      const offsets = { percentX, percentY }

      setCurrentTransformOrigin({
        left: dropX,
        top: dropY
      })
      if (draggable.type === 'set_target_direction') {
        context.emit('set-target-offset', { offsets })
        return
      } else if (draggable.type === 'add_hotspot') {
        context.emit('add-hotspot', { offsets })
      } else if (draggable.type === 'update_hotspot') {
        context.emit('update-hotspot', {
          index: draggable.index,
          hotspot: draggable.hotspot,
          offsets
        })
      }
      context.emit('set-draggable', null)
    }

    onMounted(() => {
      window.addEventListener('resize', calculateImageSize)
      isImageLoaded.value && calculateImageSize()
    })
    onUnmounted(() => {
      window.removeEventListener('resize', calculateImageSize)
    })

    let panZoom
    function onPanZoomInit(instance) {
      panZoom = instance
      if (el.value) {
        resetPanZoom(panZoom)
        context.emit('init', {
          panZoom,
          resetPanZoom,
          calculateImageSize
        })
      }
    }
    function resetPanZoom(panZoom) {
      const zoomTarget = 1
      const $el = el.value.$el
      window.$el = $el
      window.panZoom = panZoom
      const panZoomScene = $el.children[0]
      const sceneDims = panZoomScene.getBoundingClientRect()
      window.$scene = panZoomScene
      const imageWrapper = panZoomScene.children[0]
      const wrapperDims = imageWrapper.getBoundingClientRect()
      const targetImageWidth = wrapperDims.width
      const targetImageHeight = wrapperDims.height
      window.$imageWrapper = imageWrapper
      const offsetCenterX = panZoomScene.clientWidth / 2 - targetImageWidth / 2
      const offsetCenterY =
        panZoomScene.clientHeight / 2 - targetImageHeight / 2
      setTimeout(() => {
        panZoom.moveTo(offsetCenterX, offsetCenterY)
        panZoom.zoomAbs(400, 400, zoomTarget)
        setTimeout(() => {
          console.log(panZoom.getTransform())
        })
      })
    }
    window.reset = function () {
      resetPanZoom(panZoom)
    }
    watch(
      () => [isImageLoaded.value, el.value],
      ([imageLoaded, el]) => {
        if (imageLoaded && el && panZoom) {
          onPanZoomInit(panZoom)
        }
      }
    )

    return {
      imageSrc,
      isImageLoaded,
      handleImageLoad,
      handleImageClick,
      filteredHotspots,
      imageWrapper,
      imageStyle,
      targetStyle,
      el,
      handleContainerScroll,
      setCurrentTransformOrigin,
      handleHotspotClick,
      handleHotspotDrag,
      handleTargetClick,
      handleTargetDrag,
      noop,
      handleDrop,
      onPanZoomInit
    }
  }
}
</script>

<style lang="postcss">
.image-hotspots-scroll-container {
  @apply overflow-scroll min-w-full select-none;
  transform-origin: top left;
}
.image-hotspots-zoom-container {
  @apply relative;
  transform-origin: top left;
}
</style>
