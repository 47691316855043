<template>
  <div class="planner h-full">
    <PlannerInbox v-if="$auth0.state.isAuthenticated" />

    <div v-else class="flex flex-col items-center mt-2">
      <div class="mt-3 flex justify-center">
        <img src="./../../assets/planner-placeholder.svg" />
      </div>
      <div class="mt-3">
        <span class="font-bold text-lg text-black dark:text-white">
          Create & plan your adventures
        </span>
      </div>
      <div class="my-3">
        <button
          class="bg-green-800 mx-auto rounded-lg flex px-2 py-2 items-center"
          @click="loginWithRedirect"
        >
          <UserIcon />
          <span class="ml-1">Login</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/composition-api'
import { $auth0 } from '@/use/auth0.js'

import { UserIcon } from 'vue-feather-icons'
import PlannerInbox from '../PlannerInbox/PlannerInbox'

export default {
  name: 'Planner',
  metaInfo: {
    title: 'Planner',
  },
  components: {
    UserIcon,
    PlannerInbox,
  },
  setup(props, context) {
    function loginWithRedirect() {
      $auth0.loginWithRedirect()
    }

    return {
      loginWithRedirect,
    }
  },
}
</script>
