import { ref, watch } from '@vue/composition-api'
import _omit from 'lodash/omit.js'
import _pick from 'lodash/pick.js'
import _isEmpty from 'lodash/isEmpty.js'
import router from '@/router'

// Allows pulling up the last planner view when clicking the planner button.
const latestQuery = ref({ menu: 'planner' })

let $route
let $router
let watcher

export function usePlanner() {
  $route = router.app.$route
  $router = router.app.$router

  if (!$route) {
    throw new Error('No value was found for $route.')
  }

  if (!watcher) {
    watcher = watch(
      () => $route.query,
      () => updateLatestQuery(),
      { immediate: true }
    )
  }

  return {
    openPlanner,
    closePlanner,
    backToPlanner,
    openPlannerFolder,
    openPlannerItinerary,
    openShareTools,
    closeShareTools
  }
}

function updateLatestQuery() {
  const query = _pick($route.query, ['menu', 'itineraryId', 'folderId'])
  if (!_isEmpty(query)) {
    latestQuery.value = query
  }
}

function openPlanner(options = {}) {
  const queryData = options.query || latestQuery.value
  const newQuery = Object.assign({}, $route.query, queryData)
  $router.replace(Object.assign({}, $route, { query: newQuery }))
}
function closePlanner() {
  const newQuery = _omit($route.query, ['menu', 'itineraryId', 'folderId', 'share'])
  $router.replace(Object.assign({}, $route, { query: newQuery }))
}

function backToPlanner(options = {}) {
  const queryData = latestQuery.value
  const newQuery = _omit(Object.assign({}, $route.query, queryData), [
    'itineraryId',
    'folderId',
    'share'
  ])
  $router.replace(Object.assign({}, $route, { query: newQuery }))
}
function mergeAndNavigate(queryData, toOmit = []) {
  const newQuery = _omit(Object.assign({}, $route.query, queryData), toOmit)
  $router.replace(Object.assign({}, $route, { query: newQuery }))
}

function openPlannerFolder({ folderId }) {
  mergeAndNavigate({ menu: 'planner', folderId })
}
function openPlannerItinerary({ itineraryId }) {
  mergeAndNavigate({ menu: 'planner', itineraryId })
}
function openShareTools() {
  mergeAndNavigate({ share: true })
}
function closeShareTools() {
  mergeAndNavigate({}, ['share'])
}
