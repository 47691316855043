import { ref, watch, computed, reactive } from '@vue/composition-api'
import { unwrapRef } from './utils.js'
import pick from 'lodash/pick'
import _reverse from 'lodash/reverse'
import router from '../router/index'

const routeTemplate = {
  // Route Data
  name: '',
  path: '',
  fullPath: '',
  meta: {},
  params: {},
  query: {},
  title: '',
  // Primary App Data
  env: null,
  service: null,
  scene: null
}

/**
 * The user's navigation history.
 */
export const history = ref([])
export const startingRoute = computed(() => history.value[0])
export const currentRoute = computed(() => {
  return history.value[history.value.length - 1]
})
export const mostRecentDiscoverRoute = computed(() => {
  return _reverse(history.value).find(route => {
    return route.fullPath.startsWith('/discover')
  })
})

export const currentIndex = computed(() => {
  return history.value.findIndex(route => currentRoute.value === route)
})
export const canGoBack = computed(() => {
  return history.value.length > 1
})
// export const canGoForward = computed(() => {
//   return !!history.value[currentIndex.value + 1]
// })

/**
 * Go to the previous page in the history stack. Pop two values off the history stack
 * because router.go(-1) adds another frame to the stack.
 */
export function back() {
  router.go(-1)
  history.value.pop()
  history.value.pop()
}
export function forward() {
  router.go(1)
  history.value.push(context.root.$route)
}

/**
 * useHistory - Initializes the route tracking.
 */
let hasLoaded = false
export function useHistory(context, { env, service, scene }) {
  // Keeps the route object, declared above, in sync with vue-router.
  if (!hasLoaded) {
    addRouteToHistory(context.root.$route, { env, service, scene })
    router.beforeEach((to, from, next) => {
      addRouteToHistory(to, { env, service, scene })
      next()
    })
    keepCurrentRouteUpdated({ env, service, scene })
    hasLoaded = true
  }

  return {
    history
    // replace(route, options) {
    //   router.replace(route, () => {})
    //   history.value.splice(history.value.length - 1, 1, route)
    //   console.log(history.value)
    // },
    // go(index) {
    //   router.go(index)
    //   history.value.push(context.root.$route)
    // },
  }
}

/**
 * Keeps the currentRoute object updated when the env, service, or scene load,
 * which usually will happen asynchronously.
 */
function keepCurrentRouteUpdated({ env, service, scene }) {
  // Keep the env up to date.
  watch(
    () => unwrapRef(env),
    _env => {
      currentRoute.value.env = _env
    }
  )
  // Keep the service up to date.
  watch(
    () => unwrapRef(service),
    _service => {
      currentRoute.value.service = _service
    }
  )
  // Keep the scene up to date (and the service if it's found in the scene)
  watch(
    () => unwrapRef(scene),
    _scene => {
      currentRoute.value.scene = _scene
      if (_scene && _scene.service) {
        currentRoute.value.service = _scene.service
      }
    }
  )
}

export function addRouteToHistory(to, { env }) {
  const _env = unwrapRef(env)
  const routeKeys = ['name', 'path', 'fullPath', 'meta', 'params', 'query']
  const routeAttrs = pick(to, routeKeys)

  const newRoute = reactive(
    Object.assign({}, routeTemplate, {
      ...routeAttrs,
      title: '',
      env: _env
    })
  )
  setTimeout(() => {
    newRoute.title = document.title.split('|')[0]
  }, 1000)
  history.value.push(newRoute)
}
