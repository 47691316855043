<template>
  <div>
    <button
      ref="showMenuButton"
      class="inline-flex flex-row items-center justify-center max-w-48 truncate w-10 h-10"
      :class="[item.type === 'travel' ? 'text-gray-300' : 'text-gray-500']"
      @click="openPopper"
    >
      <XIcon v-if="popper.isVisible" />
      <ChevronDownIcon v-else />
    </button>

    <!-- Popper Menu -->
    <div
      v-show="popper.isVisible"
      ref="popperMenu"
      v-click-outside="closePopperIfReady"
      class="text-black dark:text-gray-100 bg-gray-100 border border-gray-400 dark:bg-gray-800 dark:border-gray-600 rounded w-64"
    >
      <div
        class="bg-gray-100 dark:bg-gray-800 transition-colors duration-200 hover:bg-gray-200 dark:hover:bg-gray-900 p-2 rounded"
      >
        <!-- Name -->
        <label class="block">
          <span>Name</span>
          <input
            v-model="name"
            class="form-input block w-full"
            type="text"
            @blur="$emit('save')"
          />
        </label>
      </div>

      <hr class="border-t border-gray-200 dark:border-gray-700" />

      <button
        v-if="item.link"
        type="button"
        class="bg-gray-100 dark:bg-gray-800 text-black transition-colors duration-200 dark:hover:bg-gray-900 hover:bg-gray-200 py-3 px-2 rounded w-full text-left"
        @click="handleOpenScene"
      >
        Open Scene
      </button>

      <hr class="border-t border-gray-200 dark:border-gray-700" />

      <button
        type="button"
        class="bg-gray-100 dark:bg-gray-800 text-red-600 transition-colors duration-200 dark:hover:bg-gray-900 hover:bg-red-200 hover:text-red-900 dark:hover:text-red-500 py-3 px-2 rounded w-full text-left"
        @click="$emit('remove')"
      >
        Remove from itinerary.
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { openLink } from '@/use/scenes.js'
import { usePopper } from '@rovit/popper'

import { ChevronDownIcon, XIcon, ImageIcon } from 'vue-feather-icons'

export default {
  name: 'ItineraryItemMenu',
  components: {
    ChevronDownIcon,
    XIcon
  },
  props: {
    item: {
      validator: val => typeof val === 'object',
      required: true
    }
  },
  setup(props, context) {
    function updateItem(key, val) {
      const newItem = Object.assign({}, ...props.item, { [key]: val })
      context.emit('update:item', newItem)
    }
    const name = computed({
      get: () => props.item.name,
      set: val => updateItem('name', val)
    })

    // Popper
    const showMenuButton = ref(null)
    const popperMenu = ref(null)
    const popper = usePopper(showMenuButton, popperMenu, {
      placement: 'bottom-end'
    })
    const isPopperReady = ref(false)
    function openPopper() {
      if (popper.isVisible) {
        closePopperIfReady()
      } else {
        popper.open()
        setTimeout(() => {
          isPopperReady.value = true
        }, 500)
      }
      // Workaround for the popper position
      window.scrollBy(0, 1)
      window.scrollBy(0, -1)
    }
    function closePopperIfReady() {
      if (isPopperReady.value) {
        popper.close()
        isPopperReady.value = false
      }
    }

    function handleOpenScene() {
      openLink({ context, link: props.item.link })
    }
    return {
      name,
      // Popper
      showMenuButton,
      popperMenu,
      popper,
      openPopper,
      closePopperIfReady,

      handleOpenScene
    }
  }
}
</script>

<style lang="postcss"></style>
