import { setVal } from '../use/utils.js'

// Store a reference to the main mapbox instance. Set by setMap mutation.
const vueMapbox = {}

export default ($store) => {
  $store.registerModule('mapClusterIndex', {
    namespaced: true,
    state: () => ({
      isOpen: false,
      cluster: null,
      children: [],
    }),
    mutations: {
      ...setVal('setIsOpen'),
      open: (state, payload) => Object.assign(state, payload, { isOpen: true }),
      close: (state) => (state.isOpen = false),
      // Store a reference to the map
      setMap(state, data) {
        Object.assign(vueMapbox, data)
      },
    },
    actions: {
      open({ commit }, { cluster, children }) {
        commit('open', { cluster, children })
      },
    },
    getters: {},
  })
}
