<template>
  <svg
    width="77"
    height="59"
    viewBox="0 0 875 672"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M139 212C233.4 136.4 412 94.5 489.5 83L703.5 73C755.5 79.5 810.5 84 843 112.5C875.5 141 878 164.5 872 200.5C867.2 229.3 827.667 282.5 808.5 305.5C776.667 344.667 709.1 428.4 693.5 450C674 477 669.5 484.5 666.5 502.5C663.5 520.5 677.5 524 681.5 526.5C685.5 529 718.5 546.5 731.5 556C744.5 565.5 768 594 753.5 621C739 648 711 651 697.5 652C684 653 575.5 663.5 485 667.5C394.5 671.5 247 680.5 149 641C51 601.5 6.99995 526 0.999949 464.5C-5.00005 403 21 306.5 139 212Z"
      fill="black"
    />
    <path
      d="M436.5 440.5C434.5 349 356 327.5 319.5 329.5C233 329.5 153 414.5 142 479.5C120.5 608 224.5 633.5 282.5 622C339.749 610.649 436.5 548.5 436.5 440.5Z"
      fill="#44B349"
    />
    <path
      d="M102 484.5C94.7999 522.9 106.333 562.167 113 577C100.167 568 70.4 542 54 510C33.5 470 37 423 54 375.5C71 328 117 267.5 213.5 210C290.7 164 427 132.833 485.5 123L712 113C751 117.5 782.5 123.5 799 131C831.5 147 837 165.5 836.5 179C836 192.5 828.5 210.5 811 237.5C793.5 264.5 721.5 351 692.5 387C663.5 423 643.5 448.5 634 472.5C624.5 496.5 624 522.5 637.5 541C651 559.5 673 564.5 695 578C717 591.5 720 600.5 718 605C716 609.5 707.5 611.5 695 613C682.5 614.5 626.5 618.5 558.5 624C504.1 628.4 405.167 631.167 362.5 632C374 624 397 610 420.5 583C452.235 546.539 468.5 508 473 475.5C477.5 443 479 407 458.5 369C438 331 407 312 387.5 303.5C368 295 328.5 284 283.5 294.5C238.5 305 200 324.5 164.5 361.5C129 398.5 111 436.5 102 484.5Z"
      fill="#44B349"
    />
    <ellipse cx="598.5" cy="114" rx="113.5" ry="114" fill="black" />
    <ellipse cx="598.5" cy="114" rx="74.5" ry="75" fill="white" />
  </svg>
</template>

<script>
export default {
  name: 'RovitLogo'
}
</script>

<style lang="postcss"></style>
