<template>
  <div
    class="relative max-h-screen overflow-y-scroll bg-white border border-gray-400 rounded-lg shadow-2xl map-cluster-index dark:bg-gray-900 dark:text-gray-200 dark:border-gray-800"
  >
    <div class="p-8 container-width">
      <!-- Search & Zoom -->
      <div class="flex flex-row w-full pl-2">
        <div class="flex items-center w-1/2 px-1 text-black bg-gray-200 rounded">
          <SearchIcon class="" />
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Search"
            class="flex-grow h-full py-2 pl-2 bg-transparent outline-none focus:outline-none"
          />
        </div>
        <div class="ml-2">
          <button
            class="flex inline h-full p-1 text-white bg-green-500 rounded outline-none"
            @click="handleZoomClick"
          >
            <ZoomInIcon /> Zoom Map
          </button>
        </div>
      </div>

      <!-- Categories -->
      <div :class="{ '-ml-2': !shouldShowRovit }">
        <DiscoverCategories
          :primary-category="primaryCategory"
          :categories="usedCategories"
          :category-id-stack.sync="categoryIdStack"
          :env="currentEnv"
          :is-clustered="true"
          :should-show-rovit="shouldShowRovit"
        />
      </div>

      <!-- Infoboxes -->
      <div class="flex flex-wrap pl-2.5">
        <DiscoverInfobox
          v-for="scene in scenes"
          :key="scene._id"
          class="mr-1.5 mt-2"
          :scene="scene"
          :env="currentEnv"
          :link="makeLink({ scene, service: scene.service, env: currentEnv })"
          @add-to-planner="() => handleAddToPlanner({ scene })"
          @open-link="({ link }) => handleOpenLink({ link, item: scene })"
        />
      </div>

      <!-- Pagination Controls -->
      <div v-if="scenes.length" class="flex justify-center mt-3">
        <FeathersVuexPagination v-model="pagination" :latest-query="latestQuery">
          <template
            #default="{
              currentPage,
              pageCount,
              toStart,
              toEnd,
              toPage,
              next,
              prev,
              canNext,
              canPrev
            }"
          >
            <SidebarPagination
              :current-page="currentPage"
              :page-count="pageCount"
              :can-prev="canPrev"
              :can-next="canNext"
              @to-start="toStart"
              @to-end="toEnd"
              @to-page="toPage"
              @next="next"
              @prev="prev"
            />
          </template>
        </FeathersVuexPagination>
      </div>
    </div>
    <!-- Right -->
    <div class="absolute top-0 right-0 px-1 py-2">
      <ToolbarIconButton icon="XIcon" @click="handleClose" />
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from '@vue/composition-api'
import { models, FeathersVuexPagination } from 'feathers-vuex'
import { uniq, includes, get, toLower, last } from 'lodash'
import { makeLink } from '@/use/discover.js'
import { saveToPlanner, addToPlanner } from '@/use/planner-saved-items.js'
import { findPaginatedScenesForEnv } from '@/use/scenes'
import { bbox } from '@turf/turf/turf.js'

import { ZoomInIcon, SearchIcon } from 'vue-feather-icons'
import { SidebarPagination } from '@rovit/sidebar-pagination'
import DiscoverCategories from '../DiscoverCategories/DiscoverCategories.vue'
import DiscoverInfobox from '../DiscoverInfobox/DiscoverInfobox.vue'
import ToolbarIconButton from '../ToolbarIconButton.vue'

export default {
  name: 'MapClusterIndex',
  components: {
    ZoomInIcon,
    SearchIcon,
    SidebarPagination,
    DiscoverCategories,
    DiscoverInfobox,
    FeathersVuexPagination,
    ToolbarIconButton
  },
  setup(props) {
    const $store = inject('$store')
    const { Scene } = models.api
    const searchQuery = ref('')
    const pagination = ref({
      $limit: 12,
      $skip: 0
    })
    const categoryIdStack = ref([])

    const cluster = computed(() => $store.state.mapClusterIndex.cluster)
    const children = computed(() => $store.state.mapClusterIndex.children)
    const categories = computed(() => $store.getters['categories/categories'])
    const primaryCategory = computed(() => $store.getters['categories/primaryCategory'])
    const currentEnv = computed(() => $store.getters['environments/currentEnv'])
    const user = computed(() => $store.state.auth.user)
    const shouldShowRovit = computed(() => currentEnv.value.slug === 'rovit')
    const boundingBox = computed(() => {
      if (children.value) {
        const features = children.value.map(c => {
          return {
            id: c.id,
            type: 'Point',
            geometry: c.geometry,
            properties: c.properties || null
          }
        })
        return bbox({ type: 'FeatureCollection', features })
      }
      return []
    })

    const usedCategories = computed(() => {
      const categoryList = children.value.reduce((result, c) => {
        const { categories } = c
        if (categories && categories.length) {
          result.push(...categories.map(category => category.id).filter(v => v))
        }
        return result
      }, [])

      const categoryIds = uniq(categoryList)

      const filteredCategories = categories.value.filter(category =>
        includes(categoryIds, category._id)
      )

      /**
       * If environment is rovit, primary category is `null`
       * If environment is other than rovit then primary category is current env
       * therefore categories should have first category as primary category
       */
      if (primaryCategory.value) {
        filteredCategories.unshift(primaryCategory.value)
      }
      return filteredCategories
    })

    const sceneIds = computed(() => {
      return children.value.map(c => c._id)
    })

    const { scenes, latestQuery } = findPaginatedScenesForEnv({
      env: currentEnv,
      search: searchQuery,
      minSearchLength: 1,
      category: computed(() => {
        if (categoryIdStack.value.length) {
          // Get latest pushed category
          const id = last(categoryIdStack.value)
          return categories.value.find(category => category._id === id)
        }
        return null
      }),
      sceneIds,
      pagination,
      queryWhen: computed(() => {
        return true
      })
    })

    // Planner
    async function handleAddToPlanner({ scene }) {
      const { PlannerSavedItem } = models.api
      if (user.value) {
        saveToPlanner({
          service: scene.service,
          scene: scene,
          env: currentEnv.value,
          $toasted: this.$toasted
        })
      } else {
        addToPlanner({
          service: scene.service,
          scene: scene,
          env: currentEnv.value
        })

        $store.dispatch('planner/openPlanner')
      }
    }

    // Open Mini Infobox, add a marker and position it visible above the mini infobox.
    function handleOpenLink({ link, item }) {
      $store.dispatch('miniInfobox/openWithLink', link)
      $store.commit('mapMain/setMarkedFeature', item)
      $store.dispatch('mapZoom/pan', {
        center: get(item, 'location.coordinates'),
        forMiniInfobox: true
      })
    }

    function handleZoomClick() {
      $store.dispatch('mapZoom/fitBounds', boundingBox.value)
      handleClose()
    }

    function handleClose() {
      $store.commit('mapClusterIndex/close')
    }

    return {
      searchQuery,
      pagination,
      categoryIdStack,
      primaryCategory,
      currentEnv,
      usedCategories,
      scenes,
      latestQuery,
      shouldShowRovit,

      makeLink,

      handleAddToPlanner,
      handleOpenLink,
      handleZoomClick,
      handleClose
    }
  }
}
</script>

<style lang="postcss" scoped>
.container-width {
  width: 728px;
}
</style>
