export default $store => {
  const mql = window.matchMedia('(orientation: portrait)')

  $store.registerModule('device', {
    namespaced: true,
    state: {
      // Determines the position of the split screen in the Infobox view.
      // The default is landscape because desktop infobox will have them side by side.
      // while `portrait` will mean they should be stacked one over the other.
      orientation: 'landscape'
    },
    mutations: {
      setOrientation(state) {
        state.orientation = mql.matches ? 'portrait' : 'landscape'
        console.log(state.orientation)
      }
    }
  })

  mql.addEventListener('change', $store.commit('device/setOrientation'))
}
