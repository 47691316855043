import { models, useFind, useGet } from 'feathers-vuex'
import { computed, ref, watch } from '@vue/composition-api'
import { get } from 'lodash'

export async function createFolder() {
  const { PlannerFolder } = models.api
  const folder = await new PlannerFolder({ name: 'New Folder' }).save()
  return folder
}

export function getPlannerFolder(plannerFolderId) {
  const { PlannerFolder } = models.api
  const params = computed(() => {
    return {
      $populateParams: { name: 'app' },
    }
  })
  const queryWhen = computed(() => {
    return !PlannerFolder.getFromStore(plannerFolderId)
  })
  const { item: plannerFolder } = useGet({
    model: PlannerFolder,
    id: plannerFolderId,
    params,
    queryWhen,
    lazy: true,
  })

  return { plannerFolder }
}

const requested = ref(false)
export function findPlannerFolders({ user }) {
  const { PlannerFolder } = models.api

  const params = computed(() => {
    const userId = get(user.value, '_id')
    if (!userId) {
      return null
    }
    const query = {
      'users.userId': userId,
      $sort: { 'users.sortOrder': 1 },
    }
    return { query, $populateParams: { name: 'app' } }
  })
  const queryWhen = computed(() => {
    return !requested.value
  })
  const { items: plannerFolders, haveBeenRequested } = useFind({
    model: PlannerFolder,
    params,
    queryWhen,
  })
  watch(
    () => haveBeenRequested.value,
    (val) => {
      if (val && !requested.value) {
        requested.value = true
      }
    },
    { immediate: true }
  )
  return { plannerFolders }
}
