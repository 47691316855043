<template>
  <div class="flex w-full pointer-events-none navbar">
    <div class="pointer-events-auto">
      <ToolbarIconButton
        icon="ChevronLeftIcon"
        class="flex items-center justify-center w-10 h-10 ml-2 rounded-full focus:outline-none"
        :class="[
          canGoBack ? 'bg-white cursor-pointer' : 'bg-gray-400 opacity-75 cursor-not-allowed'
        ]"
        @click.native="back"
      />
    </div>

    <div class="relative flex flex-row items-center w-full px-2 search-wrapper">
      <div>
        <!-- Category Menu Button -->
        <!-- <button
          ref="categoryMenuButton"
          class="items-center hidden h-full p-2 text-white bg-blue-800 rounded-l md:flex"
          @click="categoryPopper.toggle"
        >
          <span class="mr-1">All</span>
          <ChevronDownIcon size="1x" />
        </button> -->

        <!-- Category Popper -->
        <!-- <div
          v-show="categoryPopper.isVisible"
          ref="categoryMenuPopup"
          v-click-outside="categoryPopper.close"
          class="absolute left-0 right-0 flex flex-col text-black bg-white rounded-b rounded-tr shadow-lg dark:bg-gray-900 dark:text-white"
        >
          <button
            v-for="category in sortedCategories"
            :key="category._id"
            class="block px-2 py-1 text-left whitespace-no-wrap transition-colors duration-200 hover:bg-gray-300"
            style="height: 40px"
            @click="() => handleCategoryClick(category)"
          >
            {{ category.name }}
          </button>
        </div> -->
      </div>

      <!-- Search Input -->
      <div class="relative w-full">
        <div
          class="hidden md:block relative items-center pointer-events-auto bg-white text-black dark:text-white dark:bg-gray-900 px-1.5 rounded-full w-48 flex items-center hover:bg-gray-100 dark:hover:bg-gray-800 focus-within:shadow-outline focus-within:bg-white transition-bg transition-duration-200 shadow-xs"
        >
          <input
            v-model="searchQuery"
            :placeholder="getSearchPlaceholder"
            class="w-full h-full px-2 py-2 leading-normal bg-transparent rounded outline-none focus:outline-none sm:h-full md:h-auto lg:h-full"
            style="height: 40px"
          />

          <button type="button" class="absolute p-2 right-0">
            <SearchIcon />
          </button>
        </div>
        <div
          class="md:hidden rounded-full bg-white text-black dark:text-white dark:bg-gray-900 w-10 h-10"
        >
          <button type="button" class="p-2">
            <SearchIcon />
          </button>
        </div>

        <!-- <div
          v-show="popper.isVisible"
          ref="searchMenuPopper"
          v-click-outside="popper.close"
          class="w-full search-menu"
        >
          <div
            class="absolute left-0 right-0 z-40 w-40 w-full h-full p-2 mt-1 overflow-y-auto text-black bg-white border border-gray-500 rounded shadow-lg dark:bg-gray-900 dark:text-white"
          >
            <div v-if="searchQuery.length">
              <div v-if="isSearchPending">Searching...</div>
              <div v-if="searchResults.length">
                <div v-for="result in searchResults" :key="result._id">
                  <button @click="() => openInfobox(result)">
                    {{ result.name }}
                  </button>
                </div>
              </div>
              <div v-else>No Results</div>
            </div>

            <div v-else>
              <h2 class="text-xl font-medium">Favorites</h2>
              <div
                class="flex flex-row items-center justify-center h-12 text-gray-700 bg-gray-200 rounded dark:bg-gray-800 dark:text-gray-300"
              >
                No Favorites
              </div>
              <h2 class="mt-2 text-xl font-medium">Recommended</h2>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="flex pointer-events-auto">
      <PlannerButton />
    </div>

    <div class="flex pointer-events-auto">
      <XPopper>
        <template #reference="{ toggle }">
          <div
            ref="reference"
            class="flex items-center justify-center w-10 h-10 mr-2 bg-red-900 rounded-full cursor-pointer"
            @click.prevent.stop="toggle"
          >
            <div v-if="nameInitial" class="text-white">{{ nameInitial }}</div>
            <div v-else>
              <UserPlusIcon class="text-white" />
            </div>
          </div>
        </template>

        <template #popper="{ toggle }">
          <div
            ref="popper"
            v-click-outside="toggle"
            class="absolute z-50 p-1 text-center text-black bg-white rounded shadow-lg"
          >
            <div v-if="$store.state.auth.auth0User" class="p-1" @click="logoutWithRedirect">
              Logout
            </div>
            <div v-else class="p-1 cursor-pointer" @click="loginWithRedirect">Login</div>
          </div>
        </template>
      </XPopper>
    </div>
  </div>
</template>

<script>
import { computed, watch, createElement, ref, inject } from '@vue/composition-api'
import { usePopper } from '@rovit/popper'
import { isSm } from './../../utils/get-media-width'
import { $auth0 } from '@/use/auth0.js'
import { findScenesForEnv, openLink } from '@/use/scenes'
import { canGoBack, back } from '@/use/history.js'
import { openMiniInfobox, makeLink } from '@/use/discover.js'
import { isIframe, postMessageLogin } from '@/use/iframe.js'

import { SearchIcon, UserPlusIcon } from 'vue-feather-icons'
import PlannerButton from './PlannerButton.vue'
import ToolbarIconButton from '../ToolbarIconButton.vue'
import RovitLogo from '../RovitLogo'
import { XPopper } from '@ionomy/x-popper'
import { sortBy } from 'lodash'

export default {
  name: 'Navbar',
  components: {
    PlannerButton,
    ToolbarIconButton,
    SearchIcon,
    UserPlusIcon,
    XPopper
  },
  props: {
    env: {
      validator: val => typeof val === 'object',
      required: true
    }
  },
  setup(props, context) {
    const $store = inject('$store')
    const env = computed(() => props.env)

    /**
     * latestCurrentEnvSlug
     * Useful to restore context when switching back to discover from one of the other
     * tabs in the primary navigation.
     */
    const latestCurrentEnvSlug = computed(() => {
      return context.root.$store.state.environments.lastViewedEnvSlug || 'rovit'
    })

    const searchQuery = ref('')
    const { scenes, isPending: isFindScenesPending } = findScenesForEnv({
      env,
      search: searchQuery,
      paginate: false,
      minSearchLength: 1,
      queryWhen: computed(() => {
        return searchQuery.value.length
      })
    })
    const searchResults = computed(() => {
      return scenes.value
    })
    const isSearchPending = computed(() => {
      return isFindScenesPending.value
    })

    const categories = computed(() => $store.getters['categories/categories'])
    const sortedCategories = computed(() => sortBy(categories.value, 'name'))

    //search floating menu
    const searchPopperTrigger = ref(null)
    const searchMenuPopper = ref(null)
    const popper = usePopper(searchPopperTrigger, searchMenuPopper, {
      placement: 'bottom-start'
    })

    //category menu
    const categoryMenuButton = ref(null)
    const categoryMenuPopup = ref(null)
    const categoryPopper = usePopper(categoryMenuButton, categoryMenuPopup, {
      placement: 'bottom-start'
    })

    const nameInitial = computed(() => {
      const auth0User = context.root.$store.state.auth.auth0User
      if (auth0User) {
        return auth0User.name.charAt(0).toUpperCase()
      } else {
        return null
      }
    })
    function loginWithRedirect() {
      if (isIframe.value) {
        postMessageLogin()
      } else {
        $auth0.loginWithRedirect()
      }
    }

    function logoutWithRedirect() {
      $auth0.client.logout({
        redirectTo: window.location.origin
      })
    }

    // For calculating the Discover route.
    const routedEnvSlugs = computed(() => {
      return context.root.$store.state.environments.routedEnvSlugs
    })
    const latestRoutedEnvSlug = computed(() => {
      return routedEnvSlugs.value[routedEnvSlugs.value.length - 1] || null
    })

    const getSearchPlaceholder = computed(() => {
      if (isSm()) {
        return `Search ${context.root.$route.name}`
      }
      return 'Search'
    })

    function getIcon() {
      return
    }

    function openInfobox(scene) {
      const service = scene.service
      const env = ref({ _id: '5f561551bdaa16328fd3d088', name: 'Rovit' })
      const link = makeLink({ scene, service, env })
      popper.close()
      openMiniInfobox({ link })
      // openLink({context, })
    }

    //to be implemented
    function handleCategoryClick(category) {
      const { scenes } = findScenesForEnv({ env, searchQuery, category })
    }

    return {
      loginWithRedirect,
      logoutWithRedirect,
      nameInitial,
      getSearchPlaceholder,
      getIcon,
      latestCurrentEnvSlug,
      //search
      openInfobox,
      searchPopperTrigger,
      searchMenuPopper,
      popper,
      searchQuery,
      searchResults,
      isSearchPending,

      //category
      categoryMenuButton,
      categoryMenuPopup,
      categoryPopper,
      sortedCategories,
      handleCategoryClick,

      // History
      canGoBack,
      back
    }
  }
}
</script>
<style lang="postcss" scoped>
.search-box {
  max-width: 110px;
}

a.router-link-active > div {
  @apply bg-blue-500;
}
a.router-link-active span.hidden {
  @apply block;
}
.override-x-input-width >>> input {
  @apply w-32;
}
@screen dark {
  .override-hover-bg:hover {
    @apply bg-gray-800 text-white;
  }
}
.search-menu {
  height: 80vh;
}
@screen md {
  .search-menu {
    height: 350px;
  }
}
</style>
