import { reactive, computed } from '@vue/composition-api'

const defaults = {
  page: 'default',
  top: -234,
  min: -234,
  max: -56
}

const stateByPage = reactive({})

export function useDock(options = {}) {
  const { page, top, min, max } = Object.assign({}, defaults, options)

  if (!stateByPage[page]) {
    stateByPage[page] = {
      page,
      top,
      min,
      max
    }
  }
  return stateByPage[page]
}
