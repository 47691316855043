<template>
  <div class="planner-inbox h-full relative">
    <PlannerToolbar class="absolute w-full z-20" />

    <div class="px-2 sm:px-8 h-full overflow-y-auto absolute top-0 bottom-0 w-full">
      <div class="flex flex-row overflow-x-auto -mx-0.5 pt-12">
        <!-- Inbox Button -->
        <button type="button" class="folder-button current">
          <InboxIcon />
          Inbox
        </button>

        <!-- Other Folders -->
        <div v-for="folder in plannerFolders" :key="folder._id" class="mr-1">
          <PlannerFolderView :folder="folder" @select="() => openFolder({ folder })" />
        </div>

        <!-- "Add Folder" Button -->
        <button class="folder-button" @click="handleCreateNewFolder">
          <PlusIcon />
          Folder
        </button>
      </div>

      <div v-if="plannerSavedItems.length" class="flex flex-row mt-2 overflow-x-auto">
        <div v-for="item in plannerSavedItems" :key="item._id" class="mr-2">
          <PlannerTile
            v-if="item.service"
            :item="item"
            draggable="true"
            @dragstart="event => handleDragPlannerTile({ event, item })"
            @click="() => handleItemClick(item)"
          />
        </div>
      </div>

      <div v-else>
        <PlannerEmpty :label="`The inbox is empty`" />
      </div>

      <hr class="mt-3 mb-4 border-gray-300 dark:border-gray-700" />

      <PlannerItineraries />
    </div>
  </div>
</template>

<script>
import { models } from 'feathers-vuex'
import { openMiniInfobox } from '@/use/discover'
import { findPlannerSavedItems, makeLinkFromPlannerSavedItem } from '@/use/planner-saved-items.js'
import { ref, computed, watch, provide, inject } from '@vue/composition-api'
import { createFolder, findPlannerFolders } from '@/use/planner-folders.js'
import { omit } from 'lodash'
import { usePlanner } from '@/use/planner.js'

import { PlusIcon, InboxIcon } from 'vue-feather-icons'
import PlannerTile from '../PlannerTile/PlannerTile.vue'
import PlannerFolderView from './../PlannerFolderView/PlannerFolderView'
import PlannerEmpty from './../PlannerEmpty/PlannerEmpty'
import PlannerItineraries from '../PlannerItineraries/PlannerItineraries.vue'
import PlannerToolbar from '../PlannerToolbar/PlannerToolbar.vue'

export default {
  name: 'PlannerInbox',
  metaInfo: {
    title: 'Planner'
  },
  components: {
    PlusIcon,
    PlannerEmpty,
    PlannerFolderView,
    PlannerTile,
    PlannerToolbar,
    InboxIcon,
    PlannerItineraries
  },
  setup(props, context) {
    const { PlannerFolder, PlannerSavedItem } = models.api
    const currentOrg = context.root.$store.getters['auth/currentOrg']
    const user = computed(() => {
      return context.root.$store.state.auth.user
    })

    // Folders
    const { plannerFolders } = findPlannerFolders({ user })

    const { openPlannerFolder } = usePlanner()
    async function openFolder({ folder }) {
      openPlannerFolder({ folderId: folder._id })
    }
    async function handleCreateNewFolder() {
      createFolder()
    }

    function handleItemClick(plannerSavedItem) {
      const link = makeLinkFromPlannerSavedItem({ plannerSavedItem })
      openMiniInfobox({ link })
    }

    const { plannerSavedItems } = findPlannerSavedItems({
      plannerFolderId: null
    })

    const currentDraggable = ref(null)
    provide('currentDraggable', currentDraggable)

    function handleDragPlannerTile({ event, item }) {
      event.dataTransfer.setData('Text', 'planner_item_drag')
      currentDraggable.value = {
        type: 'planner_item_drag',
        plannerItem: item
      }
    }

    return {
      // Inbox,
      plannerSavedItems,
      handleItemClick,
      handleDragPlannerTile,

      // Folders
      plannerFolders,
      handleCreateNewFolder,
      openFolder
    }
  }
}
</script>

<style lang="postcss" scoped>
.folder-button {
  @apply border bg-gray-200 transition-colors border-gray-400 duration-150 rounded flex items-center justify-center text-gray-800 mr-1 flex flex-col relative;
  width: 70px;
  min-width: 70px;
  height: 80px;
  min-height: 80px;
}
.current {
  @apply border-2 border-blue-300;
}
@screen dark {
  .folder-button {
    @apply bg-gray-800 text-gray-500;
  }
  .folder-button:hover {
    @apply bg-gray-700 text-gray-400;
  }
}
</style>
