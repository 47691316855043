<template>
  <Slider>
    <div class="flex flex-row items-start pt-2.5 px-2.5">
      <!-- Rovit -->
      <div
        v-if="shouldShowRovit"
        class="flex flex-row items-start"
        :class="[rovitWrapperPadding, rovitWrapperBorder]"
      >
        <CategoryRovit class="cursor-pointer" @click="handleRovitClick" />
        <ChevronRightIcon
          v-if="env.slug === 'rovit' && categoryStack.length"
          class="-mr-1"
          style="margin-top: 14px; margin-left: -2px"
        />
      </div>

      <!-- Category Stack -->
      <div
        class="flex flex-row items-start border-r border-gray-400 dark:border-gray-700 mr-1.5"
        :class="[categoryStackPadding]"
      >
        <div
          v-for="(category, index) in categoryStack"
          :key="category._id"
          class="flex flex-row items-start"
        >
          <CategoryTile :category="category" @click="e => handleStackedCategoryClick(category)" />
          <ChevronRightIcon
            v-if="index !== categoryStack.length - 1"
            class="-mr-1"
            style="margin-top: 14px; margin-left: -2px"
          />
        </div>
      </div>

      <!-- Child Categories -->
      <div class="flex flex-row items-start">
        <CategoryTile
          v-for="category in childCategories"
          :key="category._id"
          :category="category"
          class="mr-1.5"
          @click="e => handleCategoryClick(category)"
        />
      </div>
    </div>
  </Slider>
</template>

<script>
import { Slider } from '@rovit/slider'
import { computed, toRefs } from '@vue/composition-api'
import { models } from 'feathers-vuex'

import { CategoryRovit, CategoryTile } from '@rovit/category-tile'
import { ChevronRightIcon } from 'vue-feather-icons'

export default {
  name: 'DiscoverCategories',
  components: {
    CategoryRovit,
    CategoryTile,
    ChevronRightIcon,
    Slider
  },
  props: {
    categoryIdStack: {
      type: Array,
      default: () => [],
      required: true
    },
    categories: {
      type: Array,
      default: () => [],
      required: true
    },
    env: {
      validator: val => typeof val === 'object',
      required: true,
      default: null
    },
    shouldShowRovit: {
      type: Boolean,
      default: true
    },
    primaryCategory: {
      validator: val => typeof val === 'object',
      required: true
    },
    isClustered: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const { Category } = models.api
    const { env, categories, categoryIdStack, primaryCategory, isClustered } = toRefs(props)

    const remainingCategories = computed(() => {
      return env.value.slug === 'rovit' ? categories.value : categories.value.slice(1)
    })
    const isPrimaryPrepended = computed(() => {
      const primary = primaryCategory.value
      const idStack = categoryIdStack.value
      const stack = idStack.map(id => Category.getFromStore(id))
      return primary && idStack.length > 0 && !idStack.includes(primary._id)
    })
    const categoryStack = computed(() => {
      const idStack = categoryIdStack.value
      const stack = idStack.map(id => Category.getFromStore(id))
      if (isPrimaryPrepended.value) {
        stack.unshift(primaryCategory.value)
      }
      return stack
    })
    const currentCategory = computed(() => {
      const stack = categoryIdStack.value
      return Category.getFromStore(stack[stack.length - 1])
    })
    const childCategories = computed(() => {
      const remaining = categories.value
      if (currentCategory.value) {
        return remaining.filter(cat => cat.parentCategoryId === currentCategory.value._id)
      } else {
        return remaining.filter(cat => cat.parentCategoryId === null)
      }
    })

    // Style computes
    const rovitWrapperPadding = computed(() => {
      return env.value.slug === 'rovit'
        ? categoryStack.value.length
          ? ''
          : ''
        : categoryStack.value.length
        ? 'pr-2'
        : 'pr-2'
    })
    const rovitWrapperBorder = computed(() => {
      return env.value.slug === 'rovit' ? '' : 'border-r-2 border-gray-600 dark:border-gray-700'
    })
    const categoryStackPadding = computed(() => {
      return env.value.slug === 'rovit'
        ? categoryStack.value.length
          ? 'pr-1.5'
          : ''
        : categoryStack.value.length
        ? 'px-2'
        : 'pl-0.25'
    })

    function handleRovitClick() {
      if (isClustered.value) {
        context.emit('update:categoryIdStack', [])
      } else {
        context.root.$router.push({ name: 'Discover', params: { env: 'rovit' } }, () => {})
      }
    }
    /**
     * If the primaryCategory is alone in the stack, treat the click like a category click,
     * otherwise, navigate back to that category.
     */
    function handleStackedCategoryClick(category) {
      const isPrimaryCategory = category === primaryCategory.value
      if (isPrimaryCategory) {
        context.emit('update:categoryIdStack', [])
      } else {
        let index = categoryStack.value.findIndex(cat => cat._id === category._id)
        if (!isPrimaryPrepended.value) {
          index++
        }
        const newStack = props.categoryIdStack.slice(0, index)
        context.emit('update:categoryIdStack', newStack)
      }
    }
    function handleCategoryClick(category) {
      const newStack = props.categoryIdStack.slice()
      newStack.push(category._id)
      context.emit('update:categoryIdStack', newStack)
    }

    return {
      remainingCategories,
      currentCategory,
      categoryStack,
      childCategories,

      // Style computes
      rovitWrapperPadding,
      rovitWrapperBorder,
      categoryStackPadding,

      handleRovitClick,
      handleStackedCategoryClick,
      handleCategoryClick
    }
  }
}
</script>

<style lang="postcss"></style>
