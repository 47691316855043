<template>
  <div>
    <button
      ref="showMenuButton"
      class="inline-flex flex-row items-center justify-center max-w-48 truncate w-10 h-10"
      :class="[item.type === 'travel' ? 'text-gray-300' : 'text-gray-500']"
      @click="openPopper"
    >
      <XIcon v-if="popper.isVisible" />
      <ChevronDownIcon v-else />
    </button>

    <!-- Popper Menu -->
    <div
      v-show="popper.isVisible"
      ref="popperMenu"
      v-click-outside="closePopperIfReady"
      class="bg-gray-100 border border-gray-400 dark:bg-gray-800 dark:border-gray-600 rounded w-64"
    >
      <div
        class="px-2.5 py-2 rounded-t border-b border-gray-400 bg-gray-200 hover:bg-gray-300 transition-colors duration-200"
      >
        <PlannerItemMove :item="item" />
      </div>

      <hr class="border-t border-gray-200 dark:border-gray-700" />

      <button
        type="button"
        class="bg-gray-100 dark:bg-gray-800 text-red-600 transition-colors duration-200 dark:hover:bg-gray-900 hover:bg-red-200 hover:text-red-900 dark:hover:text-red-500 py-3 px-2 rounded w-full text-left"
        @click="() => handleAddToPlanner(item)"
      >
        Remove from Planner
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { usePopper } from '@rovit/popper'
import { models } from 'feathers-vuex'
import { handleClones } from '@rovit/utils/handle-clones.js'

import { ChevronDownIcon, XIcon, ImageIcon } from 'vue-feather-icons'
import PlannerItemMove from '../PlannerItemMove/PlannerItemMove.vue'

export default {
  name: 'PlannerSavedItemMenu',
  components: {
    ChevronDownIcon,
    XIcon,
    PlannerItemMove
  },
  props: {
    item: {
      validator: val => typeof val === 'object',
      required: true
    }
  },
  setup(props, context) {
    // Popper
    const showMenuButton = ref(null)
    const popperMenu = ref(null)
    const popper = usePopper(showMenuButton, popperMenu, {
      placement: 'bottom-end'
    })
    // const { clones, saveHandlers } = handleClones(props)
    // const { _items } = clones

    const isPopperReady = ref(false)
    function openPopper() {
      if (popper.isVisible) {
        closePopperIfReady()
      } else {
        popper.open()
        setTimeout(() => {
          isPopperReady.value = true
        }, 500)
      }
      // Workaround for the popper position
      window.scrollBy(0, 1)
      window.scrollBy(0, -1)
    }
    function closePopperIfReady() {
      if (isPopperReady.value) {
        popper.close()
        isPopperReady.value = false
      }
    }

    async function handleAddToPlanner(scene) {
      const { PlannerSavedItem } = models.api

      const data = {
        name: scene.name || scene.title,
        serviceId: scene.service._id,
        sceneId: scene._id,
        sceneName: scene.name || scene.service.name,
        envId: scene.envId,
        envName: scene.envName
      }
      await new PlannerSavedItem(data).save({
        $populateParams: { name: 'withService' }
      })
      scene.remove()
    }
    return {
      // Popper
      // ...clones,
      showMenuButton,
      popperMenu,
      popper,
      openPopper,
      closePopperIfReady,
      handleAddToPlanner
    }
  }
}
</script>

<style lang="postcss"></style>
