<template>
  <div v-if="itinerary" class="w-48 cursor-pointer" @click="handleClick">
    <AspectRatio ratio="2:1">
      <img
        v-if="itinerary.assetUrl"
        :src="thumbnail(itinerary.assetUrl).url"
        alt=""
        class="h-full w-full rounded"
        :class="[
          itinerary.assetFit === 'cover' ? 'object-cover' : 'object-contain'
        ]"
      />
      <div
        v-else
        class="bg-gray-200 h-full border border-gray-300 rounded flex flex-row items-center justify-center"
      >
        <ImageIcon class="text-gray-500" />
      </div>
    </AspectRatio>
    <p class="text-center truncate mt-1">
      {{ itinerary.title || 'Untitled Itinerary' }}
    </p>
  </div>
</template>

<script>
import { thumbnail } from '@rovit/image-transforms'

import { AspectRatio } from '@rovit/aspect-ratio'
import { ImageIcon } from 'vue-feather-icons'

export default {
  name: 'PlannerItineraryTile',
  components: {
    AspectRatio,
    ImageIcon
  },
  props: {
    itinerary: {
      validator: val => typeof val === 'object',
      default: null,
      required: true
    }
  },
  setup(props, context) {
    function handleClick(event) {
      context.emit('click', { event, itinerary: props.itinerary })
    }
    return {
      thumbnail,
      handleClick
    }
  }
}
</script>

<style lang="postcss"></style>
