import { setVal } from '../use/utils.js'
import { setupAnalytics } from '@rovit/analytics-js/src/client.js'

export default $store => {
  $store.registerModule('analytics', {
    namespaced: true,
    state: () => ({
      client: null
    }),
    mutations: {
      ...setVal('setClient')
    },

    actions: {
      initiate({ state, commit }, envId) {
        const host = process.env.VUE_APP_ROVIT_API_URL
        commit('setClient', setupAnalytics(envId, host))
      },
      logEvent({ state, rootState }, { type, ...meta }) {
        if (state.client) {
          const headers = {}
          if (rootState.auth) {
            headers.Authorization = `Bearer ${rootState.auth.accessToken}`
          }

          if (rootState.auth.anonymous) {
            headers.anonymous = true
          }

          state.client.logEvent(type, meta, headers)
        }
      }
    }
  })
}
