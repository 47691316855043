<template>
  <div class="planner-folder-toolbar">
    <!-- Left -->
    <div>
      <ToolbarIconButton icon="ChevronLeftIcon" @click="back" />
    </div>

    <!-- Middle -->
    <div class="flex flex-row items-center font-bold text-lg">
      <!-- <PlannerMapButton /> -->
      <PlannerSaveButton />
      <PlannerShareButton @click.native="event => $emit('share-click', event)" />
    </div>

    <!-- Right -->
    <div>
      <ToolbarIconButton icon="XIcon" @click="closePlanner" />
    </div>
  </div>
</template>

<script>
import { inject } from '@vue/composition-api'
import { usePlanner } from '@/use/planner.js'

import ToolbarIconButton from '../ToolbarIconButton.vue'
// import PlannerMapButton from '../PlannerMapButton/PlannerMapButton.vue'
import PlannerSaveButton from '../PlannerSaveButton/PlannerSaveButton.vue'
import PlannerShareButton from '../PlannerShareButton/PlannerShareButton.vue'

export default {
  name: 'PlannerFolderToolbar',
  components: {
    ToolbarIconButton,
    // PlannerMapButton,
    PlannerSaveButton,
    PlannerShareButton
  },
  setup(prop, context) {
    const { backToPlanner, closePlanner } = usePlanner()

    function back() {
      backToPlanner()
    }

    return { closePlanner, back }
  }
}
</script>

<style lang="postcss" scoped>
.planner-folder-toolbar {
  @apply flex flex-row items-center justify-between p-1.5;
}
</style>
