import { useCdnUrl } from '@/use/cdn-url.js'

export function thumbnail(url, options) {
  const params = Object.assign(
    {
      width: 660,
      height: 330,
      progressive: true,
      fit: 'contain',
      doNotEnlarge: true,
      output: 'jpg'
    },
    options
  )
  return url ? useCdnUrl(params)(url) : ''
}

export function largeImage(url, options) {
  return url
    ? useCdnUrl({
        width: 3000,
        height: 1500,
        progressive: true,
        fit: 'contain',
        doNotEnlarge: true,
        output: 'jpg'
      })(url)
    : ''
}
