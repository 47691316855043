import { models, useFind } from 'feathers-vuex'
import _pick from 'lodash/pick.js'
import { get } from 'lodash'
import { computed } from '@vue/composition-api'
import { unwrapRefs } from './utils'

export function findPlannerSavedItems({ plannerFolderId }) {
  const { PlannerSavedItem } = models.api

  const params = computed(() => {
    const query = {
      plannerFolderId: get(plannerFolderId, 'value') || null
    }
    return {
      query,
      $populateParams: { name: 'withService' }
    }
  })
  const queryWhen = computed(() => true)
  const { items: plannerSavedItems } = useFind({
    model: PlannerSavedItem,
    params,
    queryWhen
  })
  return { plannerSavedItems }
}

export function makeLinkFromPlannerSavedItem({ plannerSavedItem }) {
  const link = _pick(plannerSavedItem, ['envId', 'envName', 'serviceId'])
  Object.assign(link, {
    serviceName: plannerSavedItem.name,
    isInfobox: true,
    infoboxId: plannerSavedItem.sceneId,
    infoboxName: plannerSavedItem.sceneName,
    shouldSwitchEnvs: false,
    sceneId: null,
    sceneName: ''
  })
  return link
}

export function moveItemToFolder(data) {
  const { item, folder } = unwrapRefs(data)
  const clone = item.clone({
    plannerFolderId: folder._id,
    plannerFolderName: folder.name
  })
  clone.commit().save({ $populateParams: { name: 'withService' } })
}

export async function saveToPlanner(options) {
  const { name, service, scene, env, $toasted } = unwrapRefs(options)
  const { PlannerSavedItem } = models.api
  if (!scene) {
    throw new Error('Scene is required to save to planner')
  }
  const data = {
    name: name || scene.name,
    sceneId: scene._id,
    sceneName: scene.name
  }
  if (service) {
    Object.assign(data, { serviceId: service._id })
  }
  if (env) {
    Object.assign(data, { envId: env._id, envName: env.name })
  }
  try {
    await new PlannerSavedItem(data).save()
    if ($toasted) {
      $toasted.global.actionSuccess('Saved to Planner Inbox')
    }
  } catch (error) {
    console.error(error)
    if ($toasted) {
      $toasted.global.actionError('An error occurred. The item was not saved to the Planner.')
    }
  }
}

export async function addToPlanner(options) {
  const { name, service, scene, env } = unwrapRefs(options)
  if (!scene) {
    throw new Error('Scene is required to save to planner')
  }
  const data = {
    name: name || scene.name,
    sceneId: scene._id,
    sceneName: scene.name
  }
  if (service) {
    Object.assign(data, { serviceId: service._id })
  }
  if (env) {
    Object.assign(data, { envId: env._id, envName: env.name })
  }

  window.localStorage.setItem('pendingPlannerItemData', JSON.stringify(data))
}
