<template>
  <div
    class="bg-white border border-gray-400 rounded-lg shadow-2xl mini-infobox dark:bg-gray-900 dark:text-gray-200 dark:border-gray-800"
  >
    <!-- Scene -->
    <div v-if="scene">
      <div class="flex justify-end">
        <button type="button" class="pt-1 px-1 pb-0.5" @click="$emit('close')">
          <XIcon class="text-gray-600" />
        </button>
      </div>

      <div class="px-3 pb-3">
        <div
          class="flex flex-row items-center justify-center bg-gray-200 rounded-md infobox-img dark:bg-black"
        >
          <img
            v-if="imgUrl && !img404"
            :src="imgUrl"
            alt=""
            class="h-full bg-cover rounded-md"
            @error="img404 = true"
          />
          <div v-else class="flex flex-col w-full font-semibold text-center text-gray-600">
            <ImageIcon class="self-center" />
            <p>No image available</p>
          </div>
        </div>
        <h2 class="mt-1 mb-2 text-xl font-semibold leading-none">
          {{ scene.name }}
        </h2>
        <h3
          v-if="scene.service && scene.name !== scene.service.name"
          class="mt-2 mb-1 text-lg font-semibold leading-none"
        >
          {{ scene.service.name }}
        </h3>

        <p>{{ infoboxSceneDistance(scene) || '' }}</p>

        <button
          type="button"
          class="w-full py-1 mt-2 text-lg font-semibold text-white rounded-md shadow-outline bg-rovit-green focus:outline-none"
          @click="handleOpenClick"
        >
          Open Infobox
        </button>
      </div>
    </div>

    <div v-else class="p-12 text-center">
      <p class="text-xl font-medium">Hopping to it!</p>
      <p class="text-sm">Loading Infobox</p>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from '@vue/composition-api'
import { models } from 'feathers-vuex'
import { get } from 'lodash'
import { point, distance } from '@turf/turf'
import { thumbnail } from '@/use/image-transforms.js'
import { getEnvBySlugFromStore } from '@/use/environments.js'

import { XIcon, ImageIcon } from 'vue-feather-icons'

export default {
  name: 'MiniInfobox',
  components: {
    XIcon,
    ImageIcon
  },
  props: {
    linkTo: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const $store = inject('$store')
    const $route = inject('$route')

    const { Scene, Service, Environment } = models.api

    const currentEnv = computed(() => $store.getters['environments/currentEnv'])

    // if img url return 404, mark img404 as true else false
    const img404 = ref(false)

    // Environment
    const env = computed(() => {
      return props.linkTo.envId ? Environment.getFromStore(props.linkTo.envId) : currentEnv.value
    })
    if (!env.value && props.linkTo.envId) {
      Environment.get(props.linkTo.envId)
    }

    // Service
    const service = computed(() => Service.getFromStore(props.linkTo.serviceId))
    if (!service.value && props.linkTo.serviceId) {
      console.log(props.linkTo.serviceId)
      Service.get(props.linkTo.serviceId)
    }

    // Scene
    const targetSceneId = computed(() => {
      return props.linkTo.sceneId ? props.linkTo.sceneId : props.linkTo.infoboxId
    })
    const scene = computed(() => Scene.getFromStore(targetSceneId.value))
    if (!scene.value && targetSceneId) {
      Scene.get(targetSceneId.value, { $populateParams: 'app' })
    }

    const imgUrl = computed(() => {
      const sceneAssetUrl = get(scene.value, 'assetUrl')
      const serviceAssetUrl = get(service.value, 'primaryAssetUrl')
      const fit = sceneAssetUrl
        ? (scene.value && scene.value.assetFit) || ''
        : (service.value && service.value.primaryAssetFit) || ''
      return thumbnail(sceneAssetUrl || serviceAssetUrl, { fit, trim: 200 })
    })

    const infoboxId = computed(() => $route.value.params.sceneId)
    const infobox = computed(() => {
      if (infoboxId.value) {
        return Scene.getFromStore(infoboxId.value)
      }
      return null
    })

    /**
     * On discover page, infobox won't show distance
     * On infobox page, distance will be calculated from infobox to scene click from pano
     */
    function infoboxSceneDistance(scene) {
      if (infobox.value) {
        const scenePoint =
          scene.location && scene.location.coordinates && point(scene.location.coordinates)
        const infoboxPoint =
          infobox.value.location &&
          infobox.value.location.coordinates &&
          point(infobox.value.location.coordinates)

        if (!scenePoint && !infoboxPoint) {
          return null
        }

        const distanceInKM = distance(infoboxPoint, scenePoint, { units: 'kilometers' })
        const distanceInMiles = distance(infoboxPoint, scenePoint, { units: 'miles' })

        return `${distanceInMiles.toFixed(2)} miles (${distanceInKM.toFixed(2)}) away`
      }
      return null
    }

    function handleOpenClick(event) {
      context.emit('open-link', {
        event,
        link: props.linkTo
      })
    }

    return { service, scene, imgUrl, img404, handleOpenClick, infoboxSceneDistance }
  }
}
</script>

<style lang="postcss">
.mini-infobox {
  max-width: 290px;
}

.infobox-img {
  width: 264px;
  height: 132px;
}
</style>
