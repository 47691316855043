<template>
  <button
    type="button"
    class="toolbar-icon-button"
    @click="$emit('click')"
    :class="[text ? 'px-2' : null]"
  >
    <component :is="icon"></component>
    <span v-if="text" class="mx-1 leading-none">{{ text }}</span>
  </button>
</template>

<script>
import {
  MenuIcon,
  Maximize2Icon,
  Minimize2Icon,
  XIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  ChevronDownIcon
} from 'vue-feather-icons'

export default {
  name: 'ToolbarIconButton',
  components: {
    MenuIcon,
    Maximize2Icon,
    Minimize2Icon,
    XIcon,
    ChevronLeftIcon,
    ChevronUpIcon,
    ChevronDownIcon
  },
  props: {
    icon: {
      type: String,
      required: true,
      validator(val) {
        const values = [
          'MenuIcon',
          'Maximize2Icon',
          'Minimize2Icon',
          'XIcon',
          'ChevronLeftIcon',
          'ChevronUpIcon',
          'ChevronDownIcon'
        ]
        return values.includes(val)
      }
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="postcss">
.toolbar-icon-button {
  @apply flex flex-row items-center text-gray-800 bg-gray-200 rounded-full p-0.75 text-gray-600 p-1.5 transition-colors duration-200 whitespace-no-wrap;
}
.toolbar-icon-button:focus {
  @apply outline-none shadow-outline;
}
.toolbar-icon-button:hover {
  @apply text-gray-800 bg-gray-300;
}
@screen dark {
  .toolbar-icon-button {
    @apply text-gray-200 bg-gray-600;
  }
  .toolbar-icon-button:hover {
    @apply text-gray-300 bg-gray-800;
  }
}
</style>
