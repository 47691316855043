<template>
  <div>
    <label class="block mt-3">
      <p class="block text-gray-700 dark:text-gray-300 mb-0.25">Folder Name</p>
      <input
        v-model="_folder.name"
        class="form-input block w-full"
        placeholder="Enter optional subtitle"
        @blur="e => save_folder('name')"
      />
    </label>

    <label class="block mt-3">
      <p class="block text-gray-700 dark:text-gray-300 mb-0.25">
        Share Itinerary
      </p>
      <div class="flex flex-row">
        <input
          class="form-input block w-full"
          placeholder="Enter email address"
        />
        <button type="button" class="bg-green-600 text-white rounded px-3">
          Share
        </button>
      </div>
    </label>
  </div>
</template>

<script>
import { handleClones } from '@rovit/utils/handle-clones.js'

export default {
  name: 'FolderTileEditor',
  props: {
    folder: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _folder } = clones
    const { save_folder } = saveHandlers
    return { ...clones, ...saveHandlers }
  }
}
</script>
