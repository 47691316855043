<template>
  <XPopper v-if="hotspot" :style="bottomCenterOffsetStyle">
    <template v-slot:reference="{ toggle }">
      <button
        ref="reference"
        class="border border-solid focus:outline-none rounded"
        :class="withBorder ? 'border-white' : 'border-transparent'"
        draggable="true"
        :style="{ transform: `scale(${hotspot.iconScale || 1})` }"
        @click.prevent.stop="
          ev => handleHotspotClick({ event: ev, togglePopper: toggle })
        "
        @dragstart="handleHotspotDrag"
        @dragenter.prevent="() => {}"
      >
        <img :src="src" alt="" :style="style" />
      </button>
    </template>

    <template v-slot:popper="{ hide }">
      <div ref="popper" v-click-outside="hide">
        <slot name="default" :hotspot="hotspot"></slot>
      </div>
    </template>
  </XPopper>
</template>

<script>
import {
  computed,
  onMounted,
  reactive,
  onUnmounted,
  watch,
  inject
} from '@vue/composition-api'
import { XPopper } from '@ionomy/x-popper'

/**
 * ImageHotspotIcon is tightly coupled to the ImageHotspots component, because it requires
 * several injected values.  It uses these injected values to determine its left and top
 * attributes, absolutely positioning itself on the parent.
 */
export default {
  name: 'ImageHotspotIcon',
  components: {
    XPopper
  },
  props: {
    hotspot: {
      type: Object,
      required: true
    },
    withBorder: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const imageWrapper = inject('imageWrapper')
    const imageClientSize = inject('imageClientSize')
    const imageClientToReferenceRatio = inject('imageClientToReferenceRatio')
    const zoom = inject('zoom')

    const src = computed(() => {
      if (props.hotspot.iconUrl) {
        return props.hotspot.iconUrl
      } else {
        return 'https://cdn.rovit.com/hotspotinfo.png'
      }
    })
    /* ------- Offsets ------- */
    const offset = computed(() => {
      return {
        left: (props.hotspot.percentX / 100) * imageClientSize.value.width,
        top: (props.hotspot.percentY / 100) * imageClientSize.value.height
      }
    })
    const hotspotSize = computed(() => {
      const scaleFactor = imageClientToReferenceRatio.value
      return {
        width: (props.hotspot.hotspotIconWidth || 102) * scaleFactor,
        height: (props.hotspot.hotspotIconHeight || 150) * scaleFactor
      }
    })

    /* ------- Styles ------- */
    const style = computed(() => {
      return {
        height: hotspotSize.value.height + 'px'
      }
    })
    const bottomCenterOffset = computed(() => {
      return {
        left: offset.value.left - hotspotSize.value.width / 2,
        top: offset.value.top - hotspotSize.value.height
      }
    })
    const bottomCenterOffsetStyle = computed(() => {
      return {
        left: `${bottomCenterOffset.value.left}px`,
        top: `${bottomCenterOffset.value.top}px`
      }
    })

    /* ------- Events ------- */
    function handleHotspotClick({ event, togglePopper }) {
      context.slots.default && togglePopper()
      context.emit('click', {
        hotspot: props.hotspot,
        offset: offset.value,
        bottomCenterOffset: bottomCenterOffset.value,
        hotspotSize: hotspotSize.value,
        event
      })
    }
    function handleHotspotDrag(event) {
      event.dataTransfer.setData('text/plain', props.hotspot._id)
      context.emit('dragstart', {
        draggable: {
          hotspot: props.hotspot,
          type: 'update_hotspot',
          offsetX: event.offsetX,
          offsetY: event.offsetY,
          bottomCenterOffset: bottomCenterOffset.value,
          hotspotSize: hotspotSize.value
        },
        event
      })
    }

    return {
      src,
      offset,
      style,
      hotspotSize,
      bottomCenterOffset,
      bottomCenterOffsetStyle,
      handleHotspotClick,
      handleHotspotDrag
    }
  }
}
</script>

<style lang="postcss"></style>
