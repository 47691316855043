import { reactive } from '@vue/composition-api'
import { unwrapRefs } from './utils.js'
import _pick from 'lodash/pick.js'

/**
 * Discover - Primary State
 */
export const discoverState = reactive({
  currentEnvSlug: null,
  currentEnvId: null,
  // currentEnv: computed(() => return models.api.Environment. )
  currentEnvServiceId: null,
  currentServiceId: null,
  currentSceneId: null,
})

export function makeLink(options) {
  const { scene, env, infobox, service } = unwrapRefs(options)
  const link = {
    envId: env._id,
    envName: env.name,
    serviceId: service ? service._id : null,
    serviceName: service ? service.name : '',
    infoboxId: null,
    infoboxName: null,
    isInfobox: scene.isInfobox,
    shouldSwitchEnvs: true,
    sceneId: scene._id,
    sceneName: scene.name,
  }
  return link
}
