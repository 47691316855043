<template>
  <div class="bg-white dark:bg-black rounded-lg p-3">
    <div class="md:w-1/2 mb-3 md:mb-0 md:mr-3">
      <ItineraryPublicSettings :itinerary="itinerary" :is-owner="isItineraryOwner" />
    </div>

    <div class="md:w-1/2 flex flex-col">
      <h2 class="text-2xl font-medium mb-2">Shared Planning</h2>
      <p
        class="text-lg rounded bg-gray-100 dark:bg-gray-900 border border-gray-300 p-2 h-full flex flex-col text-center items-center justify-center leading-tight"
      >
        <strong class="mr-1.5 font-semibold mb-1">Coming Soon:</strong>
        Allow specific people to co-author your trip.
      </p>
    </div>
  </div>
</template>
<script>
import { handleClones } from '@rovit/utils/handle-clones.js'

import ItineraryPublicSettings from './ItineraryPublicSettings.vue'
import { computed, inject } from '@vue/composition-api'

export default {
  name: 'PlannerFolderShare',
  components: {
    ItineraryPublicSettings,
  },
  props: {
    itinerary: {
      validator: (val) => typeof val === 'object',
      required: true,
    },
  },
  setup(props, context) {
    const { clones, saveHandlers } = handleClones(props)
    const { _itinerary } = clones
    const { save_itinerary } = saveHandlers

    // Determine if Owner
    const $store = inject('$store')
    const userId = computed(() => {
      return $store.state.auth.user._id
    })
    const $auth0 = inject('$auth0')
    const isItineraryOwner = computed(() => {
      return !!props.itinerary.users.filter(
        (u) => (u.accessType === 'owner' || u.accessType === 'manager') && u.userId === userId.value
      )
    })

    return { ...clones, ...saveHandlers, isItineraryOwner }
  },
}
</script>
